import { inject } from 'vue'
import { defineStore } from 'pinia'
import {
  LOCALES,
  BANNERS,
  bloomreachKey,
  countryVars as _countryVars,
} from '@gv/core'

interface CountryVars {
  LOCALE: string
  BANNER: string
  CURRENCY: string
}

const countryVars = _countryVars as CountryVars[]

export const useGlobalStore = defineStore('globalStore', {
  state: () => {
    let initBanner = localStorage.getItem('banner')
    let initLocale = localStorage.getItem('locale')
    let initCurrency = localStorage.getItem('currency')

    if (
      !initBanner ||
      !BANNERS.includes(initBanner) ||
      !initLocale ||
      !LOCALES.includes(initLocale)
    ) {
      countryVars.forEach((country) => {
        if (country.BANNER == BANNERS.split(', ')[0]) {
          initBanner = country.BANNER
          localStorage.setItem('banner', country.BANNER)
          initLocale = country.LOCALE
          localStorage.setItem('locale', country.LOCALE)
          initCurrency = country.CURRENCY
          localStorage.setItem('currency', country.CURRENCY)
        }
      })
    }

    return {
      banners: BANNERS.split(', '),
      locales: LOCALES.split(', '),
      selectedBanner: initBanner,
      selectedLocale: initLocale,
      selectedCurrency: initCurrency || undefined,
      bloomreach: inject(bloomreachKey)!,
    }
  },
  getters: {
    getSelectedBanner: (state) => state.selectedBanner,
    getSelectedLocale: (state) => state.selectedLocale,
    getSelectedCurrency: (state) => state.selectedCurrency,
  },
  actions: {
    setLocale(_locale: string) {
      countryVars.forEach((country) => {
        if (country.LOCALE == _locale) {
          this.selectedBanner = country.BANNER
          localStorage.setItem('banner', country.BANNER)
          this.selectedLocale = country.LOCALE
          localStorage.setItem('locale', country.LOCALE)
          this.selectedCurrency = country.CURRENCY
          localStorage.setItem('currency', country.CURRENCY)
          this.bloomreach.setLocale(_locale)
        }
      })
    },
    setBanner(_banner: string) {
      countryVars.forEach((country) => {
        if (country.BANNER == _banner) {
          this.selectedBanner = country.BANNER
          localStorage.setItem('banner', country.BANNER)
          this.selectedLocale = country.LOCALE
          localStorage.setItem('locale', country.LOCALE)
          this.selectedCurrency = country.CURRENCY
          localStorage.setItem('currency', country.CURRENCY)
          this.bloomreach.setLocale(country.LOCALE)
        }
      })
    },
    setCurrency(_currency: string) {
      countryVars.forEach((country) => {
        if (country.CURRENCY == _currency) {
          this.selectedBanner = country.BANNER
          localStorage.setItem('banner', country.BANNER)
          this.selectedLocale = country.LOCALE
          localStorage.setItem('locale', country.LOCALE)
          this.selectedCurrency = country.CURRENCY
          localStorage.setItem('currency', country.CURRENCY)
          this.bloomreach.setLocale(country.LOCALE)
        }
      })
    },
  },
})

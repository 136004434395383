<template>
  <component
    :is="linkComponentTag"
    v-bind="urlTag"
    v-on="$listeners"
    class="text-sm underline transition-color duration-200 hover:text-primaryColor-medium"
  >
    <slot />
  </component>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GVLink',
  props: {
    link: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    isExternal() {
      return (
        typeof this.link === 'string' && this.link.search(/(^\/|^#)/g) === -1
      )
    },
    isNativeLinkTag() {
      return this.isExternal || !this.$router
    },
    urlTag() {
      return this.isNativeLinkTag ? { href: this.link } : { to: this.link }
    },
    linkComponentTag() {
      const routerLink = this.$nuxt ? 'nuxt-link' : 'router-link'
      return this.isNativeLinkTag ? 'a' : routerLink
    },
  },
})
</script>

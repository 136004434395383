<template>
  <teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="showModal"
        ref="modal-backdrop"
        class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50"
      >
        <div
          class="flex items-start justify-center min-h-screen pt-24 text-center"
          @click.self="$emit('closeModal')"
        >
          <transition
            enter-active-class="transition ease-out duration-300 transform "
            enter-from-class="opacity-0 translate-y-10 scale-95"
            enter-to-class="opacity-100 translate-y-0 scale-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0 scale-100"
            leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95"
          >
            <div
              class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8"
              :style="{ marginTop: minimizeTo + 'px' }"
              role="dialog"
              ref="modal"
              aria-modal="true"
              v-show="showModal"
              v-bind:class="{
                'w-5/6': fullWidthModal,
                'w-3/4 md:w-1/4': !fullWidthModal,
              }"
              aria-labelledby="modal-headline"
            >
              <div class="flex justify-between items-start pb-20">
                <slot name="header"
                  ><p class="text-2xl font-bold">Simple Modal</p>
                </slot>
                <div class="flex">
                  <div
                    v-if="minimizableModal && isMaximized"
                    class="modal-close cursor-pointer z-50 mr-5"
                    @click="maximizeModal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#333"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        d="M15 3h6v6M14 10l6.1-6.1M9 21H3v-6M10 14l-6.1 6.1"
                      />
                    </svg>
                  </div>
                  <div
                    v-if="minimizableModal && !isMaximized"
                    class="modal-close cursor-pointer z-50 mr-5"
                    @click="minimizeModal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#333"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        d="M4 14h6v6M3 21l6.1-6.1M20 10h-6V4M21 3l-6.1 6.1"
                      />
                    </svg>
                  </div>
                  <div
                    class="modal-close cursor-pointer z-50"
                    @click="$emit('closeModal')"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#333"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </div>
                </div>
              </div>
              <slot name="body"></slot>
              <slot name="footer">
                <div class="flex justify-end mt-20 border-t pt-10">
                  <button
                    class="px-4 bg-transparent p-3 rounded-lg text-gray-500 hover:bg-gray-100 hover:text-gray-400 mr-2"
                    @click="$emit('closeModal')"
                  >
                    Close
                  </button>
                  <button
                    class="modal-close px-4 bg-green-500 p-3 rounded-lg text-white hover:bg-green-400"
                  >
                    Save
                  </button>
                </div>
              </slot>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { ref, watch, defineComponent } from 'vue'
export default defineComponent({
  inheritAttrs: false,
  name: 'SDialogBox',
  emits: ['closeModal'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    minimizable: {
      type: Boolean,
      default: false,
    },
    minimizeTo: {
      type: Number,
      default: 200,
    },
  },
  setup(props: any) {
    const showModal = ref(props.show)
    const fullWidthModal = ref(props.fullWidth)
    const minimizableModal = ref(props.minimizable)
    const isMaximized = ref(false)
    const minimizeTo = ref(0)
    fullWidthModal.value = props.fullWidth

    function closeModal() {
      showModal.value = false
    }

    function minimizeModal() {
      isMaximized.value = true
      minimizeTo.value = props.minimizeTo
    }

    function maximizeModal() {
      isMaximized.value = false
      minimizeTo.value = 0
    }

    watch(
      () => props.show,
      (show) => {
        showModal.value = show
      }
    )

    return {
      closeModal,
      showModal,
      fullWidthModal,
      isMaximized,
      minimizeModal,
      maximizeModal,
      minimizeTo,
      minimizableModal,
    }
  },
})
</script>

<template>
  <div>
    <!-- <GVAccountDropDown :items="flagDropDown">
      <template v-slot:button>
        <div class="cursor-pointer text-gray-600">
          {{}}
          <component
            :is="flags[bloomreach.locale.value]"
            class="mr-2 flex-shrink-0 h-4 w-4"
            aria-hidden="true"
          />
          <span class="sr-only">
            {{ bloomreach.getText('currentLanguage') }}
          </span>
        </div>
      </template>

      <template v-slot:dropdownContent="{ content }">
        <button @click="setLanguage(content.locale)" class="flex">
          <component
            :is="flags[content.locale]"
            class="mr-2 mt-1 flex-shrink-0 h-4 w-4"
            aria-hidden="true"
          />
          {{ content.title }}
        </button>
      </template>
    </GVAccountDropDown> -->

    <GVAccountDropDown :items="userDropDown">
      <template v-slot:button>
        <div class="relative pl-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-user"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="7" r="4" />
            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
          </svg>
          <div
            class="w-2 h-2 rounded-full bg-green-400 border border-white absolute inset-0 mb-0 mr-0 m-auto"
          ></div>
        </div>
        <p v-if="userData" class="text-gray-800 text-sm mx-3">
          {{ bloomreach.getText('welcome') + `, ` + userData.firstName }}
        </p>
        <div class="cursor-pointer text-gray-600"></div>
      </template>

      <template v-slot:dropdownContent="{ content }">
        <SLink
          :href="content.route || content.link"
          @click="dropDownClick(content.route || content.link)"
          variant="bare"
          class="block w-full h-full items-center text-sm text-gray-600 hover:text-primaryColor-medium"
        >
          {{ content.title }}
        </SLink>
      </template>
      <template v-slot="controlButton">
        <div v-if="!isDropdownOpen">{{ bloomreach.getText('open') }}</div>
        <div v-else>{{ bloomreach.getText('close') }}</div>
      </template>
    </GVAccountDropDown>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import {
  GVAccountDropDown,
  SLink,
  bloomreachKey,
  customerUserLogout,
} from '@gv/core'
import { useRouter } from 'vue-router'
import FlagDK from '../assets/dk.svg'
import FlagSV from '../assets/sv.svg'

let flags: any = {
  'sv-SE': FlagSV,
  'da-DK': FlagDK,
}

export default defineComponent({
  name: 'dropdownButtons',
  props: {
    flagDropDown: {
      type: Array,
      required: true,
    },
    userDropDown: {
      type: Array,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  components: {
    GVAccountDropDown,
    SLink,
  },
  setup() {
    const router = useRouter()
    const bloomreach = inject(bloomreachKey)
    const dropDownClick = (route: string) => {
      if (route === '/logout') customerUserLogout(router, { name: 'Login' })
    }
    const setLanguage = (locale: String) => bloomreach.setLocale(locale)
    return { dropDownClick, flags, bloomreach, setLanguage }
  },
})
</script>

// TODO: Review this whole "Auth Plugin" to improve integration and usage, maybe split into features and use this file as a main config file/index for exporting to portals.
import {
  CLIENT_SECRET,
  AZURE_AD_CLIENT_ID,
  AZURE_AD_REDIRECT_URI,
  AZURE_AD_TENANT,
  ASSENTLY_AUTH_SECRET,
  ASSENTLY_IDENTITY_SECRET,
  LOCALES,
} from '../env'
import { useAPI, apiClient } from '../api/index'
import { Router } from 'vue-router'
import {
  EP_AUTH_CLIENT_IDENTITY,
  EP_AUTH_CUSTOMER_IDENTITY,
  EP_AUTH_AZURE_AD_VALIDATION_TOKEN,
  EP_AUTH_CUSTOMER_TRANSACTION,
} from '../api/endpoints/uri'
import * as msal from '@azure/msal-browser'

import { countryVars as _countryVars } from './countryVars'
import { useGlobalStore } from '../../../../customer-care-portal/src/stores/globalStore'
import { accordionKey } from '../../main'

interface CountryVars {
  LOCALE: string
  BANNER: string
  CURRENCY: string
}

const countryVars = _countryVars as CountryVars[]

export function authenticateClientIdentity(
  clientSecret?: string,
  identityId?: string
) {
  const payload = {
    clientId: 'gv_gls_authorized_api_client',
    clientSecret: clientSecret || CLIENT_SECRET,
    identityId: identityId || '12345820',
    identityEmail: 'ext.Daniel.Cunha@grandvision.com',
    identityLocale: '',
  }

  return useAPI({
    method: 'post',
    url: EP_AUTH_CLIENT_IDENTITY,
    data: JSON.stringify(payload),
  })
}

export function getAccessToken() {
  return localStorage.getItem('access_token')
}

export function isAccessTokenValid() {
  const currDate = new Date()
  const currTime = currDate.getTime()
  const expiresAt = parseInt(`${localStorage.getItem('expires_at')}`)
  const diff = (expiresAt - currTime) / 60000 // Gets time diference in minutes

  // If the token is about to expire (less then 10min) then return false to trigger the refresh!
  if (localStorage.getItem('access_token') && diff > 10) {
    return true
  }
  return false
}

export function setAccessToken(accessToken: string, expiresAt?: number) {
  localStorage.setItem('access_token', `${accessToken}`)
  if (expiresAt) {
    const expirationDate = new Date(expiresAt)
    localStorage.setItem('expires_at', `${expirationDate.getTime()}`)
  } else {
    // 3600 seconds * 1000 (as it is in milliseconds)
    const oneHour = 3600 * 1000
    const expirationDate = new Date(Date.now() + oneHour)
    localStorage.setItem('expires_at', `${expirationDate.getTime()}`)
  }
}

export function getCustomerIdentityToken(redirectUrl: string) {
  return useAPI({
    method: 'post',
    url: EP_AUTH_CUSTOMER_IDENTITY,
    data: {
      locale: LOCALES.split(', ')[0],
      redirectUrl,
    },
  })
}

export async function userLogout(router?: Router, args?: any) {
  clearLocalStorageItems()

  if (sessionStorage && sessionStorage.length > 0) {
    const homeAccountId = getHomeAccountIdFromSessionStorage()

    const account = msalInstance().getAccountByHomeId(homeAccountId)
    await msalInstance().logoutPopup({
      account: account,
      postLogoutRedirectUri: AZURE_AD_REDIRECT_URI,
      mainWindowRedirectUri: AZURE_AD_REDIRECT_URI,
    })
  } else {
    // Case logout on Customer Portal
    router?.push(args)
  }
}

export async function customerUserLogout(router?: Router, args?: any) {
  clearLocalStorageItems()

  router?.push(args)
}

function clearLocalStorageItems() {
  localStorage.removeItem('access_token')
  localStorage.removeItem('expiresIn')
  localStorage.removeItem('expires_at')
  localStorage.removeItem('user_groups')
  localStorage.removeItem('userCache')
  localStorage.removeItem('userName')
}

export async function refreshToken() {
  const homeAccountId = getHomeAccountIdFromSessionStorage()

  const currentAccount = msalInstance().getAccountByHomeId(homeAccountId)

  const silentRequest = {
    scopes: [`${AZURE_AD_CLIENT_ID}/.default`],
    account: currentAccount,
    forceRefresh: false,
  }

  const request = {
    scopes: [`${AZURE_AD_CLIENT_ID}/.default`],
    loginHint: currentAccount?.username, // For v1 endpoints, use upn from idToken claims
  }

  const tokenResponse = await msalInstance()
    .acquireTokenSilent(silentRequest as msal.SilentRequest)
    .catch(async (error) => {
      if (error) {
        // fallback to interaction when silent call fails
        return await msalInstance()
          .acquireTokenPopup(request)
          .catch((error) => {
            console.error('error: ', error)
          })
      }
    })

  return await handleTokenResponse(tokenResponse)
}

function getHomeAccountIdFromSessionStorage(): string {
  let homeAccountId = ''

  for (var [_, valueStorage] of Object.entries(sessionStorage)) {
    for (var [key, value] of Object.entries(JSON.parse(valueStorage))) {
      if (key === 'homeAccountId') {
        homeAccountId = `${value}`
        break
      }
    }
    if (homeAccountId.length > 0) break
  }

  return homeAccountId
}

const msalConfig = {
  auth: {
    clientId: AZURE_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AZURE_AD_TENANT}`,
    redirectUri: AZURE_AD_REDIRECT_URI,
    postLogoutRedirectUri: AZURE_AD_REDIRECT_URI,
  },
}

const msalInstance = (() => {
  let instance: msal.PublicClientApplication | null = null

  return () => {
    if (!instance) {
      instance = new msal.PublicClientApplication(msalConfig)
    }

    return instance
  }
})()

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const loginRequest = {
  scopes: [`${AZURE_AD_CLIENT_ID}/.default`],
}

function authenticateAzureADToken(identityId?: string) {
  const payload = {
    identityToken: identityId,
  }

  return useAPI({
    method: 'post',
    url: EP_AUTH_AZURE_AD_VALIDATION_TOKEN,
    data: JSON.stringify(payload),
  })
}

export async function userAzureADLogin() {
  try {
    const tokenResponse = await msalInstance().loginPopup(loginRequest)
    if (tokenResponse && tokenResponse.account)
      localStorage.setItem('userName', `${tokenResponse.account.username}`)

    await handleTokenResponse(tokenResponse)
  } catch (err) {
    return false
  }
  return true
}

async function handleTokenResponse(
  tokenResponse: void | msal.AuthenticationResult
) {
  if (tokenResponse) {
    if (tokenResponse.account?.idTokenClaims?.groups) {
      localStorage.setItem(
        'user_groups',
        tokenResponse.account.idTokenClaims.groups.join(',')
      )
    }

    const accessToken = tokenResponse.accessToken
    if (accessToken && accessToken.length > 0) {
      const { fetch } = authenticateAzureADToken(accessToken)
      await fetch()
      setAccessToken(
        tokenResponse.accessToken,
        tokenResponse.expiresOn?.getTime()
      )
      setLocalStorageConfigs()
    }
    return accessToken
  }
}

function setLocalStorageConfigs() {
  const globalStore = useGlobalStore()
  const locale = localStorage.getItem('locale')
  // ? Checks if the localStorage contains a valid LOCALE variable.
  const foundLocale = countryVars.filter((country) => {
    return country.LOCALE == locale
  })
  // ? If a valid value is found, populate the globalStore with cached values.
  if (foundLocale.length > 0) {
    globalStore.setLocale(foundLocale[0].LOCALE)
    // ? If no valid value found, then populate the globalStore with default values.
  } else {
    globalStore.setLocale(LOCALES.split(', ')[0])
  }
}

import { isAccessTokenValid } from '@gv/core'
import { createWebHistory, createRouter } from 'vue-router'
import MyAccount from './pages/MyAccount.vue'
import ReviewInvoices from './pages/ReviewInvoices.vue'
import Login from './pages/Login.vue'
import Subscriptions from './pages/Subscriptions.vue'
import Invoices from './pages/Invoices.vue'
import PaymentMethod from './pages/PaymentMethod.vue'
import Profile from './pages/Profile.vue'
import PaymentsRefunds from './pages/PaymentsRefunds.vue'
import Support from './pages/Support.vue'
import NotFound from './pages/NotFound.vue'
import PaymentCapture from './pages/PaymentCapture.vue'
import Contact from './pages/Contact.vue'
import PaymentCallback from './pages/PaymentCallback.vue'
import Faq from './pages/Faq.vue'
import AuthCallback from './pages/AuthCallback.vue'

const routes: any = [
  {
    path: '/',
    name: 'Home',
    component: MyAccount,
    meta: {
      breadcrumbs: [{ name: 'my_account' }],
    },
  },
  // {
  //   path: '/review-invoices',
  //   name: 'ReviewInvoices',
  //   component: ReviewInvoices,
  //   meta: {
  //     breadcrumbs: [
  //       { name: 'My account', link: 'Home' },
  //       { name: 'Review Invoices' },
  //     ],
  //   },
  // },
  // {
  //   path: '/payment-capture',
  //   name: 'PaymentCapture',
  //   props: true,
  //   component: PaymentCapture,
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions,
    meta: {
      breadcrumbs: [{ name: 'my_subscriptions' }],
    },
  },
  {
    path: '/bills',
    name: 'Bills',
    component: Invoices,
    meta: {
      breadcrumbs: [{ name: 'my_invoices' }],
    },
  },
  {
    path: '/payment-method',
    name: 'PaymentMethod',
    component: PaymentMethod,
    meta: {
      breadcrumbs: [{ name: 'my_payment_method' }],
    },
  },
  {
    path: '/account-details',
    name: 'AccountDetails',
    component: Profile,
    meta: {
      breadcrumbs: [{ name: 'my_profile' }],
    },
  },
  {
    path: '/payment-callback',
    name: 'PaymentCallback',
    component: PaymentCallback,
    props: true,
  },
  {
    path: '/payments-and-refunds',
    name: 'PaymentsRefunds',
    component: PaymentsRefunds,
    meta: {
      breadcrumbs: [{ name: 'my_payments' }],
    },
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      breadcrumbs: [{ name: 'support' }],
    },
  },

  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      breadcrumbs: [{ name: 'my_contacts' }],
    },
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: {
      breadcrumbs: [{ name: 'questions_and_answers' }],
    },
  },  
  {
    path: '/auth-callback',
    name: 'AuthCallback',
    component: AuthCallback,
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'text-primaryColor-medium text-2xl font-bold',
})

router.beforeEach((to, _, next) => {
  const hasValidToken = isAccessTokenValid()
  if (
    to.name !== 'Login' &&
    to.name !== 'PaymentCapture' &&
    to.name !== 'PaymentCallback' &&
    to.name !== 'AuthCallback' &&
    !hasValidToken
  )
    next({ name: 'Login' })
  else next()
})

export default router

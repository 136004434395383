<template>
  <div
    class="relative bg-white pt-5 px-4 pb-4 sm:pt-6 sm:px-6 shadow-md border border-gray-200"
    v-bind="$attrs"
  >
    <dt>
      <div class="absolute rounded-md p-3 pt-6">
        <div v-if="skeletonLoader" class="w-12 h-12 bg-gray-300 animate-pulse">
          &nbsp;
        </div>
        <div v-else>
          <slot name="icon"> </slot>
        </div>
      </div>
      <div class="flex text-sm font-medium text-gray-500 truncate">
        <div v-if="skeletonLoader" class="bg-gray-300 animate-pulse w-3/4 my-1">
          &nbsp;
        </div>
        <div v-else><slot name="title"></slot></div>
        <div v-if="hasTooltip" class="ml-2">
          <div
            v-if="skeletonLoader"
            class="w-12 h-12 bg-gray-300 animate-pulse"
          >
            &nbsp;
          </div>
          <div v-else class="tooltip-box">
            <div class="tooltip-icon" ref="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-info-circle mr-5 text-primaryColor-medium"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="12" r="9" />
                <line x1="12" y1="8" x2="12.01" y2="8" />
                <polyline points="11 12 12 12 12 16 13 16" />
              </svg>
            </div>
            <div v-if="!isMobile()" class="tooltip border-n">
              <span class="text"><slot name="tooltipText"></slot></span>
            </div>
            <div v-else class="tooltip" :style="tooltipMobileStyle">
              <span class="text"><slot name="tooltipText"></slot></span>
            </div>
          </div>
        </div>
      </div>
    </dt>
    <dd class="items-baseline">
      <div class="w-full mb-2 text-2xl font-semibold text-gray-900">
        <div v-if="skeletonLoader" class="bg-gray-300 animate-pulse my-1 w-2/4">
          &nbsp;
        </div>
        <div v-else>
          <slot name="header"></slot>
        </div>
      </div>

      <div class="w-full mb-2 text-2xl font-semibold text-gray-900">
        <div v-if="skeletonLoader" class="bg-gray-300 animate-pulse my-1 w-2/4">
          &nbsp;
        </div>
        <div v-else>
          <slot name="body"></slot>
        </div>
      </div>

      <div class="flex text-sm font-medium text-gray-500">
        <div v-if="skeletonLoader" class="bg-gray-300 animate-pulse w-3/4 my-1">
          &nbsp;
        </div>
        <div v-else>
          <slot name="extra"> </slot>
        </div>
      </div>
      <div class="relative bottom-0 inset-x-0 text-right pt-6">
        <div class="text-sm">
          <div
            v-if="skeletonLoader"
            class="bg-gray-300 animate-pulse w-1/4 my-1"
          >
            &nbsp;
          </div>
          <div v-else>
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </dd>

    <div class="mt-auto text-sm">
      <div v-if="skeletonLoader" class="bg-gray-300 animate-pulse w-1/4 my-1">
        &nbsp;
      </div>
      <div v-else>
        <slot name="fixedFooter"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, reactive } from 'vue'
import { isMobile } from '@gv/core'

withDefaults(
  defineProps<{
    skeletonLoader: boolean | undefined
    hasTooltip: boolean | undefined
  }>(),
  {
    skeletonLoader: false,
    hasTooltip: false,
  }
)

const icon = ref<HTMLElement | null>(null)
const tooltipMobileStyle = reactive({
  color: '#000',
  'text-align': 'center',
  padding: '15px',
  border: '6px',
  width: `${screen.width - 32}px`,
  bottom: '100%',
  'margin-left': '0px',
  left: '0px',
  'white-space': 'pre-line',
  display: 'none',
  transition: 'display 1s',
  position: 'absolute',
  background: '#eee',
})

watch(icon, (newData) => {
  if (!newData) return

  const coords = newData.getBoundingClientRect()
  tooltipMobileStyle['margin-left'] = `-${coords.left - 16}px`
})
</script>

<style scoped>
.tooltip-box {
  position: absolute;
  z-index: 1;
}

.tooltip-icon:hover + .tooltip {
  display: block !important;
}
.tooltip-box:hover > .tooltip {
  display: block !important;
}

.tooltip {
  color: #000;
  text-align: center;
  padding: 15px;
  border-radius: 6px;

  width: 320px;
  bottom: 100%;
  left: 50%;
  margin-left: -160px;
  white-space: pre-line;

  display: none;
  transition: display 1s;

  position: absolute;
  z-index: 1;

  background: #eee;
}
.text::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -13px;
  border-width: 5px;
  border-style: solid;
  border-color: #eee transparent transparent transparent;
}
@media (max-width: 800px) {
  .text::after {
    border: none;
  }
}
</style>

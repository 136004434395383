<template>
  <div>
    <h1>Support</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Support',
  setup() {
    return {}
  },
})
</script>

<template>
  <table class="min-w-full divide-y divide-gray-200 table-auto border">
    <thead class="bg-gray-50">
      <th v-if="skeletonLoader" v-for="i in skeletonLoaderColumns" :key="i">
        <div :class="[i == 1 ? 'w-10' : 'w-36', ' bg-gray-300 animate-pulse']">
          &nbsp;
        </div>
      </th>
      <tr v-else>
        <th
          v-for="header in headers"
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          :class="{ 'text-secondaryColor-medium': sortKey == header.field }"
          :key="header.name"
        >
          {{ header.name }}
        </th>
      </tr>
    </thead>

    <tr v-if="skeletonLoader">
      <td v-for="i in skeletonLoaderColumns" :key="i">
        <div class="bg-gray-300 animate-pulse">&nbsp;</div>
      </td>
    </tr>
    <tbody v-else class="bg-white divide-y divide-gray-200">
      <tr
        v-for="row in data"
        class="hover:bg-gray-50 cursor-pointer text-gray-700 hover:text-secondaryColor-medium"
        @click="$emit('rowClick', row)"
      >
        <td
          v-for="header in headers"
          :key="header.field"
          class="px-6 py-4"
          scope="row"
          @click="$emit('fieldClick', header.field, row)"
        >
          <div
            v-if="header.field === 'actions'"
            :class="`grid grid-cols-${row.actions.length}`"
          >
            <div
              v-for="(action, index) in row.actions"
              :key="index"
              class="col-span-1"
            >
              <GVButton
                v-if="!action.condition || action.condition(row)"
                @click="action.onClick(row)"
                class="border bg-white text-secondaryColor-medium hover:text-white"
              >
                {{ action.content }}
              </GVButton>
            </div>
          </div>
          <div v-else class="text-sm">
            {{ row[header.field] }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted } from 'vue'
import { GVButton, SPagination } from '@gv/core'

export default defineComponent({
  name: 'SSimpleTable',
  emits: ['rowClick, fieldClick'],
  components: { SPagination, GVButton },
  props: {
    headers: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    onRowClick: {
      type: Function,
    },
    onFieldClick: {
      type: Function,
    },
    skeletonLoader: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const insertActionsInTable = function () {
      if (Object.keys(props.actions).length > 0) {
        tableHeaders.value.push({ name: 'Actions', field: 'actions' })
        tableData.value.forEach((data) => {
          data.actions = props.actions
        })
      }
    }

    const skeletonLoaderColumns = 4
    const tableHeaders = ref(props.headers)
    const tableData = ref(props.data)
    onMounted(() => insertActionsInTable())

    return {
      tableHeaders,
      tableData,
      skeletonLoaderColumns,
    }
  },
})
</script>

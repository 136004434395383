<template>
  <div class="relative">
    <label class="font-mono pr-5 text-sm sr-only"
      >Filter on Subscriptions</label
    >
    <input
      v-bind="$attrs"
      v-model="modelValue"
      @focus="pickerVisible = true"
      @blur="hideList"
      @keyup="filterList"
      :placeholder="placeHolder"
      class="border border-gray-100 focus:outline-none focus:border-secondaryColor-medium rounded text-sm text-gray-500 bg-gray-100 h-full mr-4 pl-1"
      type="text"
    />
    <ul
      v-if="pickerVisible"
      class="absolute top-50 bg-white border border-gray-300 rounded-sm w-full shadow-lg z-10"
    >
      <li
        v-for="pickable in filteredList"
        @click="selectItem(pickable)"
        class="hover:bg-black hover:text-white cursor-pointer w-full px-5 py-2"
      >
        {{ pickable }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
export default defineComponent({
  title: 'STypeAhead',
  emits: ['selectItem'],
  props: {
    items: {
      type: Array,
      default: [],
    },
    selectedValue: {
      type: String,
      default: '',
    },
    placeHolder: {
      type: String,
      default: 'Filter on subscription number'
    }
  },
  setup(props, ctx) {
    const modelValue = ref(props.selectedValue)
    const pickerVisible = ref(false)
    const filteredList = ref(props.items)

    const filterList = (_: KeyboardEvent) => {
      filteredList.value = props.items.filter((item: string) =>
        item.toLowerCase().includes(modelValue.value.toLowerCase())
      )
    }

    const selectItem = (val: string) => {
      modelValue.value = val
      ctx.emit('selectItem', val)
    }

    const hideList = () => {
      setTimeout(() => (pickerVisible.value = false), 150)
    }

    return {
      pickerVisible,
      hideList,
      filteredList,
      filterList,
      modelValue,
      selectItem,
    }
  },
})
</script>

<template></template>

<script setup lang="ts">
import { onMounted, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { bloomreachKey, setAccessToken, useAPI } from '@gv/core'
import { EP_AUTH_CUSTOMER_TRANSACTION } from '@gv/core/src/lib/api/endpoints/uri'
import { useAccountDataStore } from '../stores/accountData'
import { usePaymentsDataStore } from '../stores/paymentsData'

const router = useRouter()
const route = useRoute()
const accountDataStore = useAccountDataStore()
const paymentsDataStore = usePaymentsDataStore()
const bloomreach = inject(bloomreachKey)!

interface Transaction {
  success: boolean
  token: Token
}

interface Token {
  token: string
  tokenType: string
  expiresIn: number
}

const fetchTransaction = async (
  transactionId: string
): Promise<Transaction | null> => {
  const { fetch, data } = useAPI({
    method: 'get',
    url: EP_AUTH_CUSTOMER_TRANSACTION,
    params: {
      transactionId,
    },
  })

  try {
    await fetch()

    return data.value as Transaction | null
  } catch (e) {
    console.error(e)

    return null
  }
}

const redirectToLogin = (error: string) =>
  router.push({ name: 'Login', query: { error } })
const redirectToHome = () => router.push({ name: 'Home' })

const fetchHasZuoraAccount = async (): Promise<boolean> => {
  const account = await accountDataStore.fetchAccountOverview()

  // For some reason fetchAccountOverview() returns null on success...?
  // Just check if we don't get the error response, and consider everything else a success
  return account?.status != 404 && account?.status != 500
}

const setLocale = () =>
  bloomreach.setLocale((accountDataStore.data as any).banner)

const preloadData = () => {
  accountDataStore.fetchOutstandingBalance()
  accountDataStore.fetchRelatedAccounts()
  paymentsDataStore.fetchDefaultPaymentMethod()
}

onMounted(async () => {
  // Find the transcation-id query
  const transactionId = route.query['transaction_id']
  // Find the success query
  const success = route.query['success'] == 'true'
  // Login failed/invalid query params
  if (!success || !transactionId) {
    redirectToLogin('failed')

    return
  }

  const transaction = await fetchTransaction(transactionId!.toString())
  // If transaction failed, the login failed
  if (transaction == null) {
    redirectToLogin('failed')

    return
  }

  try {
    const hasZuoraAccount = await fetchHasZuoraAccount()
    if (!hasZuoraAccount) {
      redirectToLogin('no_zuora_account')

      return
    }
    
    setAccessToken(transaction.token.token)
    
    setLocale()
    preloadData()
    redirectToHome()
  } catch {
    redirectToLogin('failed')
  }
})
</script>

<template>
  <div>
    <GVCard class="relative" :skeletonLoader="accountDataStore.accountLoading">
      <template v-slot:header>
        <div class="w-full flex text-base justify-end sticky top-0 md:px-16">
          <SButton
            v-if="!isEnabledEdit"
            variant="bare"
            @click="toggleEdit"
            class="p-2 text-primaryColor-medium hover:text-secondaryColor-medium"
            ><div class="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-edit mr-2"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
                />
                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                <line x1="16" y1="5" x2="19" y2="8" />
              </svg>
              {{ getText('edit') }}
            </div></SButton
          >
          <div v-else class="w-full flex justify-end">
            <SButton
              variant="bare"
              class="p-2 text-green-500 hover:text-secondaryColor-medium"
              :disabled="isSaving"
              form="update-address-info"
              type="submit"
            >
              <span v-if="isSaving">
                <SLoading class="h-12 w-12 mr-5" />
              </span>

              <span class="flex" v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-device-floppy mr-1"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"
                  />
                  <circle cx="12" cy="14" r="2" />
                  <polyline points="14 4 14 8 8 8 8 4" />
                </svg>
                {{ getText('save') }}
              </span>
            </SButton>
            <SButton
              v-if="!isSaving"
              variant="bare"
              class="p-2 text-gray-500 hover:text-secondaryColor-medium flex"
              @click="cancelEdit"
            >
              {{ getText('cancel') }}
            </SButton>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="lg:px-16 md:px-8">
          <div
            class="mb-12 gap-2 grid md:grid-cols-2 xl:grid-cols-3 items-baseline md:gap-6 border-b pb-4 text-base"
          >
            <div
              class="text-lg font-medium leading-6 text-secondaryColor-medium flex flex-shrink-0 mb-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-user mr-5 text-primaryColor-medium"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="7" r="4" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
              </svg>
              <div class="pt-1">
                <p class="mt-1 text-sm text-gray-500">
                  {{ getText('name') }}
                </p>
                <h3>
                  {{
                    `${accountDataStore.data.personalInfo?.firstName} ${accountDataStore.data.personalInfo?.lastName}`
                  }}
                </h3>
              </div>
            </div>

            <div
              class="text-lg font-medium leading-6 text-secondaryColor-medium flex flex-shrink-0 mb-4"
            >
              <div>
                <p class="mt-1 text-sm text-gray-500">
                  {{ getText('customer_number') }}
                </p>
                <h3>
                  {{ accountDataStore.data.customerNumber }}
                </h3>
              </div>
            </div>
          </div>
          <div class="pb-6 gap-2 md:grid md:grid-cols-12 md:gap-6 text-base">
            <div class="sm:col-span-12 xl:col-span-2 flex">
              <h3
                class="text-lg font-medium leading-6 flex flex-shrink-0 text-secondaryColor-medium pr-2"
              >
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-map-2 mr-3 text-primaryColor-medium"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="18" y1="6" x2="18" y2="6.01" />
                  <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
                  <polyline
                    points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15"
                  />
                  <line x1="9" y1="4" x2="9" y2="17" />
                  <line x1="15" y1="15" x2="15" y2="20" />
                </svg> -->
                {{ getText('address_information_title') }}
              </h3>
              <STooltip :text="getText('account_name_info_tooltip')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-info-circle mr-5 text-primaryColor-medium"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="12" r="9" />
                  <line x1="12" y1="8" x2="12.01" y2="8" />
                  <polyline points="11 12 12 12 12 16 13 16" />
                </svg>
              </STooltip>
            </div>
            <div class="sm:col-span-12 xl:col-span-10">
              <h5 class="text-secondaryColor-medium w-full border-b flex pb-3">
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-map-pin mr-5"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="11" r="3" />
                  <path
                    d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"
                  />
                </svg> -->
                <!-- {{ camelCaseToReadable(address.type) }} -->
                {{ getText('invoice_owner') }}
              </h5>
            </div>
            <div class="md:col-span-2"></div>
            <div class="md-5 pr-6 md:col-span-5">
              <div
                v-for="address in accountDataStore.data.addresses"
                :key="address.type"
              >
                <form id="update-address-info" @submit.prevent="saveEdit">
                  <div class="mt-5 md:col-span-1">
                    <div class="xl:flex items-center mb-2">
                      <SFormField
                        :name="`firstName`"
                        :label="getText('first_name')"
                        :class="['w-full']"
                      >
                      </SFormField>
                      <!-- <SInput
                        v-if="isEnabledEdit"
                        :id="`firstName`"
                        :name="`firstName`"
                        :value="accountDataStore.data.personalInfo.firstName"
                        :disabled="true"
                        class="-mt-4 col-span-2"
                      /> -->
                      <div class="xl:-mt-7 -mt-5 w-full">
                        {{ accountDataStore.data.personalInfo?.firstName }}
                      </div>
                    </div>
                    <div class="xl:flex items-center mb-2">
                      <SFormField
                        :name="`lastName`"
                        :label="getText('last_name')"
                        :class="['w-full']"
                      >
                      </SFormField>
                      <!-- <SInput
                        v-if="isEnabledEdit"
                        :id="`lastName`"
                        :name="`lastName`"
                        v-model="accountDataStore.data.personalInfo.lastName"
                        :disabled="true"
                        class="-mt-4 col-span-2"
                      /> -->
                      <div class="xl:-mt-7 -mt-5 w-full">
                        {{ accountDataStore.data.personalInfo?.lastName }}
                      </div>
                    </div>
                    <div class="xl:flex items-center mb-2">
                      <SFormField
                        :name="`address`"
                        :label="getText('address')"
                        :class="['w-full']"
                      >
                      </SFormField>
                      <!-- <SInput
                        :id="`${address.type}.address`"
                        :name="`address`"
                        :value="`${address.addressLine1} ${address.addressLine2}`"
                        :disabled="true"
                        v-if="isEnabledEdit"
                        class="-mt-4 col-span-2"
                      /> -->
                      <div class="xl:-mt-7 -mt-5 w-full">
                        {{ `${address.addressLine1} ${address.addressLine2}` }}
                      </div>
                    </div>
                    <div class="xl:flex items-center mb-2">
                      <SFormField
                        :name="`zipcode`"
                        :label="getText('postal_code')"
                        :class="['w-full']"
                      >
                      </SFormField>
                      <!-- <SInput
                        :id="`${address.type}.zipCode`"
                        :name="`zipcode`"
                        v-model="address.postalCode"
                        :disabled="true"
                        v-if="isEnabledEdit"
                        class="-mt-4 col-span-2"
                      /> -->
                      <div class="xl:-mt-7 -mt-5 w-full">
                        {{ address.postalCode }}
                      </div>
                    </div>
                    <div class="xl:flex items-center mb-2">
                      <SFormField
                        :name="`city`"
                        :label="getText('city')"
                        :class="['w-full']"
                      >
                      </SFormField>
                      <!-- <SInput
                        :id="`${address.type}.city`"
                        :name="`city`"
                        v-model="address.city"
                        :disabled="true"
                        v-if="isEnabledEdit"
                        class="-mt-4 col-span-2"
                      /> -->
                      <div class="xl:-mt-7 -mt-5 w-full">
                        {{ address.city }}
                      </div>
                    </div>
                    <div class="xl:flex items-center mb-2">
                      <SFormField
                        :name="`countryCode`"
                        :label="getText('country')"
                        :class="['w-full']"
                      >
                      </SFormField>

                      <div class="xl:-mt-7 -mt-5 w-full">
                        {{ showCountry(address.country) }}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="md:col-span-5">
              <form id="update-address-info" @submit.prevent="saveEdit">
                <div class="mt-5 md:col-span-1">
                  <div class="xl:flex items-center mb-2">
                    <SFormField
                      :name="`invoice_channel`"
                      :label="getText('invoice_channel')"
                      :class="['w-full']"
                    >
                    </SFormField>
                    <div v-if="!isEnabledEdit" class="xl:-mt-7 -mt-5 w-full">
                      {{
                        invoiceDeliveryPreference(
                          accountDataStore.data.invoiceChannelEmail,
                          accountDataStore.data.invoiceChannelPrint
                        )
                      }}
                    </div>

                    <div v-else class="w-full xl:flex items-center mb-5">
                      <div class="">
                        <SRadio
                          :id="`invoiceChannelEmail`"
                          :name="'invoiceChannelNone'"
                          v-model="emailPrint"
                          :value="'none'"
                          :disabled="!isEnabledEdit"
                          v-if="!accountDataStore.data.invoiceChannelEmail && !accountDataStore.data.invoiceChannelPrint"
                          @click="setInvoiceChannel($event, 'none')"
                          >
                          <p
                            class="text-right text-sm font-medium text-gray-700 px-2 pr-4">
                            {{ getText('ingen_besked') }}
                          </p>
                        </SRadio>
                        <SRadio
                          :id="`invoiceChannelEmail`"
                          :name="'invoiceChannelEmail'"
                          v-model="emailPrint"
                          :value="'email'"
                          :disabled="!isEnabledEdit"
                          @click="setInvoiceChannel($event, 'email')"
                        >
                          <p
                            class="text-right text-sm font-medium text-gray-700 px-2 pr-4"
                          >
                            {{ getText('email') }}
                          </p>
                        </SRadio>
                        <SRadio
                          :id="`invoiceChannelPrint`"
                          :name="'invoiceChannelPrint'"
                          v-model="emailPrint"
                          :value="'print'"
                          :disabled="!isEnabledEdit"
                          @click="setInvoiceChannel($event, 'print')"
                        >
                          <p
                            class="text-right text-sm font-medium text-gray-700 px-2"
                          >
                            {{ getText('print') }}
                          </p>
                        </SRadio>
                        <SRadio
                          :id="`invoiceChannelEmailPrint`"
                          :name="'invoiceChannelEmailPrint'"
                          v-model="emailPrint"
                          :value="'emailPrint'"
                          :disabled="!isEnabledEdit"
                          @click="setInvoiceChannel($event, 'email_and_print')"
                        >
                          <p
                            class="text-right text-sm font-medium text-gray-700 px-2"
                          >
                            {{ getText('email_and_print') }}
                          </p>
                        </SRadio>
                      </div>
                    </div>
                  </div>
                  <div class="xl:flex items-center mb-2">
                    <SFormField
                      :name="`phoneNumber`"
                      :label="getText('phone_number')"
                      :class="['w-full']"
                    >
                    </SFormField>
                    <SInput
                      v-if="isEnabledEdit"
                      :id="`phoneNumber`"
                      :name="`phoneNumber`"
                      v-model="accountDataStore.data.contactInfo.phoneNumber"
                      :disabled="!isEnabledEdit"
                      class="-mt-4 col-span-2"
                      :maxlength="maxLength.phoneNumber"
                    />
                    <div v-else class="xl:-mt-7 -mt-5 w-full">
                      {{ accountDataStore.data.contactInfo?.phoneNumber }}
                    </div>
                  </div>
                  <div class="xl:flex items-center mb-2">
                    <SFormField
                      :name="`mobileNumber`"
                      :label="getText('mobile_number')"
                      :class="['w-full']"
                    >
                    </SFormField>
                    <SInput
                      v-if="isEnabledEdit"
                      :id="`mobileNumber`"
                      :name="`mobileNumber`"
                      v-model="accountDataStore.data.contactInfo.mobileNumber"
                      :disabled="!isEnabledEdit"
                      class="-mt-4 col-span-2"
                      :maxlength="maxLength.mobileNumber"
                    />
                    <div v-else class="xl:-mt-7 -mt-5 w-full">
                      {{ accountDataStore.data.contactInfo?.mobileNumber }}
                    </div>
                  </div>
                  <div class="xl:flex items-center mb-2">
                    <SFormField
                      :name="`workNumber`"
                      :label="getText('work_number')"
                      :class="['w-full']"
                    >
                    </SFormField>
                    <SInput
                      v-if="isEnabledEdit"
                      :id="`workNumber`"
                      :name="`workNumber`"
                      v-model="accountDataStore.data.contactInfo.workNumber"
                      :disabled="!isEnabledEdit"
                      class="-mt-4 col-span-2"
                      :maxlength="maxLength.workNumber"
                    />
                    <div v-else class="xl:-mt-7 -mt-5 w-full">
                      {{ accountDataStore.data.contactInfo?.workNumber }}
                    </div>
                  </div>
                  <div class="xl:flex items-center mb-2">
                    <SFormField
                      :name="`email`"
                      :label="getText('contact_email_address')"
                      class="w-full pr-3"
                    >
                    </SFormField>
                    <SInput
                      :id="`email`"
                      :name="`email`"
                      :class="['w-full']"
                      v-model="accountDataStore.data.contactInfo.email"
                      :disabled="!isEnabledEdit"
                      v-if="isEnabledEdit"
                      class="col-span-2 -mt-4"
                      :maxlength="maxLength.email"
                    />

                    <div v-else class="text-primaryColor-medium -mt-5 w-full">
                      <a
                        :href="`mailto:${accountDataStore.data.contactInfo?.email}`"
                      >
                        {{
                          accountDataStore &&
                          accountDataStore.data.contactInfo &&
                          accountDataStore.data.contactInfo.email
                            ? accountDataStore.data.contactInfo?.email
                            : 'No number'
                        }}
                      </a>
                    </div>
                  </div>

                  <!-- <div class="col-span-1 mt-10">
                    <div
                      v-for="address in accountDataStore.data.addresses"
                      :key="address.type"
                    >
                      <SLink
                        :href="`https://www.google.com/maps?q=${
                          address.addressLine1 + ' ' + address.addressLine2
                        },+${address.city},+${address.country}`"
                        variant="bare"
                        target="_blank"
                        ref="nofollow"
                        class="text-primaryColor-medium hover:text-black"
                        >{{ getText('google_maps_text') }}</SLink
                      >
                    </div>
                  </div> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>
    </GVCard>
    <SConfirmDialog
      :show="showInvoiceChannelModal"
      @close-modal="showInvoiceChannelModal = false"
      @confirm="confirmPrintPrice()"
    >
      <template v-slot:body>
        <p class="text-xl font-bold text-center">
          {{ getText('print_price_confirmation_message') }}
        </p>
      </template>
    </SConfirmDialog>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  watch,
  inject,
  onBeforeMount,
} from 'vue'
import { useRouter } from 'vue-router'

import {
  SButton,
  SLoading,
  SFormField,
  SInput,
  SLink,
  SRadio,
  SCheckbox,
  SConfirmDialog,
  camelCaseToReadable,
  GVCard,
  STooltip,
  bloomreachKey,
} from '@gv/core'

import { useAccountDataStore } from '../stores/accountData'

export default defineComponent({
  name: 'Profile',
  components: {
    SButton,
    SLoading,
    SInput,
    SLink,
    SRadio,
    SFormField,
    GVCard,
    STooltip,
    SCheckbox,
    SConfirmDialog,
  },
  methods: {
    camelCaseToReadable,
  },
  setup() {
    const router = useRouter()
    const isSaving = ref(false)

    const bloomreach = inject(bloomreachKey)
    // workaround because we cannot currently add translations to Bloomreach
    bloomreach.addExtraTranslation('sv_SE', 'ingen_besked', 'Inget meddelande');
    bloomreach.addExtraTranslation('da_DK', 'ingen_besked', 'Ingen besked');

    const getText = (key: string) => bloomreach.getText(key)

    const accountDataStore = useAccountDataStore()

    const isEnabledEdit = ref(false)
    const countries = ref([])
    const showCountry = ref((countryCode) => {})

    const showInvoiceChannelModal = ref(false)
    const invoiceChannel = ref('')

    const emailPrint = ref('')

    // Field length restrictions from XPO - Tasks SOZI-757
    const maxLength = ref({
      email: 80,
      phoneNumber: 30,
    })

    onBeforeMount(async () => {
      const countryList = await getText('country_list_dropdown').split('|')
      countries.value = await countryList.map((country) => {
        const countrySplitted = country.split('-')
        const obj = {
          countryCode: countrySplitted[0],
          label: countrySplitted[1],
        }
        return obj
      })
      showCountry.value = (countryCode) => {
        return countries.value.find((country) => {
          return country.countryCode
            .toLowerCase()
            .includes(countryCode.toLowerCase())
        })?.label
      }
    })

    const invoiceDeliveryPreference = (email, print) => {
      if (email) {
        if (print) {
          return `${bloomreach.getText('email')}, ${bloomreach.getText(
            'print'
          )}`
        }
        return `${bloomreach.getText('email')}`
      } else if (print) {
        return `${bloomreach.getText('print')}`
      }
      return `${bloomreach.getText('ingen_besked')}`
    }

    const setInvoiceChannel = (event: Event, channel: string) => {
      invoiceChannel.value = channel
      if (channel === 'email') {
        emailPrint.value = 'email'
        return
      }

      if (channel === 'print' || channel === 'email_and_print') {
        event.preventDefault()
        if (!accountDataStore.data.invoiceChannelPrint) {
          showInvoiceChannelModal.value = true
        }
      }
    }

    const confirmPrintPrice = async () => {
      emailPrint.value = invoiceChannel.value
      showInvoiceChannelModal.value = false
    }

    const toggleEdit = () => {
      isEnabledEdit.value = !isEnabledEdit.value
      if (accountDataStore.data.invoiceChannelEmail) {
        if (accountDataStore.data.invoiceChannelPrint) {
          emailPrint.value = 'emailPrint'
        } else {
          emailPrint.value = 'email'
        }
      } else if (accountDataStore.data.invoiceChannelPrint) {
        emailPrint.value = 'print'
      } else {
        emailPrint.value = 'none'
      }
    }

    const cancelEdit = () => {
      accountDataStore.data = JSON.parse(
        JSON.stringify(accountDataStore.pristineData)
      )
      isEnabledEdit.value = false
    }

    const saveEdit = async () => {
      isSaving.value = true
      try {
        await accountDataStore.updateAccountData(emailPrint.value)
        isEnabledEdit.value = false
        isSaving.value = false
      } catch (error) {
        console.error(error)
        isSaving.value = false
      }
    }

    onMounted(async () => {
      const address = []
      // In case the page refreshes or this is the first route accessed
      if (accountDataStore.storeNotStarted) await accountDataStore.startStore()
      if (
        accountDataStore.data &&
        Array.isArray(accountDataStore.data.addresses) &&
        accountDataStore.data.addresses.length > 0
      ) {
        address.push(accountDataStore.data.addresses[0])
      }
    })

    router.beforeEach((to, from, next) => {
      if (accountDataStore.hasChanged() && to.path !== `/account-details`) {
        const answer = window.confirm(getText('unsaved_changes_warning'))
        next(answer)
      } else {
        next()
      }
    })

    return {
      isSaving,
      isEnabledEdit,
      toggleEdit,
      cancelEdit,
      saveEdit,
      getText,
      countries,
      showCountry,
      confirmPrintPrice,
      maxLength,
      showInvoiceChannelModal,
      invoiceChannel,
      setInvoiceChannel,
      emailPrint,
      invoiceDeliveryPreference,
      accountDataStore,
      bloomreach
    }
  },
})
</script>
<style>
/* Overrides the margin from a child component of GVCard */
dd {
  margin-left: 0px !important;
}
</style>

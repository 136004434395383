<template>
  <div
    class="lg:w-3/4 mx-auto flex-1 items-center text-center lg:text-left lg:flex"
  >
    <div class="w-full lg:w-1/2">
      <div class="mb-20 mx-auto">
        <button
          class="flex text-lg lg:mt-0 mt-20 font-light outline-none focus:outline-none transform transition-all hover:scale-110 text-primaryColor-medium hover:text-primaryColor-light"
          @click="$router.go(-1)"
        >
          <ArrowLeft />
          Go Back
        </button>
      </div>
      <h1
        class="font-black uppercase text-3xl text-secondaryColor-medium mb-10"
      >
        You seem to be lost!
      </h1>
      <div class="font-light">
        <p>The page you're looking for isn't available.</p>
        <p>Try searching again or use the Go Back button.</p>
      </div>
    </div>
    <div class="w-full lg:w-1/2 mt-20 lg:mt-0">
      <NotFoundSvg />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import NotFoundSvg from '../assets/undraw_page_not_found_su7k.svg'
import ArrowLeft from '../assets/icon-tabler-arrow-narrow-left.svg'
export default defineComponent({
  name: 'NotFound',
  components: { NotFoundSvg, ArrowLeft },
})
</script>

import { inject } from 'vue'
import { defineStore } from 'pinia'
import {
  getDefaultPaymentMethodPP,
  getPayments,
  formatPaymentsData,
  bloomreachKey,
} from '@gv/core'

export const usePaymentsDataStore = defineStore('paymentsData', {
  state: () => {
    return {
      data: [],
      paymentsTable: {},
      defaultPaymentMethod: {},
      loading: true,
      error: null,
      refresh: false,
      storeNotStarted: true,
    }
  },
  actions: {
    async fetchDefaultPaymentMethod() {
      const { fetch, data } = getDefaultPaymentMethodPP()
      try {
        this.loading = true
        await fetch()
        if (data && data.value) {
          this.defaultPaymentMethod = data.value
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
        this.storeNotStarted = false
      }
    },
    async fetchPayments(bloomreach: any) {
      const { fetch, data, error } = getPayments()
      try {
        this.loading = true
        await fetch()
        if (data && data.value) {
          this.data = data.value
          this.formatPaymentsData(bloomreach)
        }
      } catch (er) {
        this.error = error
        console.error(er)
      } finally {
        this.loading = false
      }
    },
    formatPaymentsData(bloomreach: any) {
      const {
        headers: paymentsHeader,
        data: paymentsData,
      } = formatPaymentsData(this.data, bloomreach)
      this.paymentsTable = {
        headers: paymentsHeader,
        data: paymentsData,
      }
    },
    // Generic function to start and populate the store.
    async startStore() {
      const bloomreach = inject(bloomreachKey)
      try {
        this.loading = true
        await this.fetchDefaultPaymentMethod()
        await this.fetchPayments(bloomreach)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
})

import { inject, Ref } from 'vue'
import { useAccountDataStore } from '../stores/accountData'
import {
  formatPPActiveSubscriptionData,
  formatPPCanceledSubscriptionData,
  getSubscriptionsPP,
  LOCALES,
  bloomreachKey,
  SubscriptionData,
} from '@gv/core'
import { defineStore } from 'pinia'

interface SubscriptionTableRecord {
  data: Record<string, unknown>[]
  headers: Record<string, unknown>[]
  subscription: {
    subscriptionNumber?: string
    subscriptionActivationDate: string
    subscriptionStartDate?: string
    earliestSubscriptionEndDate?: string
    subscriptionOwner: any
    subscriptionEndDate?: string
    cancellationNoticeDate?: string
    subscriptionStatus: string
    subscriptionOwnerName: string
  }
}

export const useSubscriptionDataStore = defineStore('subscriptionData', {
  state: () => {
    return {
      data: {},
      selectedSubscription: {},
      loadingSubscriptions: true,
      errorSubscriptions: '',
      subscriptionRecords: [] as SubscriptionTableRecord[],
      loadingRelatedAccounts: true,
      relatedAccountsTable: {
        headers: [],
        data: {
          parents: [],
          children: [],
        },
      },
      storeNotStarted: true,
    }
  },
  actions: {
    async fetchSubscriptionDetails(bloomreach: any, currency: string) {
      const { fetch: fetchSubscriptions, data } = getSubscriptionsPP()
      const accountDataStore = useAccountDataStore()

      try {
        this.loadingSubscriptions = true
        await Promise.all([fetchSubscriptions(), accountDataStore.startStore()])
        const dataSubscriptions = data as Ref<SubscriptionData>

        let invoiceOwner: SubscriptionTableRecord[] = []
        let childSubscriptions: SubscriptionTableRecord[] = []

        if (dataSubscriptions && dataSubscriptions.value) {
          // @ts-ignore
          this.errorSubscriptions = ''
          // Saves pristine data
          const cancelledSubscriptions = dataSubscriptions.value.subscriptionsOwned.filter(
            (sub) => {
              return sub.subscription.status === 'Cancelled'
            }
          )
          const activeSubscriptions = dataSubscriptions.value.subscriptionsOwned.filter(
            (sub) => {
              return sub.subscription.status === 'Active'
            }
          )

          const active = formatPPActiveSubscriptionData(
            activeSubscriptions,
            bloomreach,
            currency,
            true
          )
          const cancelled = formatPPCanceledSubscriptionData(
            cancelledSubscriptions,
            bloomreach,
            currency,
            true
          )

          invoiceOwner = [...active, ...cancelled]
        }

        const relatedSubs = accountDataStore.relatedAccounts.children.map(
          (x) => x.subscriptionInfo as SubscriptionData
        ) as SubscriptionData[]

        if (relatedSubs && relatedSubs.length > 0) {
          childSubscriptions = this.formatChildrenSubscriptions(
            bloomreach,
            currency,
            relatedSubs
          )
        }

        this.subscriptionRecords = [...invoiceOwner, ...childSubscriptions]
      } catch (error) {
        // @ts-ignore
        this.errorSubscriptions = error
        console.error(error)
      } finally {
        this.loadingSubscriptions = false
        this.storeNotStarted = false
      }
    },

    formatChildrenSubscriptions(
      bloomreach: any,
      currency: string,
      relatedSubscriptions: SubscriptionData[]
    ) {
      let childSubscriptions: SubscriptionTableRecord[] = []

      for (let index = 0; index < relatedSubscriptions.length; index++) {
        const child = relatedSubscriptions[index]
        const cancelledSubscription = child.subscriptionsOwned.filter((sub) => {
          return sub.subscription.status === 'Cancelled'
        })
        const activeSubscription = child.subscriptionsOwned.filter((sub) => {
          return sub.subscription.status === 'Active'
        })

        const activeFormattedChild = formatPPActiveSubscriptionData(
          activeSubscription,
          bloomreach,
          currency,
          false
        )
        childSubscriptions.push(...activeFormattedChild)

        const cancelledFormattedChild = formatPPCanceledSubscriptionData(
          cancelledSubscription,
          bloomreach,
          currency,
          false
        )
        childSubscriptions.push(...cancelledFormattedChild)
      }

      childSubscriptions.sort((a, b) => {
        if (
          a.subscription.subscriptionOwnerName >
          b.subscription.subscriptionOwnerName
        ) {
          return 1
        }

        if (
          a.subscription.subscriptionOwnerName <
          b.subscription.subscriptionOwnerName
        ) {
          return -1
        }

        return 0
      })
      return childSubscriptions
    },

    // Generic function to start and populate the store..
    async startStore() {
      const bloomreach = inject(bloomreachKey)
      const currency = LOCALES.split(', ')[0] == 'da-DK' ? 'DKK' : 'SEK'

      try {
        this.loadingSubscriptions = true
        this.fetchSubscriptionDetails(bloomreach, currency)
      } catch (error) {
        console.error(error)
      }
    },
  },
})

// TODO: Review/refactor this api plugin to return request status for better error handling on the FE side
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
import { Ref, reactive, toRefs } from 'vue'

import { BACKEND_URL, ENVIRONMENT } from '../env'
import { getAccessToken } from '../auth/auth'
import { userLogout, refreshToken, isAccessTokenValid } from '../auth/auth'
import { BloomreachPlugin } from '../bloomreach/bloomreach'

interface QueryState {
  data: unknown | null
  loading: boolean | null
  error: unknown | null
}

export const apiClient = axios.create({
  baseURL: BACKEND_URL,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

let requestQueue: {
  url: any
  resolve: (value: unknown) => void
  reject: (reason?: any) => void
}[] = []

function addToRequestQueue(cfg: any) {
  return new Promise((resolve, reject) => {
    requestQueue.push({ url: cfg.url, resolve, reject })
  })
}

function refreshTokenIfNeeded(cfg: any) {
  // If the request is auth related, we let it through
  if (cfg.url.includes('/api/authenticate/')) {
    return Promise.resolve()
  }

  // If there are already requests in the queue, the token is actively being refreshed
  if (requestQueue.length) {
    return addToRequestQueue(cfg)
  }

  // If the token is still valid, just return
  if (isAccessTokenValid()) {
    return Promise.resolve()
  }

  // Otherwise, start the token refresh flow
  addToRequestQueue(cfg)

  return refreshToken()
    .then((resp) => {
      requestQueue.forEach((req: any) => req.resolve(resp))
    })
    .catch((err) => {
      requestQueue.forEach((req: any) => req.reject(err))
    })
    .finally(() => {
      requestQueue = []
    })
}

apiClient.interceptors.request.use(async (config) => {
  await refreshTokenIfNeeded(config)

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${getAccessToken()}`,
  }

  // If running locally allow ngrok
  if (ENVIRONMENT === 'local') {
    config.headers['ngrok-skip-browser-warning'] = '69420'
  }

  // Inject common values
  const banner = BloomreachPlugin.banner.value
  if (banner) {
    config.headers['X-Banner'] = banner
  }
  const locale = BloomreachPlugin.locale.value
  if (locale) {
    config.headers['X-Locale'] = locale
  }
  const userName = localStorage.getItem('userName')
  if (userName) {
    config.headers['X-User-Name'] = userName
  }

  return config
})

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // If the user gets unauthorized, we log them out
    if (error.response?.status === 401) {
      userLogout()
      location.reload()
    }

    return Promise.reject(error)
  }
)

export function useAPI(
  query: AxiosRequestConfig,
  cancelTokenCallback?: (source: CancelTokenSource) => void
) {
  const state: QueryState = reactive({
    data: null,
    loading: false,
    error: null,
  })

  let source: CancelTokenSource
  const fetch = async () => {
    state.loading = true
    state.error = null
    try {
      source = axios.CancelToken.source()
      cancelTokenCallback?.(source)
      const result = await apiClient({
        method: query.method,
        url: query.url,
        data: query.data || {},
        params: query.params || {},
        responseType: query.responseType || 'json',
        cancelToken: source.token,
      })
      const filetype =
        result?.headers['content-type'] === 'application/pdf'
          ? '.pdf'
          : result?.headers['content-type']?.includes('.sheet')
          ? '.xlsx'
          : undefined
      //TODO: Rewrite this as an interceptor..
      //If it's a PDF, download it.
      if (filetype) {
        //Since we are having trouble getting the content-disposition header from the server, we rely on client-side to tell us the filename.
        var filename = query.params?.filename
          ? query.params.filename + filetype
          : 'file' + filetype
        var file = new Blob([result.data], {
          type: result.headers['content-type'],
        })
        // For Internet Explorer and Edge
        if ('msSaveOrOpenBlob' in window.navigator) {
          window.navigator.msSaveOrOpenBlob(file, filename)
        }
        // For Firefox and Chrome
        else {
          // Bind blob on disk to ObjectURL
          var data = URL.createObjectURL(file)
          var a = document.createElement('a')
          a.style.display = 'none'
          a.href = data
          a.download = filename
          document.body.appendChild(a)
          a.click()
          // For Firefox
          setTimeout(function () {
            document.body.removeChild(a)
            // Release resource on disk after triggering the download
            window.URL.revokeObjectURL(data)
          }, 100)
        }
      } else {
        state.data = result.data
      }
    } catch (error) {
      state.error = error
      state.data = null
      throw error
    } finally {
      state.loading = false
    }
  }

  return {
    ...toRefs(state),
    fetch,
  }
}

export function useTypedAPI<T>(
  query: AxiosRequestConfig,
  cancelTokenCallback?: (source: CancelTokenSource) => void
) {
  return useAPI(query, cancelTokenCallback) as {
    fetch: () => Promise<void>
    data: Ref<T | null>
    error: Ref<Error | null>
    loading: Ref<boolean>
  }
}

<template>
  <div
    v-bind="$attrs"
    class="flex items-center relative cursor-pointer h-full"
    @click="toggleDropdown"
  >
    <div>
      <ul
        :class="[
          { hidden: !isDropdownOpen },
          'p-2 w-40 border-r bg-white absolute rounded left-0 shadow mt-8',
        ]"
      >
        <li
          v-for="item in items"
          :key="item.id"
          class="flex justify-start cursor-pointer items-center"
        >
          <slot name="dropdownContent" :content="item"></slot>
        </li>
      </ul>
    </div>

    <slot name="button"></slot>

    <slot name="controlButton" :isDropdownOpen="isDropdownOpen">
      <ChevronR
        :class="[
          'transition-all duration-150 ease-in-out transform opacity-0',
          { 'opacity-100 rotate-90': isDropdownOpen },
        ]"
      />
    </slot>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from 'vue'
import ChevronR from '../../../assets/chevron_r.svg'
export default defineComponent({
  name: 'GVAccountDropDown',
  components: { ChevronR },
  props: {
    items: {
      type: Array,
      default: [
        {
          id: 1,
          icon: 'http://placehold.it/15x15',
          title: 'My account',
          link: '#',
        },
      ],
    },
  },
  setup(props, { attrs }) {
    const items = ref(props.items)

    const dropdownOpen = ref(attrs.isOpen)
    const isDropdownOpen = computed(() => dropdownOpen.value)
    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value
    }

    return { isDropdownOpen, toggleDropdown, items }
  },
})
</script>

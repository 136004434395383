<template>
  <div v-if="isLoading">
    <SButton
      class="bg-gray-50 cursor-default mx-3"
      aria-disabled="true"
      disabled
    >
      &lt;
    </SButton>
    <SButton
      class="bg-gray-50 cursor-default mx-3"
      aria-disabled="true"
      disabled
    >
      &gt;
    </SButton>
  </div>
  <div v-else>
    {{ bloomreach.getText('pagination_page') }}
    {{ tableLength === 0 ? 0 : tablePage }}
    {{ bloomreach.getText('pagination_of') }}
    {{ Math.ceil(tableLength / limitPerPage) }}
    <SButton class="mx-3" @click="$emit('previousPage')"> &lt; </SButton>
    <input
      v-if="showGotoPage"
      v-bind="$attrs"
      v-model="inputValue"
      v-on:keyup="captureReturnKey"
      class="page-number py-2 px-2 font-extrabold border border-solid border-gray-300 rounded focus:border-gray-400 focus:outline-none"
      type="text"
    />
    <SButton class="mx-3" @click="$emit('nextPage')"> &gt; </SButton>
    {{ bloomreach.getText('pagination_record') }}
    {{ tableLength === 0 ? 0 : (tablePage - 1) * limitPerPage + 1 }}-{{
      tablePage * limitPerPage <= tableLength
        ? tablePage * limitPerPage
        : tableLength
    }}
    {{ bloomreach.getText('pagination_of') }} {{ tableLength }}
  </div>
</template>

<script>
import { defineComponent, inject, ref } from 'vue'
import { SButton } from '@rb2-bv/subby-ui'
import { bloomreachKey } from '@gv/core'

export default defineComponent({
  name: 'SPagination',
  components: { SButton },
  props: {
    tablePage: {
      type: Number,
      required: true,
    },
    tableLength: {
      type: Number,
      required: true,
    },
    limitPerPage: {
      type: Number,
      required: true,
    },
    onNextPage: {
      type: Function,
    },
    onPreviousPage: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showGotoPage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['gotoPage'],
  setup(props, { emit }) {
    const bloomreach = inject(bloomreachKey)
    const inputValue = ref('')
    const captureReturnKey = (e) => {
      if (e.keyCode === 13) {
        if (!isNaN(inputValue.value) && !isNaN(parseInt(inputValue.value))) {
          emit('gotoPage', parseInt(inputValue.value))
          inputValue.value = ''
        }
      }
    }
    return { bloomreach, captureReturnKey, inputValue }
  },
})
</script>
<style>
.page-number {
  text-align: center;
  width: 50px;
}
</style>

import { useAPI } from '../index'

// TODO: Fix the Imports to be import * from payment-capture.ts after refactoring
import {
  EP_PC_METHODS,
  EP_PC_PAYMENT,
  EP_PC_PAYMENTS_VALIDATE,
} from '../endpoints/uri'

// TODO: Organize by request type (POST/PUT/GET) and order alphabetically.
// Endpoints for the Payment Capture (PC)
export function getPCPaymentMethods(searchTerms: string) {
  return useAPI({
    method: 'get',
    url: EP_PC_METHODS,
    params: searchTerms,
  })
}

export function makePCPayment(paymentData: Object) {
  return useAPI({
    method: 'post',
    url: EP_PC_PAYMENT,
    data: paymentData,
  })
}

export function validatePCPayment(data: Object) {
  return useAPI({
    method: 'post',
    url: EP_PC_PAYMENTS_VALIDATE,
    data: data,
  })
}

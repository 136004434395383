export const BACKEND_URL: any =
  import.meta.env.VITE_BACKEND_URL || 'https://nl1-gls-zip-dk-p-app.azurewebsites.net'
export const CLIENT_SECRET: any =
  import.meta.env.VITE_CLIENT_SECRET || 's9oeWODcOhVgPIC7ICzMRpogPGqWrGYamAMYRw0XUpsvxgwCRcZxPiDl6NmRKG9'
export const AZURE_AD_CLIENT_ID: any =
  import.meta.env.VITE_AZURE_AD_CLIENT_ID || 'RTE_AZURE_AD_CLIENT_ID'
export const AZURE_AD_REDIRECT_URI: any =
  import.meta.env.VITE_AZURE_AD_REDIRECT_URI || 'RTE_AZURE_AD_REDIRECT_URI'
export const AZURE_AD_TENANT: any =
  import.meta.env.VITE_AZURE_AD_TENANT || 'RTE_AZURE_AD_TENANT'
export const PAYMENT_CLIENT_KEY: any =
  import.meta.env.VITE_PAYMENT_CLIENT_KEY || 'live_R2SAO4IZ6VA6BACUHEORZACSMYD5IHVA'
export const BLOOMREACH_BASE_URL: any =
  import.meta.env.VITE_BLOOMREACH_BASE_URL || 'https://www-prod.brxm.grandvision.io'
export const BLOOMREACH_PORTAL: any =
  import.meta.env.VITE_BLOOMREACH_PORTAL || 'customer-portal'
export const BLOOMREACH_OPCOS: any =
  import.meta.env.VITE_BLOOMREACH_OPCOS || 'dk-synoptik'
export const BLOOMREACH_LOCALE: any =
  import.meta.env.VITE_BLOOMREACH_LOCALE || 'da_DK'
export const LOCALES: any = import.meta.env.VITE_LOCALES || 'da-DK'
export const BANNERS: any = import.meta.env.VITE_BANNERS || 'RTE_BANNERS'
export const ASSENTLY_AUTH_SECRET: any =
  import.meta.env.VITE_ASSENTLY_AUTH_SECRET || '4FF51A3F-BFD7-4CE6-8469-0B45C4D0ACD5'
export const ASSENTLY_IDENTITY_SECRET: any =
  import.meta.env.VITE_ASSENTLY_IDENTITY_SECRET ||
  '491D0429-2ED0-47CD-8A3B-2DB6B98E27B9'
export const ENVIRONMENT: any =
  import.meta.env.VITE_ENVIRONMENT || 'PROD'
export const AZURE_AD_ROLES_READ: string =
  import.meta.env.VITE_AZURE_AD_ROLES_READ || 'RTE_AZURE_AD_ROLES_READ'
export const AZURE_AD_ROLES_WRITE: string =
  import.meta.env.VITE_AZURE_AD_ROLES_WRITE || 'RTE_AZURE_AD_ROLES_WRITE'
export const AZURE_AD_ROLES_ADMIN: string =
  import.meta.env.VITE_AZURE_AD_ROLES_ADMIN || 'RTE_AZURE_AD_ROLES_ADMIN'
export const AZURE_AD_ROLES_OLD: string =
  import.meta.env.VITE_AZURE_AD_ROLES_OLD || 'RTE_AZURE_AD_ROLES_OLD'
export const SENTRY_DSN: string =
  import.meta.env.VITE_SENTRY_DSN || 'https://56539828c7bcfee655989bf2058f51f1@o4505646100643840.ingest.sentry.io/4506020270637056'
export const SENTRY_ENVIRONMENT: string =
  import.meta.env.VITE_SENTRY_ENVIRONMENT || 'prod'
export const SENTRY_TRACES_SAMPLE_RATE: string =
  import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ||
  '0.001'
export const SENTRY_RELEASE: string =
  import.meta.env.VITE_SENTRY_RELEASE || '1.0.1'
export const SENTRY_ENABLED: string =
  import.meta.env.VITE_SENTRY_ENABLED || 'true'

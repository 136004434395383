<template>
  <div class="">
    <!-- default sidebar -->
    <div
      class="w-64 min-h-screen overflow-x-hidden lg:relative bg-white border-r flex-col justify-between hidden lg:flex pb-12"
    >
      <div class="px-8">
        <slot name="content"></slot>
      </div>
    </div>

    <!-- Mobile sidebar -->
    <div
      id="mobile-nav-bg"
      :class="[
        isOpen ? '-translate-x-0' : '-translate-x-full',
        'absolute z-40 w-full h-full lg:hidden transform transition duration-150 ease-in-out ',
      ]"
    >
      <!-- bg over main content -->
      <div
        :class="[
          isOpen ? 'opacity-60' : 'opacity-0',
          'bg-gray-800 transform w-full h-full absolute transition-opacity duration-150 ease-in-out',
        ]"
        @click="$emit('toggleMobileNav')"
      ></div>
      <!-- sidebar -->
      <div
        id="mobile-nav"
        :class="[
          isOpen ? '-translate-x-0' : '-translate-x-full',
          'w-4/5 md:w-64 z-20 absolute bg-white shadow h-full flex-col justify-between pb-4 transition duration-150 ease-in-out',
        ]"
      >
        <div class="px-8">
          <slot name="mobileContent"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SSidebar',
  inheritAttrs: false,
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleMobileNav'],
})
</script>

<template>
  <div
    v-if="
      [
        'Login',
        'ReviewInvoices',
        'PaymentCapture',
        'PaymentCallback',
        'AuthCallback',
        'foo',
      ].includes($route.name)
    "
  >
    <router-view></router-view>
  </div>
  <div v-else>
    <div
      v-if="bloomreachLoading"
      class="w-full h-screen bg-black flxed z-50 flex flex-col items-center backdrop-filter backdrop-blur-md bg-opacity-80"
    >
      <SLoading class="m-auto h-20 w-20" />
    </div>
    <div
      v-else
      class="relative flex flex-no-wrap overflow-hidden"
      :class="[sidebarOpen ? 'h-screen' : 'min-h-screen']"
    >
      <div class="lg:fixed lg:top-0 lg:left-0">
        <GVSidebar :isOpen="sidebarOpen" @toggle-mobile-nav="toggleSidebar">
          <template v-slot:content>
            <a
              href="https://www.synoptik.dk/"
              target="_blank"
              v-if="bloomreach.getLocale() === 'da-DK'"
              class="h-16 w-full flex items-center"
            >
              <SynoptikLogo />
            </a>
            <a
              href="https://www.synoptik.se/"
              target="_blank"
              v-if="bloomreach.getLocale() === 'sv-SE'"
              class="h-16 w-full flex items-center"
            >
              <SynoptikLogo />
            </a>
            <ul class="mt-12">
              <li
                class="flex w-full justify-between items-center mb-6"
                v-for="item in menuItems"
                :key="item.name"
              >
                <slot name="menuContent" :content="item">
                  <router-link
                    :to="{ name: item.name }"
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                    class="w-full"
                  >
                    <div class="flex">
                      <div v-html="item.icon"></div>
                      <span class="text-sm ml-2">{{ item.title }}</span>
                    </div>
                  </router-link>
                </slot>
              </li>
            </ul>

            <div class="border-t mt-10">
              <DropdownButtons
                class="-mx-6 pt-6"
                :userData="userData"
                :userDropDown="userDropDown"
                :flagDropDown="flagDropDown"
              />
            </div>
          </template>

          <template v-slot:mobileContent>
            <div class="h-screen overflow-y-hidden">
              <div class="mt-12 flex">
                <div class="w-full flex items-center">
                  <a
                    href="https://www.synoptik.dk/"
                    target="_blank"
                    v-if="bloomreach.getLocale() === 'da-DK'"
                    class="h-16 w-full flex items-center"
                  >
                    <SynoptikLogo />
                  </a>
                  <a
                    href="https://www.synoptik.se/"
                    target="_blank"
                    v-if="bloomreach.getLocale() === 'sv-SE'"
                    class="h-16 w-full flex items-center"
                  >
                    <SynoptikLogo />
                  </a>
                </div>
              </div>
              <ul class="mt-12">
                <li
                  class="flex w-full justify-between items-center mb-6"
                  v-for="item in menuItems"
                  :key="item.name"
                >
                  <router-link
                    :to="{ name: item.name }"
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                    @click="toggleSidebar"
                    class="w-full"
                  >
                    <div class="flex">
                      <div v-html="item.icon"></div>
                      <span class="text-sm ml-2">{{ item.title }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
              <div class="flex pt-10 border-t">
                <DropdownButtons
                  class="pr-5 flex"
                  :userData="userData"
                  :userDropDown="userDropDown"
                  :flagDropDown="flagDropDown"
                />
              </div>
            </div>
          </template>
        </GVSidebar>
      </div>
      <div class="relative w-full lg:ml-64">
        <GVNavbar @toggle-mobile-nav="toggleSidebar">
          <template v-slot:content>
            <div class="h-full flex mx-auto items-center">
              <p class="text-lg font-semibold">
                {{ bloomreach.getText('my_synoptic_glasses_subscription') }}
              </p>
            </div>
          </template>

          <template v-slot:mobileBranding>
            <a
              href="https://www.synoptik.dk/"
              target="_blank"
              v-if="bloomreach.getLocale() === 'da-DK'"
              class="h-16 w-3/4 flex items-center"
            >
              <SynoptikLogo />
            </a>
            <a
              href="https://www.synoptik.se/"
              target="_blank"
              v-if="bloomreach.getLocale() === 'sv-SE'"
              class="h-16 w-3/4 flex items-center"
            >
              <SynoptikLogo />
            </a>
          </template>
        </GVNavbar>
        <div
          class="container mx-auto pt-28 pb-6 px-2 sm:px-10 md:px-20 container-height overflow-x-hidden"
        >
          <SBreadcrumbs class="mb-10" />
          <router-view v-slot="{ Component }">
            <transition
              enter-active-class="transition ease-out duration-400 transform"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition ease-in duration-400 transform"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
              mode="out-in"
            >
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  inject,
} from 'vue'
import {
  GVNavbar,
  GVSidebar,
  SSearchbar,
  SLink,
  SBreadcrumbs,
  customerUserLogout,
  SLoading,
  isMobile,
  bloomreachKey,
} from '@gv/core'
import { useRouter, useRoute } from 'vue-router'
import DropdownButtons from './components/dropdownButtons.vue'

import SynoptikLogo from './assets/SynoptikLogo.svg'

export default defineComponent({
  name: 'Backoffice',
  components: {
    SLoading,
    GVNavbar,
    GVSidebar,
    SSearchbar,
    SLink,
    DropdownButtons,
    SynoptikLogo,
    SBreadcrumbs,
  },
  watch: {
    bloomreachLoading(val) {
      this.updateMenuItems()
    },
  },
  setup() {
    const sidebarOpen = ref(false)
    const router = useRouter()
    const route = useRoute()
    const bloomreach = inject(bloomreachKey)!
    const bloomreachLoading = ref(bloomreach.loading)
    const userData = ref(JSON.parse(localStorage.getItem('userCache') || '{}'))
    const userDropDown = [
      {
        id: 1,
        title: 'logout',
        link: '/logout',
      },
    ]

    const flagDropDown = [
      {
        id: 1,
        title: 'Danish',
        locale: 'da-DK',
      },
      {
        id: 1,
        title: 'Swedish',
        locale: 'sv-SE',
      },
    ]

    const logout = () => {
      customerUserLogout(router, { name: 'Login' })
    }

    onBeforeMount(() => {
      if (userData.value === null) logout()
    })

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value
    }

    router.beforeEach((to, from, next) => {
      userData.value = JSON.parse(localStorage.getItem('userCache') || '{}')
      next()
    })

    const menuItems = [
      {
        id: 1,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="7" r="4" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
          </svg>`,
        title: 'Dashboard',
        name: 'Home',
      },
      {
        id: 2,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-credit-card" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="3" y="5" width="18" height="14" rx="3" />
                <line x1="3" y1="10" x2="21" y2="10" />
                <line x1="7" y1="15" x2="7.01" y2="15" />
                <line x1="11" y1="15" x2="13" y2="15" />
                </svg>`,
        title: 'Payment method',
        name: 'PaymentMethod',
      },
      {
        id: 3,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-receipt-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2" />
              <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5" />
              </svg>`,
        title: 'Bills',
        name: 'Bills',
      },

      {
        id: 4,
        icon: `<svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-settings"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                  <path d="M7 12h14l-3 -3m0 6l3 -3" />
                </svg>`,
        title: 'Payments',
        name: 'PaymentsRefunds',
      },

      {
        id: 5,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eyeglass-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M8 4h-2l-3 10v2.5" />
                <path d="M16 4h2l3 10v2.5" />
                <line x1="10" y1="16" x2="14" y2="16" />
                <circle cx="17.5" cy="16.5" r="3.5" />
                <circle cx="6.5" cy="16.5" r="3.5" />
                </svg>`,
        title: 'My profile',
        name: 'AccountDetails',
      },
      {
        id: 6,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-refresh" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
                <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
              </svg>`,
        title: 'Subscriptions',
        name: 'Subscriptions',
      },
      {
        id: 7,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-zoom-question" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="10" cy="10" r="7" />
          <path d="M21 21l-6 -6" />
          <line x1="10" y1="13" x2="10" y2="13.01" />
          <path d="M10 10a1.5 1.5 0 1 0 -1.14 -2.474" />
          </svg>`,
        title: 'Questions & Answers',
        name: 'Faq',
      },
      {
        id: 8,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                  <path d="M15 6h6m-3 -3v6" />
                </svg>`,
        title: 'Contact',
        name: 'Contact',
      },
    ]

    const updateMenuItems = () => {
      menuItems[0].title = bloomreach.getText('dashboard')
      menuItems[1].title = bloomreach.getText('payment_method')
      menuItems[2].title = bloomreach.getText('bills')
      menuItems[3].title = bloomreach.getText('payments')
      menuItems[4].title = bloomreach.getText('profile')
      menuItems[5].title = bloomreach.getText('subscriptions')
      menuItems[6].title = bloomreach.getText('questions_and_answers')
      menuItems[7].title = bloomreach.getText('contact')
      userDropDown[0].title = bloomreach.getText('logout')
    }

    const userActivityTimeout = ref(null)
    const userActivityThrottlerTimeout = ref(null)

    const userActivityThrottler = () => {
      if (!userActivityThrottlerTimeout.value) {
        userActivityThrottlerTimeout.value = setTimeout(() => {
          resetUserActivityTimeout()
          clearTimeout(userActivityThrottlerTimeout.value)
          userActivityThrottlerTimeout.value = null
        }, 500)
      }
    }

    const TIME_TILL_LOGOUT = 3_0_0_0_0_0

    const resetUserActivityTimeout = () => {
      clearTimeout(userActivityTimeout.value)
      userActivityTimeout.value = setTimeout(() => {
        customerUserLogout()
        location.reload()
      }, TIME_TILL_LOGOUT)
    }

    router.afterEach((to) => {
      const guestRoutes = [
        'Login',
        'ReviewInvoices',
        'PaymentCapture',
        'PaymentCallback',
      ]
      if (isMobile() && !guestRoutes.includes(to.name.toString())) {
        activateActivityTracker()
      }
    })

    const activateActivityTracker = () => {
      window.addEventListener('mousemove', userActivityThrottler)
      window.addEventListener('scroll', userActivityThrottler)
      window.addEventListener('keydown', userActivityThrottler)
      window.addEventListener('resize', userActivityThrottler)
    }

    onBeforeUnmount(() => {
      window.removeEventListener('mousemove', userActivityThrottler)
      window.removeEventListener('scroll', userActivityThrottler)
      window.removeEventListener('keydown', userActivityThrottler)
      window.removeEventListener('resize', userActivityThrottler)
      clearTimeout(userActivityTimeout.value)
      clearTimeout(userActivityThrottlerTimeout.value)
    })

    return {
      sidebarOpen,
      toggleSidebar,
      userData,
      userDropDown,
      flagDropDown,
      bloomreachLoading,
      logout,
      bloomreach,
      menuItems,
      updateMenuItems,
    }
  },
})
</script>

<style scoped>
.container-height {
  min-height: 100vh;
}
@media screen and (min-width: 768px) {
  .container-height {
    min-height: 70vh;
  }
}
</style>

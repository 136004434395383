<template>
  <div>
    <div v-if="invoicesDataStore.loading" class="text-center">
      <SLoading class="m-auto h-24 w-24" />
    </div>
    <div v-else>
      <div class="flex justify-between items-center my-2">
        <SButton
          v-if="invoicesDataStore.invoicesTable.data.length > 0 && !isMobile()"
          @click="downloadAllClick"
          variant="default"
        >
          <div class="flex">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Complete">
                <g id="download">
                  <g>
                    <path
                      d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7"
                      fill="none"
                      stroke="#000000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <g>
                      <polyline
                        data-name="Right"
                        fill="none"
                        id="Right-2"
                        points="7.9 12.3 12 16.3 16.1 12.3"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />

                      <line
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        x1="12"
                        x2="12"
                        y1="2.7"
                        y2="14.2"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <span class="ml-3">{{ getText('download_selected') }}</span>
          </div>
        </SButton>
        <div class="flex">
          <SButton
            variant="default"
            :class="[{ 'bg-gray-100 ': isFilterOpen }]"
            @click="toggleFilters"
            ><div class="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-filter mr-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5"
                ></path>
              </svg>
              {{ getText('filters') }}
            </div></SButton
          >
          <div class="block ml-2">
            <SButton variant="default" @click="resetSorting">{{
              getText('reset_sorting')
            }}</SButton>
          </div>
        </div>
      </div>
      <div
        :class="[
          { hidden: !isFilterOpen },
          'bg-white px-4 py-5 border-b border-gray-200 sm:px-6 sm:rounded-lg mb-4',
        ]"
      >
        <div
          class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
        >
          <div class="ml-4 mt-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ getText('filters') }}
            </h3>
          </div>
        </div>
        <div class="grid grid-cols-6">
          <div
            class="text-right items-center my-2 pt-2 pr-3 block text-sm font-medium text-gray-700 sm:pt-2"
          >
            {{ getText('number') }}
          </div>
          <div>
            <SInput
              class="text-sm my-2"
              :name="'number'"
              v-model="filters.number"
              v-on:keyup="captureReturnKey"
            />
          </div>

          <div
            class="text-right items-center my-2 pt-2 pr-3 block text-sm font-medium text-gray-700 sm:pt-2"
          >
            {{ getText('from_date') }}
          </div>
          <div>
            <SInput
              class="text-sm my-2"
              :name="'fromDate'"
              type="date"
              v-model="filters.fromDate"
              v-on:keyup="captureReturnKey"
            />
          </div>

          <div
            class="text-right items-center my-2 pt-2 pr-3 block text-sm font-medium text-gray-700 sm:pt-2"
          >
            {{ getText('to_date') }}
          </div>
          <div>
            <SInput
              class="text-sm my-2"
              :name="'toDate'"
              type="date"
              v-model="filters.toDate"
              v-on:keyup="captureReturnKey"
            />
          </div>

          <div
            class="text-right items-center my-2 pt-2 pr-3 block text-sm font-medium text-gray-700 sm:pt-2"
          >
            {{ getText('from_amount') }}
          </div>
          <div>
            <SInput
              class="text-sm my-2"
              :name="'fromAmount'"
              v-model="filters.fromAmount"
              v-on:keyup="captureReturnKey"
            />
          </div>

          <div
            class="text-right items-center my-2 pt-2 pr-3 block text-sm font-medium text-gray-700 sm:pt-2"
          >
            {{ getText('to_amount') }}
          </div>
          <div>
            <SInput
              class="text-sm my-2"
              :name="'toAmount'"
              v-model="filters.toAmount"
              v-on:keyup="captureReturnKey"
            />
          </div>
        </div>

        <div
          class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
        >
          <div class="ml-4 mt-2 flex-grow text-right">
            <div>
              <SButton
                @click="applyFilters()"
                variant="bare"
                class="bg-primaryColor-medium text-white transition duration-150 ease-in-out hover:bg-gray-200 rounded hover:text-gray-800 px-8 py-3 text-sm mt-6"
              >
                {{ getText('filters_apply') }}
              </SButton>
              <div class="block mt-5">
                <SLink
                  href="#"
                  variant="bare"
                  class="text-sm text-gray-400 hover:text-black"
                  @click="resetFilters"
                  >{{ getText('filters_reset') }}
                </SLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div v-if="invoicesDataStore.invoicesTable.data.length > 0">
          <STable
            v-if="!isMobile()"
            :headers="invoicesDataStore.invoicesTable.headers"
            :data="invoicesDataStore.invoicesTable.data"
            :actions="tableActions"
            :paginated="true"
            :limitPerPage="10"
            :actionsTitle="getText('invoice_copy')"
            :row-clickable="false"
            :sortable="true"
            @rowClick="onRowClick"
          />
          <!-- TODO: Refactor into new component "MobileTable" or add behavior into STable component -->
          <div
            v-else
            v-for="data in invoicesDataStore.invoicesTable.data"
            :key="data.id"
            class="my-4 border shadow-md p-4 w-full"
          >
            <table class="w-full">
              <tr
                v-for="(header, index) in invoicesDataStore.invoicesTable
                  .headers"
                :key="header.name"
                :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'"
              >
                <template v-if="header.inputType != 'checkbox'">
                  <th
                    class="px-2 text-left text-xs font-medium text-gray-700 uppercase tracking-wider w-1/2"
                  >
                    {{ header.name }}
                  </th>
                  <td
                    class="px-2 text-right text-small w-1/2"
                    :class="[header.extraClass ? data['_class'] : 'text-sm']"
                  >
                    {{ data[header.field] }}
                  </td>
                </template>
              </tr>
            </table>

            <div class="mt-4 flex w-full justify-end">
              <SButton
                variant="default"
                @click="($event) => downloadIndividualInvoice(data.number)"
                v-if="isValidInvoiceNumber(data.number)"
              >
                {{ getText('download') }}
              </SButton>
            </div>
          </div>
        </div>

        <div v-else>
          <NoData />
        </div>
      </div>
    </div>
    <div class="w-full text-right my-8">
      <SDialogBox
        :show="downloadModal"
        :fullWidth="false"
        @close-modal="downloadModal = false"
      >
        <template v-slot:header
          ><p class="text-2xl font-bold">
            {{ getText('invoice_download_title') }} {{ currentInvoice.number }}
          </p></template
        >
        <template v-slot:body>
          <div class="flex justify-center">
            <div>
              <SLoading class="mt-8 relative h-24 w-24 m-auto" />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex justify-end mt-20 border-t pt-10">
            <button
              variant="bare"
              class="text-sm text-gray-400 text-primaryColor-dark hover:text-black"
              @click="downloadModal = false"
            >
              {{ getText('cancel') }}
            </button>
          </div>
        </template>
      </SDialogBox>
    </div>
    <div class="w-full text-right my-8">
      <SDialogBox
        :show="firstPaymentModal"
        @close-modal="firstPaymentModal = false"
      >
        <template v-slot:header
          ><p class="text-2xl font-bold">
            {{ getText('invoice_payment_title') }} {{ currentInvoice.number }}
          </p></template
        >
        <template v-slot:body>
          <div v-if="paymentsDataStore.loading" class="flex justify-center">
            <div>
              <SLoading class="mt-8 relative h-24 w-24 m-auto" />
            </div>
          </div>
          <div v-else>
            <DefaultPaymentMethodCard
              :pay-buttons-text="true"
              :has-buttons="true"
              :data-default-payment-method="
                paymentsDataStore.defaultPaymentMethod
              "
              :is-mandate-requested="
                accountDataStore.data.mandateActive === 'Requested'
              "
              @open-payment-window="openPaymentWindow"
            />
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex justify-end mt-20 border-t pt-10">
            <button
              variant="bare"
              class="text-sm text-primaryColor-dark hover:text-black"
              @click="firstPaymentModal = false"
            >
              {{ getText('cancel') }}
            </button>
          </div>
        </template>
      </SDialogBox>
    </div>
    <GVPaymentTypeModal
      :open-modal="choosePaymentMethodModal"
      @update-modal="closePaymentWindow"
      @payment-type="choosePaymentType"
    />
    <GVPaymentModal
      :open-modal="payNowModal"
      :payment-option="paymentOption"
      :invoice="currentInvoice"
      :has-default-payment-method="
        paymentsDataStore.defaultPaymentMethod.paymentMethodType &&
        paymentsDataStore.defaultPaymentMethod.paymentMethodType != 'None'
      "
      @update-modal="changeOpenModal"
      @payment-successful="paymentComplete"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, inject } from 'vue'
import {
  STable,
  SButton,
  SDialogBox,
  SInput,
  SLoading,
  SLink,
  downloadInvoicePP,
  downloadDocumentTypePP,
  GVPaymentModal,
  GVButton,
  GVPaymentTypeModal,
  isMobile,
  formatDate,
  LOCALES,
  parsedCurrencyToFloat,
  downloadCreditMemoPP,
  bloomreachKey,
  GVCheckbox,
} from '@gv/core'

import DefaultPaymentMethodCard from '../components/DefaultPaymentMethodCard.vue'
import { formatCurrency } from '../../../core/src/lib/functions'

import { useAccountDataStore } from '../stores/accountData'
import { usePaymentsDataStore } from '../stores/paymentsData'
import { useInvoicesDataStore } from '../stores/invoicesData'
import NoData from '../pages/NoData.vue'

export default defineComponent({
  name: 'Invoices',
  components: {
    STable,
    SButton,
    SDialogBox,
    SInput,
    SLoading,
    SLink,
    GVPaymentModal,
    GVButton,
    GVPaymentTypeModal,
    DefaultPaymentMethodCard,
    NoData,
    GVCheckbox,
  },

  setup() {
    const filters = ref({
      number: '',
      fromDate: '',
      toDate: '',
      fromAmount: '',
      toAmount: '',
      balance: '',
    })
    const downloadModal = ref(false),
      bloomreach = inject(bloomreachKey),
      getText = (key: string) => bloomreach.getText(key),
      choosePaymentMethodModal = ref(false),
      firstPaymentModal = ref(false),
      payNowModal = ref(false),
      paymentOption = ref(''),
      currentInvoice = ref({}),
      tableHeaders = ref([]),
      tableData = ref([]),
      currency = LOCALES.split(', ')[0] == 'da-DK' ? 'DKK' : 'SEK',
      invoicesDataStore = useInvoicesDataStore(),
      accountDataStore = useAccountDataStore(),
      paymentsDataStore = usePaymentsDataStore(),
      isFilterOpen = ref(false),
      toggleFilters = () => {
        isFilterOpen.value = !isFilterOpen.value
      },
      downloadInvoiceModal = (row) => {
        currentInvoice.value = row
        downloadModal.value = true
        //Find the associated ID.
        let invoice = invoicesDataStore.data.find(
          (x) => x.number === row.number
        )

        if (invoice.type === 'CreditMemo') {
          let { fetch: downloadCredit } = downloadCreditMemoPP(
            invoice.latestPDFFileId,
            invoice.number
          )
          setTimeout(() => {
            downloadCredit()
            downloadModal.value = false
          }, 1000)

          return
        }
        if (invoice.type === 'Invoice') {
          let { fetch } = downloadInvoicePP(invoice.id, invoice.number)
          setTimeout(() => {
            fetch()
            downloadModal.value = false
          }, 1000)
        } else {
          let { fetch: downloadCredit } = downloadDocumentTypePP(
            invoice.latestPDFFileId,
            invoice.number
          )

          setTimeout(() => {
            downloadCredit()
            downloadModal.value = false
          }, 1000)
        }
      },
      openPaymentWindow = (object: object) => {
        firstPaymentModal.value = false
        paymentOption.value = object.option
        if (object.option === 'newPaymentMethod') {
          choosePaymentType('CreditCard')
        } else {
          payNowModal.value = true
        }
      },
      closePaymentWindow = () => {
        choosePaymentMethodModal.value = false
        payNowModal.value = false
      },
      choosePaymentType = (option: string) => {
        if (option === 'CreditCard') {
          choosePaymentMethodModal.value = false
          payNowModal.value = true
        }
      },
      payNow = async (row) => {
        try {
          firstPaymentModal.value = true
          let invoice = invoicesDataStore.data.find(
            (x) => x.number === row.number
          )
          currentInvoice.value = invoice
          if (
            (paymentsDataStore.defaultPaymentMethod &&
              !paymentsDataStore.defaultPaymentMethod.paymentMethodType) ||
            (paymentsDataStore.defaultPaymentMethod.paymentMethodType &&
              paymentsDataStore.defaultPaymentMethod.paymentMethodType ==
                'None')
          ) {
            firstPaymentModal.value = false
            paymentOption.value = 'newPaymentMethod'
            choosePaymentType('CreditCard')
          }
        } catch (error) {
          console.error(error)
          throw Error(error)
        }
      },
      changeOpenModal = (value: boolean) => {
        payNowModal.value = value
      },
      paymentComplete = (value: boolean) => {
        fetch()
      },
      downloadAllClick = () => {
        const checkedRow = invoicesDataStore.invoicesTable.data.filter(
          (x) => x.select
        )
        if (checkedRow.length > 0) {
          for (let item of checkedRow) {
            downloadModal.value = true

            let invoice = invoicesDataStore.data.find(
              (x) => x.number === item.number
            )
            if (invoice.type === 'Invoice') {
              let { fetch } = downloadInvoicePP(invoice.id, invoice.number)
              setTimeout(() => {
                fetch()
                downloadModal.value = false
              }, 1000)
            } else {
              let { fetch: downloadCredit } = downloadDocumentTypePP(
                invoice.latestPDFFileId,
                invoice.number
              )

              setTimeout(() => {
                downloadCredit()
                downloadModal.value = false
              }, 1000)
            }
          }
        } else {
          // todo
        }
      },
      downloadIndividualInvoice = (number: string) => {
        let invoice = invoicesDataStore.data.find((x) => x.number === number)
        // In case we don't find anything??
        if (typeof invoice == 'undefined') return

        downloadModal.value = true
        if (invoice.type === 'Invoice') {
          let { fetch } = downloadInvoicePP(invoice.id, invoice.number)
          setTimeout(() => {
            fetch()
            downloadModal.value = false
          }, 1000)
        } else {
          let { fetch: downloadCredit } = downloadDocumentTypePP(
            invoice.latestPDFFileId,
            invoice.number
          )

          setTimeout(() => {
            downloadCredit()
            downloadModal.value = false
          }, 1000)
        }
      },
      tableActions = [
        {
          isIcon: false,
          content: getText('download'),
          condition: (row) => isValidInvoiceNumber(row.number),
          onClick: downloadInvoiceModal,
        },
        // {
        //   isIcon: false,
        //   content: getText('pay_now'),
        //   condition: (row) => {
        //     return parseInt(row.balance) > 0
        //     // return parseInt(row.balance.charAt(0)) > 0
        //   },
        //   onClick: payNow,
        // },
      ]

    const isValidInvoiceNumber = (number) => {
      if (typeof number !== 'string') {
        return false
      }

      const lowercaseNumber = number.toLowerCase()
      return !(
        lowercaseNumber.includes('mig_') || lowercaseNumber.includes('i_')
      )
    }

    const applyFilters = () => {
      let baseList = JSON.parse(JSON.stringify(invoicesDataStore.data))
      let formattedFilter, formattedData
      let filtersList = filters.value
      for (var index in filtersList) {
        let key = String(index)
        if (filtersList[key].length > 0 || filtersList[key] === true) {
          baseList = baseList.filter((data) => {
            switch (key) {
              case 'fromDate':
                formattedFilter = formatDate(
                  filtersList[key],
                  bloomreach,
                  'YYYY-MM-DD'
                )
                formattedData = formatDate(
                  data['date'],
                  bloomreach,
                  'YYYY-MM-DD'
                )
                return (
                  filtersList[key] &&
                  data.hasOwnProperty('date') &&
                  data['date'] &&
                  formattedData >= formattedFilter
                )
              case 'toDate':
                //? This formatting returns the YYYY-MM-DD date format which is required for this specific comparison
                const formattedFilterFrom = formatDate(
                  filtersList['fromDate'],
                  bloomreach,
                  'YYYY-MM-DD'
                )
                const formattedFilterTo = formatDate(
                  filtersList[key],
                  bloomreach,
                  'YYYY-MM-DD'
                )
                formattedData = formatDate(
                  data['date'],
                  bloomreach,
                  'YYYY-MM-DD'
                )
                return (
                  filtersList[key] &&
                  data.hasOwnProperty('date') &&
                  data['date'] &&
                  formattedData >= formattedFilterFrom &&
                  formattedData <= formattedFilterTo
                )
              case 'fromAmount':
                formattedFilter = filtersList[key]
                formattedData = parsedCurrencyToFloat(
                  formatCurrency(data['amount'], currency, false, false)
                )
                return (
                  filtersList[key] &&
                  data.hasOwnProperty('amount') &&
                  data['amount'] &&
                  formattedData >= formattedFilter
                )
              case 'toAmount':
                //? This formattedData is formatted which is required for this specific comparison
                const formattedAmountFrom = filtersList['fromAmount']
                const formattedAmountTo = filtersList[key]
                formattedData = parsedCurrencyToFloat(
                  formatCurrency(data['amount'], currency, false, false)
                )
                return (
                  filtersList[key] &&
                  data.hasOwnProperty('amount') &&
                  data['amount'] &&
                  formattedData >= formattedAmountFrom &&
                  formattedData <= formattedAmountTo
                )
              default:
                return (
                  filtersList[key] &&
                  data.hasOwnProperty(key) &&
                  data[key] &&
                  String(data[key])
                    .toLowerCase()
                    .includes(String(filtersList[key]).toLowerCase())
                )
            }
          })
        }
      }

      invoicesDataStore.applyFilters(baseList, bloomreach, currency)
    }

    const captureReturnKey = (e) => {
      if (e.keyCode === 13) {
        applyFilters()
      }
    }

    const resetFilters = () => {
      filters.value = {
        number: '',
        fromDate: '',
        toDate: '',
        fromAmount: '',
        toAmount: '',
        balance: '',
      }

      invoicesDataStore.formatInvoicesData(bloomreach, currency)
    }
    const resetSorting = () => {
      invoicesDataStore.formatInvoicesData(bloomreach, currency)
    }

    onMounted(async () => {
      // In case the page refreshes or this is the first route accessed
      if (invoicesDataStore.storeNotStarted) invoicesDataStore.startStore()
      if (invoicesDataStore.data !== null) {
        invoicesDataStore.data = invoicesDataStore.data.map((invoice) => {
          if (invoice.type === 'ReminderLetter') {
            const { paymentAmount, dueDate, period, balance, ...rest } = invoice
            return rest
          }
          return invoice
        })
      }
    })

    const onRowClick = (element: any, rowKey: number) => {
      // "element" from the event isn't the actual row, it's the column.
      // Use the rowKey to get the actual row from the data store.
      const row = invoicesDataStore.invoicesTable.data[rowKey] as {
        select: boolean
      }
      if (!row) return

      row.select = !row.select
    }

    return {
      tableActions,
      tableHeaders,
      tableData,
      downloadModal,
      currentInvoice,
      downloadInvoiceModal,
      isFilterOpen,
      toggleFilters,
      payNowModal,
      changeOpenModal,
      paymentOption,
      choosePaymentMethodModal,
      openPaymentWindow,
      paymentComplete,
      closePaymentWindow,
      choosePaymentType,
      firstPaymentModal,
      getText,
      isMobile,
      filters,
      resetFilters,
      applyFilters,
      resetSorting,
      downloadAllClick,
      downloadIndividualInvoice,
      isValidInvoiceNumber,
      invoicesDataStore,
      accountDataStore,
      paymentsDataStore,
      captureReturnKey,
      onRowClick,
    }
  },
})
</script>

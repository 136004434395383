<template>
  <teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="showModal"
        ref="modal-backdrop"
        class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50"
      >
        <div
          class="flex items-start justify-center min-h-screen pt-24 text-center"
          @click.self="$emit('closeModal')"
        >
          <transition
            enter-active-class="transition ease-out duration-300 transform "
            enter-from-class="opacity-0 translate-y-10 scale-95"
            enter-to-class="opacity-100 translate-y-0 scale-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0 scale-100"
            leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95"
          >
            <div
              class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-11/12 md:w-3/5 lg:w-1/3"
              role="dialog"
              ref="modal"
              aria-modal="true"
              v-show="showModal"
              aria-labelledby="modal-headline"
            >
              <div class="flex justify-end items-center pb-4">
                <div
                  class="modal-close cursor-pointer z-50"
                  @click="$emit('closeModal')"
                >
                  <svg
                    class="fill-current text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path
                      d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                    ></path>
                  </svg>
                </div>
              </div>

              <div>
                <div class="flex justify-center mb-4">
                  <question-icon class="text-center w-8 h-8" />
                </div>
                <slot name="body">
                  <p class="text-xl font-bold text-center">
                    Are you sure you want to perform this action?
                  </p>
                </slot>
              </div>
              <slot name="footer">
                <div
                  class="mt-10 grid grid-cols-2 w-full gap-x-4 border-t pt-6"
                >
                  <button
                    class="bg-transparent p-3 w-full outline-none focus:outline-none text-gray-500 hover:bg-gray-100 hover:text-gray-400 border"
                    @click="$emit('closeModal')"
                  >
                    {{ bloomreach.getText('cancel') }}
                  </button>
                  <button
                    class="bg-black w-full outline-none focus:outline-none hover:bg-gray-800 p-3 text-white"
                    :class="{
                      'opacity-20 bg-gray-800 cursor-not-allowed': disabled,
                    }"
                    @click="$emit('confirm')"
                    :disabled="disabled"
                  >
                    {{ bloomreach.getText('ok') }}
                  </button>
                </div>
              </slot>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { ref, watch, defineComponent, inject } from 'vue'
import QuestionIcon from '../../../assets/question-mark.svg'
import { bloomreachKey } from '@gv/core'

export default defineComponent({
  inheritAttrs: false,
  name: 'SConfirmDialogBox',
  emits: ['closeModal', 'confirm', 'preview'],
  components: {
    QuestionIcon,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const showModal = ref(props.show)
    const bloomreach = inject(bloomreachKey)

    function closeModal() {
      showModal.value = false
    }

    watch(
      () => props.show,
      (show) => {
        showModal.value = show
      }
    )

    return {
      closeModal,
      showModal,
      bloomreach,
    }
  },
})
</script>

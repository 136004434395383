<template>
  <div>
    <div v-if="paymentsDataStore.loading" class="text-center">
      <SLoading class="m-auto h-24 w-24" />
    </div>
    <div v-else-if="paymentsDataStore.error" class="text-center">
      <h2
        class="px-2 text-left text-lg font-medium text-gray-700 uppercase tracking-wider"
      >
        {{ bloomreach.getText('generic_error_msg') }}
      </h2>
    </div>
    <div
      v-else-if="
        paymentsDataStore.paymentsTable &&
        paymentsDataStore.paymentsTable.data &&
        paymentsDataStore.paymentsTable.data.length === 0
      "
    >
      <div class="w-full m-auto -mt-24">
        <div class="italic text-2xl text-center w-full">
          <NoData />
          <p>
            {{ bloomreach.getText('no_payments_yet') }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-else-if="
        paymentsDataStore.paymentsTable &&
        paymentsDataStore.paymentsTable.data &&
        paymentsDataStore.paymentsTable.data.length > 0
      "
    >
      <STable
        v-if="!isMobile()"
        :headers="paymentsDataStore.paymentsTable.headers"
        :data="paymentsDataStore.paymentsTable.data"
        :paginated="true"
        :limitPerPage="10"
      />
      <!-- TODO: Refactor into new component "MobileTable" or add behavior into STable component -->
      <div
        v-else
        v-for="(data, index) in paymentsDataStore.paymentsTable.data"
        :key="data.id"
        class="my-4 border shadow-md p-4 w-full"
      >
        <table class="w-full">
          <tr
            v-for="(header, index) in paymentsDataStore.paymentsTable.headers"
            :key="header.name"
            :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'"
          >
            <th
              class="px-2 text-left text-xs font-medium text-gray-700 uppercase tracking-wider w-1/2"
            >
              {{ header.name }}
            </th>
            <td
              class="px-2 text-right text-small w-1/2"
              :class="[header.extraClass ? data['_class'] : 'text-sm']"
            >
              {{ data[header.field] }}
            </td>
          </tr>
        </table>
        <div
          v-if="tableActions && tableActions.length > 0"
          class="my-2 w-full grid grid-flow-row"
        >
          <div
            v-for="(action, index) in tableActions.filter((action) => {
              return !action.condition || action.condition(data)
            })"
            :key="index"
            class="w-full justify-center"
          >
            <button
              @click="action.onClick(data)"
              class="border bg-secondaryColor-medium text-white w-full text-center py-2 px-5 transition duration-300 ease-in-out cursor-pointer mt-2"
            >
              {{ action.content }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, inject } from 'vue'
import { STable, SLoading, isMobile, bloomreachKey } from '@gv/core'
import NoData from './NoData.vue'
import { usePaymentsDataStore } from '../stores/paymentsData'

export default defineComponent({
  name: 'PaymentRefunds',
  components: { STable, SLoading, NoData },
  setup() {
    const bloomreach = inject(bloomreachKey)
    const getText = (key: string) => bloomreach.getText(key)

    const paymentsDataStore = usePaymentsDataStore()

    onMounted(async () => {
      // In case the page refreshes or this is the first route accessed
      if (paymentsDataStore.storeNotStarted) paymentsDataStore.startStore()
    })

    return {
      getText,
      isMobile,
      bloomreach,
      paymentsDataStore,
    }
  },
})
</script>

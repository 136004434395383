<template>
  <div>
    <div class="block sm:hidden">
      <div
        class="inline-flex justify-center w-full mt-1 border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700"
      >
        <label for="tabs" class="sr-only">Select a tab</label>
        <select
          id="tabs"
          name="tabs"
          @change="selectTab"
          class="font-bold py-2 px-3 border-b-2 border-primaryColor-medium w-full"
        >
          <option
            v-for="(tab, index) in tabs"
            :key="index"
            :selected="tab.props.title == selectedIndex"
            :value="tab.props.title"
          >
            {{ tab.props.title }}
          </option>
        </select>
      </div>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200" :class="headerBackground">
        <nav class="-mb-px flex" aria-label="Tabs">
          <a
            class="w-1/4 py-4 px-1 text-center border-b-2 font-bold text-sm"
            v-for="(tab, index) in tabs"
            :href="`#${tab.props.title}`"
            @click.prevent="selectedIndex = tab.props.title"
            :key="index"
            :class="
              tab.props.title == selectedIndex
                ? 'border-secondaryColor-medium text-secondaryColor-medium '
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 '
            "
          >
            {{ tab.props.title }}
          </a>
        </nav>
      </div>
    </div>
  </div>

  <div class="py-8 px-4 sm:px-10 transition-all min-h-screen duration-200">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeMount,
  onBeforeUpdate,
  provide,
  reactive,
  toRefs,
} from 'vue'
import { SButton } from '@rb2-bv/subby-ui'
import { STabItem } from '@gv/core'

export default defineComponent({
  name: 'STabs',
  components: { SButton, STabItem },
  props: {
    headerBackground: String,
  },
  setup(_, { slots }) {
    const state = reactive(<TabState>{
      selectedIndex: '',
      tabs: [],
    })

    provide('TabsProvider', state)
    const selectTab = (payload: Event) => {
      state.selectedIndex = (<HTMLInputElement>payload.target).value
    }
    const manualSelectTab = (tabTitle: string) => {
      state.selectedIndex = tabTitle
    }
    const update = () => {
      if (slots.default) {
        state.tabs = slots
          .default()
          .filter((node: any) => node.type.name === 'STabItem')
        state.selectedIndex = state.selectedIndex || state.tabs[0].props.title
      }
    }
    onBeforeMount(() => update())
    onBeforeUpdate(() => update())

    return { ...toRefs(state), selectTab, manualSelectTab }
  },
})
interface TabState {
  selectedIndex: string
  tabs: any[]
}
</script>

<template>
  <div class="relative">
    <input
      @input="filterList"
      v-model="modelValue"
      @focus="onInputFocused"
      @blur="hideList"
      :placeholder="placeHolder"
      class="py-2 px-3 block w-full col-span-2 border border-solid border-gray-400 rounded focus:border-gray-600 focus:outline-none text-sm"
      type="text"
      autocomplete="none"
      :name="name"
      v-on:keyup="captureReturnKey"
    />
    <ul
      role="listbox"
      v-if="pickerVisible"
      class="absolute top-50 bg-white border border-gray-300 rounded-sm w-full shadow-lg z-10"
    >
      <li
        role="option"
        v-for="(pickable, i) in filteredList"
        :key="i"
        @click="(e) => onSelectItemClicked(pickable, e)"
        class="hover:bg-black hover:text-white cursor-pointer w-full px-5 py-2"
      >
        {{ pickable }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
export default defineComponent({
  title: 'SAutoSuggestion',
  emits: ['selectItem', 'inputTyped'],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedValue: {
      type: String,
      default: '',
    },
    placeHolder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  setup(props, ctx) {
    const modelValue = ref(props.selectedValue)
    const pickerVisible = ref(false)
    const filteredList = ref([...props.items])

    const filterList = () => {
      filteredList.value = props.items.filter((item: any) =>
        item.toLowerCase().includes(modelValue.value.toLowerCase())
      )
    }

    const onInputFocused = () => {
      filteredList.value = props.items.filter((item: any) =>
        item.toLowerCase().includes(modelValue.value.toLowerCase())
      )
      pickerVisible.value = true
    }

    const onSelectItemClicked = (val: any, e: any) => {
      modelValue.value = val
      ctx.emit('inputTyped', val)
    }

    const hideList = () => {
      setTimeout(() => (pickerVisible.value = false), 150)
      ctx.emit('inputTyped', modelValue.value)
    }

    const resetInput = () => {
      modelValue.value = ''
      pickerVisible.value = false
    }

    const captureReturnKey = (e) => {
      if (e.keyCode === 13) {
        ctx.emit('selectItem', modelValue.value)
      }
    }

    return {
      pickerVisible,
      hideList,
      filteredList,
      filterList,
      modelValue,
      resetInput,
      onInputFocused,
      onSelectItemClicked,
      captureReturnKey,
    }
  },
})
</script>

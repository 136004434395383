<template>
  <div class="w-full text-right my-8">
    <SDialogBox :show="openModal" @close-modal="closeModal">
      <template v-slot:header>
        <p class="text-2xl font-bold">
          {{
            paymentMethodType?.toLowerCase().includes('credit')
              ? getText('change_payment_card')
              : getText('new_payment_method')
          }}
        </p>
      </template>
      <template v-slot:body>
        <div>
          <div class="flex justify-center">
            <div class="mt-2 pt-2">
              <SCheckbox
                class="text-sm"
                :name="'recurringPayment'"
                v-model="recurringPayment"
                :disabled="true"
              />
            </div>
            <div class="mt-2 p-2 block text-sm font-medium text-gray-700">
              {{ getText('recurring_payment_method_agreement_msg') }}
            </div>
          </div>
          <div class="flex justify-center">
            <div v-if="isLoading">
              <SLoading class="inset-1/2 -ml-8 mt-8 relative h-24 w-24" />
            </div>
            <div id="dropin-container"></div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end mt-20 border-t pt-10">
          <button
            variant="bare"
            class="text-sm text-primaryColor-dark hover:text-black"
            @click="closeModal"
          >
            {{ getText('cancel') }}
          </button>
        </div>
      </template>
    </SDialogBox>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onUpdated, inject } from 'vue'
import {
  SDialogBox,
  SCheckbox,
  PAYMENT_CLIENT_KEY,
  getPaymentMethods,
  makePayment,
  SLoading,
  deletePaymentMethods,
  ENVIRONMENT,
  getBrowserInfo,
  bloomreachKey,
} from '@gv/core'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import { computed } from '@vue/reactivity'

export default defineComponent({
  name: 'AddPaymentMethodModal',
  components: {
    SDialogBox,
    SCheckbox,
    SLoading,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    hasDefaultPaymentMethod: {
      type: Boolean,
      default: false,
    },
    activePaymentMethod: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['updateModal', 'addPaymentMethodSuccessful'],
  setup(props, { emit }) {
    // const recurringPayment = ref(!props.hasDefaultPaymentMethod)
    const activePaymentMethod = ref(props.activePaymentMethod)
    const recurringPayment = ref(true)
    const isLoading = ref(false)
    const isSubmitting = ref(false)
    const openPaymentModal = ref(false)
    let adyenDropin = undefined

    const bloomreach = inject(bloomreachKey)!
    const getText = (key: string) => bloomreach.getText(key)

    const closeModal = () => {
      if (adyenDropin) adyenDropin.unmount()
      emit('updateModal', false)
    }

    const {
      fetch: fetchPaymentMethods,
      data: dataPaymentMethods,
    } = getPaymentMethods()

    const openPaymentWindow = async () => {
      isLoading.value = true
      const clientKey = PAYMENT_CLIENT_KEY
      const env = ENVIRONMENT == 'PROD' ? 'live' : 'test'
      let configuration = {
        paymentMethodsResponse: { paymentMethods: [] },
        clientKey: clientKey, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
        locale: bloomreach.getLocale(),
        environment: env,
        paymentMethodsConfiguration: {
          card: {
            // Example optional configuration for Cards
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: false,
          },
        },
        allowPaymentMethods: ['scheme'],
        onSubmit: (state, dropin) => {
          dropin.setStatus('loading')
        },
        onAdditionalDetails: (state, dropin) => {
          console.log('onAdditionalDetails')
        },
        onError: (error) => {
          console.log('error: ', error)
        },
      }

      fetchPaymentMethods()

      watch(dataPaymentMethods, (newData) => {
        if (newData !== null) {
          configuration.paymentMethodsResponse = { paymentMethods: newData }

          configuration.onSubmit = (state, dropin) => {
            dropin.setStatus('loading')
            isSubmitting.value = true
            const paymentData = {
              card: {
                type: state.data.paymentMethod.type,
                cardNumber: state.data.paymentMethod.encryptedCardNumber,
                expiryMonth: state.data.paymentMethod.encryptedExpiryMonth,
                expiryYear: state.data.paymentMethod.encryptedExpiryYear,
                name: state.data.paymentMethod.holderName,
                securityCode: state.data.paymentMethod.encryptedSecurityCode,
              },
              invoiceId: 'NONE',
              recurringPaymentMethod: recurringPayment.value,
              browserInformation: getBrowserInfo(),
              returnUrl: `${window.location.origin}/payment-callback`,
              origin: `${window.location.origin}`,
              goBackUrl: `${window.location.pathname}`,
            }

            const { fetch: fetchDelete } = deletePaymentMethods()

            const {
              fetch: fetchAddNewPaymentMethod,
              data: dataAddNewPaymentMethod,
            } = makePayment(paymentData)

            fetchAddNewPaymentMethod()
              .then(() => {
                isSubmitting.value = false
                if (dataAddNewPaymentMethod.value.resultCode === 'Authorised') {
                  dropin.setStatus('success', {
                    message: getText('payment_method_add_success_msg'),
                  })
                  setTimeout(() => {
                    dropin.setStatus('ready')
                    emit('addPaymentMethodSuccessful', true)
                    closeModal()
                  }, 2500)
                } else if (
                  dataAddNewPaymentMethod.value.resultCode ===
                    'RedirectShopper' &&
                  dataAddNewPaymentMethod.value.action
                ) {
                  const data = {
                    MD: dataAddNewPaymentMethod.value.action.data.md,
                    PaReq: dataAddNewPaymentMethod.value.action.data.paReq,
                    TermUrl: dataAddNewPaymentMethod.value.action.data.termUrl,
                  }

                  delete dataAddNewPaymentMethod.value.action.data
                  dataAddNewPaymentMethod.value.action.data = data
                  dropin.handleAction(dataAddNewPaymentMethod.value.action)
                } else {
                  throw dataAddNewPaymentMethod.value.resultCode
                }
              })
              .catch((error) => {
                isSubmitting.value = false
                dropin.setStatus('error', {
                  message: getText('payment_method_add_error_msg'),
                })
                setTimeout(() => {
                  dropin.setStatus('ready')
                  emit('addPaymentMethodSuccessful', false)
                  closeModal()
                }, 2500)
                throw Error(error)
              })
          }

          const checkout = new AdyenCheckout(configuration)

          adyenDropin = checkout.create('dropin', {
            showPayButton: true,
          })

          adyenDropin.mount('#dropin-container')
          isLoading.value = false
        }
      })
    }

    onUpdated(() => {
      if (props.openModal) {
        openPaymentWindow()
        // recurringPayment.value = !props.hasDefaultPaymentMethod
      }
    })

    return {
      recurringPayment,
      isLoading,
      isSubmitting,
      openPaymentModal,
      closeModal,
      getText,
      paymentMethodType: computed(
        () => props.activePaymentMethod?.paymentMethodType
      ),
    }
  },
})
</script>

<style>
.adyen-checkout__payment-method,
.adyen-checkout__button,
.adyen-checkout__field,
.adyen-checkout__input {
  border-radius: 0px !important;
}
</style>

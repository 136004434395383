<template>
  <div class="flex items-center">
    <input
      :id="id"
      type="radio"
      :name="name"
      :class="[{ 'cursor-not-allowed': disabled }]"
      :value="value"
      :checked="modelValue === value"
      :disabled="disabled"
      @change="handleChange"
    />
    <slot name="label">
      <label
        v-if="label"
        :for="id"
        class="cursor-pointer ml-2"
      >
        {{ label }}
      </label>
    </slot>

    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SRadio',

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },

    modelValue: {
      type: String,
      required: true,
      default: false,
    },

    name: {
      type: String,
      default: 'name',
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    value: {
      type: String,
      required: false,
      default: false,
    },
    
  },

  emits: ['update:modelValue', 'on-change'],

  setup(props, { attrs, emit }) {
    
    const guid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
        })
    }

    const id = attrs.id || guid()

    const handleChange = ({ target }: { target: HTMLInputElement }) => {
      // only for boolean now
      // To optimize
      if (!props.disabled) {
        emit('update:modelValue', target.value)
        emit('on-change', target.value)
      }
    }

    return {
      id,
      handleChange,
    }
  },
})
</script>
<template>
  <table class="min-w-full divide-y divide-gray-200 table-auto border text-sm">
    <thead v-if="skeletonLoader">
      <th v-for="i in 10" class="text-left pr-4">
        <p class="bg-gray-300 animate-pulse h-10 m-2">&nbsp;</p>
      </th>
    </thead>
    <thead v-else class="bg-gray-50">
      <th class="w-4">&nbsp;</th>
      <th
        v-for="header in headers"
        scope="col"
        class="px-6 py-4 text-xs font-bold text-black uppercase tracking-wider"
        :class="[header.currency ? 'text-right' : 'text-left']"
        :key="header.field"
      >
        {{ header.name }}
      </th>
    </thead>

    <tr v-if="skeletonLoader" v-for="i in 3">
      <th v-for="i in 10" class="text-left pr-4">
        <p class="bg-gray-300 animate-pulse m-1">&nbsp;</p>
      </th>
    </tr>
    <template v-else v-for="row in data">
      <tr
        :class="[row.rowClass ? row['rowClass'] : 'bg-white text-gray-700']"
        class="hover:bg-gray-50 cursor-pointer text-gray-700 hover:text-secondaryColor-medium"
        @click="toggleRow(row.id)"
      >
        <td class="text-center">
          <div v-if="row.subRow">
            <ChevronR
              class="transform-gpu duration-150 ease-in-out"
              :class="[
                {
                  'rotate-90': openedRows.includes(row.id),
                },
              ]"
            />
          </div>
        </td>
        <td
          v-for="header in headers"
          class="px-6 py-4 whitespace-nowrap"
          :key="header.field"
        >
          <div
            :class="[
              header.extraClass ? row['_class'] : 'text-sm',
              header.currency ? 'text-right' : '',
            ]"
          >
            {{ row[header.field] }}
          </div>
        </td>
      </tr>
      <tr
        class="bg-gray-200 shadow-inner"
        v-if="row.subRow && openedRows.includes(row.id)"
      >
        <td :colspan="headers.length + 1">
          <slot name="subRowContent" :row="row"></slot>
        </td>
      </tr>
      <slot name="extraRows" :row="row"></slot>
    </template>
  </table>
</template>

<script lang="ts">
//A table with expandable rows
import { defineComponent, watchEffect, ref, onMounted, reactive } from 'vue'
import ChevronR from '../../../assets/chevron_r.svg'
export default defineComponent({
  name: 'STableCollapse',
  props: {
    data: {
      type: Object,
    },
    headers: {
      type: Object,
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
    skeletonLoader: {
      type: Boolean,
      default: false,
    },
    expandRows: {
      type: Object,
      required: false,
    },
  },
  components: { ChevronR },
  emits: ['allRowsExpanded'],
  setup(props, { emit }) {
    const openedRows = reactive([])
    const toggleRow = (id: Number) => {
      const index = openedRows.indexOf(id)
      if (index == -1) openedRows.push(id)
      else openedRows.splice(index, 1)
      //? After toggling a row, checks if all the rows are expanded or not
      checkAllExpanded()
    }

    const toggleAll = (toggleState: Boolean) => {
      if (!toggleState) {
        openedRows.splice(0)
      } else {
        props.data.forEach((row) => openedRows.push(row.id))
      }
    }

    //? Function to check if all the rows are open to trigger the event emitter and update the state of Colapse/Expand all buttons
    const checkAllExpanded = () => {
      if (openedRows.value.length === props.data?.length)
        emit('allRowsExpanded', true)
      else emit('allRowsExpanded', false)
    }

    watchEffect(() => toggleAll(props.expandAll))

    //? When mount the component, checks if there's a rule to expand some rows by default
    onMounted(() => {
      if (
        props.data &&
        props.expandRows &&
        Object.keys(props.expandRows).length > 0
      ) {
        openedRows.splice(0)
        props.data?.forEach((row) => {
          if (row[props.expandRows.field] == props.expandRows.value) {
            openedRows.push(row.id)
          }
        })
        checkAllExpanded()
      }
    })

    return { openedRows, toggleRow }
  },
})
</script>

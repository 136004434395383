<template>
  <div>
    <h3
      v-if="
        paymentsDataStore.defaultPaymentMethod &&
        paymentsDataStore.defaultPaymentMethod.mandateData &&
        paymentsDataStore.defaultPaymentMethod.mandateData.mandateActive ==
          'Requested'
      "
      class="text-xl pb-2 mb-8 border-b"
    >
      {{ getText('mandate_approval_in_progress') }}
    </h3>
    <h3
      v-else-if="
        !paymentsDataStore.loading && paymentsDataStore.defaultPaymentMethod
      "
      class="text-xl pb-2 mb-8 border-b"
    >
      {{ getText('current_payment_method_msg') }} {{ getCurrentPaymentMethod }}
      {{ getText('current_payment_method_msg_cnt') }}
    </h3>
    <p class="my-3 font-normal text-base mb-6">
      {{ getText('manual_payment_text_active_one') }}
    </p>
    <div>
      <NewPaymentMethodCard
        :loading="paymentsDataStore.loading || isLoading"
        :data-default-payment-method="paymentsDataStore.defaultPaymentMethod"
        :profile-data="accountDataStore.data"
        @add-new-payment="addNewPaymentMethod('DirectDebit')"
        @add-new-payment-method="addNewPaymentMethod"
        @remove-default="removeDefault(paymentsDataStore.defaultPaymentMethod)"
        @set-manual-payment="isActiveCreditCard"
        @paymentTabActive="onPaymentMethodTabChanged"
        :is-mandate-requested="
          paymentsDataStore.defaultPaymentMethod &&
          paymentsDataStore.defaultPaymentMethod.mandateData &&
          paymentsDataStore.defaultPaymentMethod.mandateData.mandateActive ==
            'Requested'
        "
      />
    </div>

    <!-- <div v-if="dataAllPaymentMethods && tableData.length > 0">
      <h3 class="text-xl pb-2 my-8 border-b text-primaryColor-medium">
        {{ getText('other_payment_methods') }}
      </h3>
      <STable
        :headers="tableHeaders"
        :data="tableData"
        :actions="tableActions"
        :paginated="true"
        :limitPerPage="10"
      />
    </div> -->
    <GVPaymentTypeModal
      :open-modal="choosePaymentMethodModal"
      :active-payment-method="paymentsDataStore.defaultPaymentMethod"
      :payment-method-modal-btn="paymentMethodModalBtn"
      @update-modal="closePaymentWindow"
      @payment-type="choosePaymentType"
      @set-manual-payment="removePaymentMethod(selectedPaymentMethod)"
      @payment-changed="refreshPaymentMethod"
    />

    <AddPaymentMethodModal
      :has-default-payment-method="
        paymentsDataStore.defaultPaymentMethod &&
        paymentsDataStore.defaultPaymentMethod.defaultPaymentMethod &&
        paymentsDataStore.defaultPaymentMethod.paymentMethodType != 'None'
      "
      :active-payment-method="paymentsDataStore.defaultPaymentMethod"
      :open-modal="paymentMethodModal"
      @update-modal="changeOpenModal"
      @add-payment-method-successful="addPaymentMethodComplete"
    />

    <SDialogBox :show="openModal" @close-modal="closeModal">
      <template v-slot:header>
        <p class="text-2xl font-bold">
          {{ getText('confirm_action') }}
        </p>
      </template>
      <template v-slot:body>
        <div v-if="isLoading">
          <SLoading class="inset-1/2 -ml-8 mt-8 relative h-24 w-24" />
        </div>
        <div
          class="flex my-4"
          v-else-if="resultMessage.msg && resultMessage.msg.length > 0"
        >
          <p
            class="text-sm"
            :class="{
              'text-green-500': resultMessage.status === 'Success',
              'text-red-500': resultMessage.status === 'Error',
              'text-primaryColor-light': resultMessage.status === 'Warning',
            }"
          >
            {{ resultMessage.msg }}
          </p>
        </div>
        <div v-else-if="selectedAction === 'Remove'">
          <p>{{ getText('remove_payment_method_confirmation') }}</p>
          <p class="text-sm my-2">
            {{ getText('remove_payment_method_manual_inform_msg') }}
          </p>
          <div class="text-sm text-left mt-8">
            <div v-if="selectedPaymentMethod && selectedPaymentMethod.cardType">
              <div class="font-bold text-left text-primaryColor-medium">
                {{ getText('type') }}
              </div>
              <div class="break-words">
                {{ selectedPaymentMethod.cardType }}
              </div>
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{
                selectedPaymentMethod.cardNumber
                  ? getText('card_holder_name')
                  : getText('bank_account_number')
              }}
            </div>
            <div class="break-words">
              {{
                selectedPaymentMethod.cardHolderName ||
                selectedPaymentMethod.bankAccountNumber
              }}
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{
                selectedPaymentMethod.cardNumber
                  ? getText('card_number')
                  : getText('mandate_active')
              }}
            </div>
            <div class="break-words">
              {{
                selectedPaymentMethod.cardNumber ||
                selectedPaymentMethod.mandateActive
              }}
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{
                selectedPaymentMethod.cardNumber
                  ? getText('expiration_date')
                  : getText('mandate_start_date')
              }}
            </div>
            <div class="break-words">
              {{
                selectedPaymentMethod.cardNumber
                  ? `${selectedPaymentMethod.expirationMonth}/${selectedPaymentMethod.expirationYear}`
                  : selectedPaymentMethod.mandateStartDate
              }}
            </div>
            <div class="flex my-4">
              <GVButton
                class="text-sm mr-2"
                @click="removePaymentMethod(selectedPaymentMethod)"
                >{{ getText('confirm') }}</GVButton
              >
              <GVButton class="text-sm mr-2" @click="closeModal()">{{
                getText('cancel')
              }}</GVButton>
            </div>
          </div>
        </div>
        <div v-else-if="selectedAction === 'MakeDefault'">
          <p>{{ getText('default_payment_method_confirmation') }}</p>
          <div class="text-sm text-left mt-8">
            <div
              v-if="selectedPaymentMethod && selectedPaymentMethod?.cardType"
            >
              <div class="font-bold text-left text-primaryColor-medium">
                {{ getText('type') }}
              </div>
              <div class="break-words">
                {{ selectedPaymentMethod.cardType }}
              </div>
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('card_holder_name') }}
            </div>
            <div class="break-words">
              {{ selectedPaymentMethod.cardHolderName }}
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('card_number') }}
            </div>
            <div class="break-words">
              {{ selectedPaymentMethod.cardNumber }}
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('expiration_date') }}
            </div>
            <div class="break-words">
              {{ selectedPaymentMethod.expiration }}
            </div>
          </div>
          <div class="flex my-4">
            <GVButton
              class="text-sm mr-2"
              @click="makeDefaultPaymentMethod(selectedPaymentMethod)"
              >{{ getText('confirm') }}</GVButton
            >
            <GVButton class="text-sm mr-2" @click="closeModal()">{{
              getText('cancel')
            }}</GVButton>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end mt-20 border-t pt-10">
          <button
            variant="bare"
            class="text-sm text-gray-400 text-primaryColor-dark hover:text-black"
            @click="closeModal"
          >
            {{ getText('cancel') }}
          </button>
        </div>
      </template>
    </SDialogBox>

    <SConfirmDialog
      :show="showPaymentChangeConfirmationModal"
      @close-modal="showPaymentChangeConfirmationModal = false"
      @confirm="removePaymentMethod"
    >
      <template v-slot:body>
        <p class="text-xl font-semibold text-center">
          {{ getText('payment_change_confirmation') }}
          {{ getText('click_to_proceed') }}
        </p>
      </template>
    </SConfirmDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref, inject, Ref } from 'vue'
import {
  GVCard,
  GVButton,
  STable,
  getAllPaymentMethodPP,
  updateDefaultPaymentMethodPP,
  deletePaymentMethods,
  SDialogBox,
  SLoading,
  GVPaymentTypeModal,
  bloomreachKey,
  SConfirmDialog,
} from '@gv/core'

import NewPaymentMethodCard from '../components/NewPaymentMethodCard.vue'
import AddPaymentMethodModal from '../components/AddPaymentMethodModal.vue'

import { useAccountDataStore } from '../stores/accountData'
import { usePaymentsDataStore } from '../stores/paymentsData'

export default defineComponent({
  name: 'PaymentMethod',
  components: {
    GVCard,
    GVButton,
    STable,
    AddPaymentMethodModal,
    SDialogBox,
    SLoading,
    NewPaymentMethodCard,
    GVPaymentTypeModal,
    SConfirmDialog,
  },
  setup() {
    const paymentMethodModal = ref(false)
    const paymentMethodModalBtn = ref('')
    const tableHeaders = ref([])
    const tableData = ref([])
    const resultMessage = ref({ msg: '', status: '' })
    const selectedAction = ref('')
    const selectedPaymentMethod = ref({})
    const openModal = ref(false)
    const isLoading = ref(false)
    const choosePaymentMethodModal = ref(false)
    const paymentOption = ref('')
    const paymentTabIsActive = ref(false)

    const bloomreach = inject(bloomreachKey)
    const getText = (key: string) => bloomreach.getText(key)

    const paymentMethods = computed(() => {
      return dataAllPaymentMethods.value.filter(
        (paymentMethod) => !paymentMethod.defaultPaymentMethod
      )
    })

    const accountDataStore = useAccountDataStore()
    const paymentsDataStore = usePaymentsDataStore()

    // const {
    //   fetch: fetchAllPaymentMethods,
    //   data: dataAllPaymentMethods,
    //   loading: loadingAllPaymentMethods,
    // } = getAllPaymentMethodPP()

    const closePaymentWindow = () => {
      choosePaymentMethodModal.value = false
    }
    const choosePaymentType = (option: string) => {
      if (option === 'CreditCard') {
        choosePaymentMethodModal.value = false
        addNewPaymentMethod('CreditCard')
      }
    }

    const closeModal = () => {
      resultMessage.value = { msg: '', status: '' }
      selectedPaymentMethod.value = {}
      selectedAction.value = ''
      openModal.value = false
      // fetchAllPaymentMethods()
    }

    const removeDefault = (defaultPayment) => {
      if (defaultPayment && defaultPayment.paymentMethodType == 'Credit card') {
        actionPaymentMethod(defaultPayment.creditCardData, 'Remove')
      } else {
        actionPaymentMethod(defaultPayment.mandateData, 'Remove')
      }
    }
    const actionPaymentMethod = (row, action) => {
      selectedAction.value = action
      selectedPaymentMethod.value = row
      openModal.value = true
    }

    const makeDefaultPaymentMethod = async (row) => {
      const payload = {
        paymentMethodId: row.id,
      }
      const { fetch, data } = updateDefaultPaymentMethodPP(payload)

      try {
        isLoading.value = true
        await fetch()
        if (data) {
          resultMessage.value.msg = getText(
            'update_default_payment_method_success_msg'
          )
          resultMessage.value.status = 'Success'
        }
      } catch (error) {
        resultMessage.value.msg = getText(
          'update_default_payment_method_error_msg'
        )
        resultMessage.value.status = 'Error'
      } finally {
        isLoading.value = false
        setTimeout(() => {
          closeModal()
        }, 2000)
      }
    }

    const isActiveCreditCard = () => {
      if (
        paymentsDataStore.defaultPaymentMethod?.paymentMethodType ===
        'Credit card'
      ) {
        showPaymentChangeConfirmationModal.value = true
      } else {
        removePaymentMethod()
      }
    }

    const removePaymentMethod = async (row: string | null = null) => {
      const { fetch, data } = deletePaymentMethods()
      showPaymentChangeConfirmationModal.value = false

      try {
        isLoading.value = true
        await fetch()
        if (data) {
          resultMessage.value.msg = getText('remove_payment_method_success_msg')
          resultMessage.value.status = 'Success'
        }
      } catch (error) {
        resultMessage.value.msg = getText('remove_payment_method_error_msg')
        resultMessage.value.status = 'Error'
      } finally {
        isLoading.value = false
        refreshPaymentMethod()
        closeModal()
      }
    }

    const addNewPaymentMethod = (paymentMethod?: string) => {
      if (paymentMethod) {
        if (paymentMethod == 'CreditCard') paymentMethodModal.value = true
        else {
          paymentMethodModalBtn.value = paymentMethod
          choosePaymentMethodModal.value = true
        }
      } else {
        paymentMethodModalBtn.value = ''
        choosePaymentMethodModal.value = true
      }
    }

    const changeOpenModal = async (value: boolean) => {
      paymentMethodModal.value = value
    }

    const onPaymentMethodTabChanged = (value: boolean) => {
      paymentTabIsActive.value = value
    }

    const addPaymentMethodComplete = async (value: boolean) => {
      if (value) {
        paymentsDataStore.fetchDefaultPaymentMethod()
      }
    }

    const tableActions = [
      {
        isIcon: false,
        content: 'Make Default',
        onClick: (row) => {
          actionPaymentMethod(row, 'MakeDefault')
        },
      },
      {
        isIcon: false,
        content: 'Remove',
        onClick: (row) => {
          actionPaymentMethod(row, 'Remove')
        },
      },
    ]

    const getCurrentPaymentMethod = computed(() => {
      const {
        paymentMethodType,
        defaultPaymentMethod,
        creditCardData,
      } = paymentsDataStore.defaultPaymentMethod

      if (!defaultPaymentMethod) return getText('manual_payment_tab_title')

      if (['Autogiro', 'Betalingsservice'].includes(paymentMethodType)) {
        return getText('direct_debit_tab_title')
      }

      if (
        paymentMethodType === 'Credit card' &&
        creditCardData &&
        creditCardData.id
      ) {
        return getText('credit_card')
      }
    })

    const refreshPaymentMethod = () => {
      paymentsDataStore.fetchDefaultPaymentMethod()
    }

    onMounted(async () => {
      // fetchAllPaymentMethods()
      // In case the page refreshes or this is the first route accessed
      if (paymentsDataStore.storeNotStarted) paymentsDataStore.startStore()
      if (accountDataStore.storeNotStarted) accountDataStore.startStore()
    })

    const showPaymentChangeConfirmationModal: Ref<boolean> = ref(false)

    // watch(dataAllPaymentMethods, (newData) => {
    //   if (newData) {
    //     const {
    //       data: formatRowData,
    //       headers: formatHeaderData,
    //     } = formatAllPaymentMethodsData(paymentMethods.value)
    //     tableHeaders.value = formatHeaderData
    //     tableData.value = formatRowData
    //   }
    // })

    return {
      // dataAllPaymentMethods,
      // loadingAllPaymentMethods,
      paymentMethods,
      tableData,
      tableHeaders,
      tableActions,
      addNewPaymentMethod,
      changeOpenModal,
      addPaymentMethodComplete,
      paymentMethodModal,
      removePaymentMethod,
      makeDefaultPaymentMethod,
      resultMessage,
      selectedPaymentMethod,
      selectedAction,
      closeModal,
      openModal,
      isLoading,
      getText,
      removeDefault,
      getCurrentPaymentMethod,
      closePaymentWindow,
      choosePaymentType,
      choosePaymentMethodModal,
      paymentOption,
      paymentMethodModalBtn,
      accountDataStore,
      paymentsDataStore,
      onPaymentMethodTabChanged,
      paymentTabIsActive,
      refreshPaymentMethod,
      showPaymentChangeConfirmationModal,
      isActiveCreditCard,
    }
  },
})
</script>

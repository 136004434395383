// TODO: Refactor this component to improve persistency on localStorage and improve usage when changing languages
import axios from 'axios'
import { computed, reactive } from 'vue'
import { bloomreachKey } from '@gv/core'
import {
  BLOOMREACH_BASE_URL,
  BLOOMREACH_PORTAL,
  BLOOMREACH_OPCOS,
  BLOOMREACH_LOCALE,
  LOCALES,
  BANNERS,
} from '../env'

const state = reactive({
  extraTranslations: {} as Record<string, Record<string>>,
  loading: true as boolean,
  data: {} as Record<string, unknown>,
  clearCache: false as boolean,
  error: null,
  locale: localStorage.getItem('locale') || (LOCALES.split(', ')[0] as string),
  banner:
    localStorage.getItem('banner') ||
    (BANNERS.split(', ')[0] as string | undefined),
  currency:
    localStorage.getItem('currency') || (undefined as string | undefined),
})

export const BloomreachPlugin = {
  loading: computed(() => state.loading),
  data: computed(() => state.data),
  locale: computed(() => state.locale),
  banner: computed(() => state.banner),
  currency: computed(() => state.currency),
  getEnvLocale,
  addExtraTranslation,
  getText,
  setLocale,
  clearCache,
  getLocale,
  setBanner,
  getBanner,
  setCurrency,
  getCurrency,
}

function clearCache() {
  //Use this to clear cache from axios requests to bloomreach
  state.clearCache = true
}

/**
 * Find a value/translation based on a key.
 * @param key Key to use for translating
 * @param fallbackValue Fallback value if no value is found
 * @returns Value of key from bloomreach or key if none found
 */
function getText(key: string, fallbackValue?: string) {
  if (!state.data) throw 'Bloomreach Plugin not initialized!'
  /*
  return key.split('.').reduce((o: any, i: any) => {
        if (o) return o[i]
      })
*/
  const text = state.data[key] as string
  if (text) return text
  else if (text?.length == 0) return ''

  state.data.has
  return fallbackValue || key
}

function setLocale(_locale: string) {
  state.locale = _locale
  switch (_locale) {
    case 'sv-SE':
      setBanner('Synoptik AB')
      setCurrency('SEK')
      break
    case 'da-DK':
      setBanner('Synoptik A/S')
      setCurrency('DKK')
      break
    case 'nl-NL':
      setBanner('Pearle')
      setCurrency('EUR')
      break
    default:
      break
  }
  getDocument()
}

function getLocale() {
  return state.locale
}

function setBanner(_banner: string) {
  state.banner = _banner
}

function getBanner() {
  return state.banner
}

function setCurrency(_currency: string) {
  state.currency = _currency
}

function getCurrency() {
  return state.currency
}

function getEnvLocale() {
  return BLOOMREACH_LOCALE;
}

function addExtraTranslation(locale: string, key: string, value: string) {
  state.extraTranslations ??= {}
  state.extraTranslations[locale] ??= {}
  state.extraTranslations[locale][key] = value
  if (BLOOMREACH_LOCALE !== locale) return
  state.data[key] = value
}

const getDocument = async () => {
  state.loading = true

  let URL = `${BLOOMREACH_BASE_URL}/api/resources/${BLOOMREACH_LOCALE}/${BLOOMREACH_OPCOS}`
  let resourceBundleId = `grandvision.public.${BLOOMREACH_OPCOS}.labels.${BLOOMREACH_PORTAL}`

  try {
    // TODO: Execute axios request here with state.clearCache, using timestamp for now
    const response = await axios.get(
      `${URL}?resourceBundleIds=${resourceBundleId}&timestamp=${Date.now()}`
    )
    if (response.data) {
      const { properties } = response.data.resourceBundles.bundles[
        resourceBundleId
      ]
      for (const [key, value] of Object.entries(properties)) {
        state.data[key] = value
      }
    }
    const extraTranslationsForCurrentLocale = state.extraTranslations?.[BLOOMREACH_LOCALE] ?? {};

    for(const [key, value] of Object.entries(extraTranslationsForCurrentLocale)) {
      state.data[key] = value;
    }
  } catch (error) {
    console.error(error)
  } finally {
    state.loading = false
  }
}

export const BloomreachAdapter = async () => {
  //Initialize adapter
  getDocument()

  return {
    install: (app: any) => {
      app.provide(bloomreachKey, BloomreachPlugin)
    },
  }
}

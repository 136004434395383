import { useAPI } from '../index'
// TODO: Fix the Imports to be import * from customer-portal.ts after refactoring
import {
  EP_PP_ACCOUNT_OVERVIEW,
  EP_PP_INVOICES,
  EP_PP_INVOICES_ITEMS,
  EP_PP_INVOICES_DOWNLOAD,
  EP_PP_ACCOUNT,
  EP_PP_ACCOUNT_CONTACTS,
  EP_PP_PAYMENTS,
  EP_PP_PAYMENTS_DEFAULT,
  EP_PP_SUBSCRIPTIONS,
  EP_PP_DEFAULT_PAYMENT_METHOD,
  EP_PP_PAYMENT_METHODS,
  EP_PP_ALL_PAYMENT_METHODS,
  EP_PP_PAYMENTS_NETS,
  EP_PP_ACCOUNT_OUTSTANDINGBALANCE,
  EP_PP_RELATED_ACCOUNTS,
  EP_PP_ACCOUNT_NOTDUEBALANCE,
  EP_PP_CREDITMEMO_DOWNLOAD,
  EP_PP_CHILD_SUBSCRIPTIONS,
  EP_PP_DOCUMENTYPE_DOWNLOAD
} from '../endpoints/uri'

// TODO: Organize by request type (POST/PUT/GET) and order alphabetically.
// Endpoints for the Payments Portal (PP) aka /SelfHelp/

export function getAccountOverview() {
  return useAPI({
    method: 'get',
    url: EP_PP_ACCOUNT_OVERVIEW,
  })
}

export function getRelatedAccountsPP() {
  return useAPI({
    method: 'get',
    url: EP_PP_RELATED_ACCOUNTS,
  })
}

export function getCustomerInvoicesPP() {
  return useAPI({
    method: 'get',
    url: EP_PP_INVOICES,
  })
}

export function getCustomerInvoiceItems() {
  return useAPI({
    method: 'get',
    url: EP_PP_INVOICES_ITEMS,
  })
}

export function downloadInvoicePP(id: string, filename?: string) {
  return useAPI({
    method: 'get',
    responseType: 'blob',
    url: EP_PP_INVOICES_DOWNLOAD(id),
    params: {
      filename,
    },
  })
}

export function getAccountData() {
  return useAPI({
    method: 'get',
    url: EP_PP_ACCOUNT_OVERVIEW,
  })
}

export function updateAccountData(accountData: AccountData) {
  return useAPI({
    method: 'put',
    url: EP_PP_ACCOUNT,
    data: accountData,
  })
}

export function getPayments() {
  return useAPI({
    method: 'get',
    url: EP_PP_PAYMENTS,
  })
}
export function getSubscriptionsPP() {
  return useAPI({
    method: 'get',
    url: EP_PP_SUBSCRIPTIONS,
  })
}

export function getDefaultPaymentMethodPP() {
  return useAPI({
    method: 'get',
    url: EP_PP_DEFAULT_PAYMENT_METHOD,
  })
}

export function getPaymentMethods() {
  return useAPI({
    method: 'get',
    url: EP_PP_PAYMENT_METHODS,
  })
}

export function makePayment(paymentData: Object) {
  return useAPI({
    method: 'post',
    url: EP_PP_PAYMENTS,
    data: paymentData,
  })
}

export function makePaymentWithDefault(paymentData: Object) {
  return useAPI({
    method: 'post',
    url: EP_PP_PAYMENTS_DEFAULT,
    data: paymentData,
  })
}
export function getAllPaymentMethodPP() {
  return useAPI({
    method: 'get',
    url: EP_PP_ALL_PAYMENT_METHODS,
  })
}

export function updatePaymentType(paymentType: Object) {
  return useAPI({
    method: 'put',
    url: EP_PP_PAYMENT_METHODS,
    data: paymentType,
  })
}

export function deletePaymentMethods() {
  return useAPI({
    method: 'delete',
    url: EP_PP_PAYMENT_METHODS,
  })
}

export function updateDefaultPaymentMethodPP(payload: Object) {
  return useAPI({
    method: 'put',
    url: EP_PP_DEFAULT_PAYMENT_METHOD,
    data: payload,
  })
}

export function makeNetsPayment(paymentPayload: Object) {
  return useAPI({
    method: 'post',
    url: EP_PP_PAYMENTS_NETS,
    data: paymentPayload,
  })
}

export function getOutstandingBalance() {
  return useAPI({
    method: 'get',
    url: EP_PP_ACCOUNT_OUTSTANDINGBALANCE,
  })
}

export function getNotDueBalance() {
  return useAPI({
    method: 'get',
    url: EP_PP_ACCOUNT_NOTDUEBALANCE,
  })
}

export function downloadCreditMemoPP(id: string, filename?: string) {
  return useAPI({
    method: 'get',
    responseType: 'blob',
    url: EP_PP_CREDITMEMO_DOWNLOAD(id),
    params: {
      filename,
    },
  })
}

export function downloadDocumentTypePP(PdfId: string, filename: string) {
  return useAPI({
    method: 'get',
    responseType: 'blob',
    url: EP_PP_DOCUMENTYPE_DOWNLOAD(PdfId, filename),
    params: {
      filename,
    },
  })
}
<template>
  <GVCard :skeletonLoader="loading">
    <template v-slot:title>
      <h1 class="text-lg">{{ getText('default_payment_method') }}</h1>
      <div
        v-if="hasClosingArrow"
        class="absolute top-6 right-6 transform -rotate-90"
        @click="closePaymentDefaultCard"
      >
        <RightArrowIcon />
      </div>
    </template>
    <template v-slot:body class="pb-0">
      <div class="-mb-6 mt-4">
        <!-- Div to show if there is a Default Payment Method -->
        <div
          v-if="
            dataDefaultPaymentMethod &&
            dataDefaultPaymentMethod.defaultPaymentMethod &&
            dataDefaultPaymentMethod.paymentMethodType != 'None'
          "
        >
          <p v-if="isMandateRequested" class="text-sm text-left">
            {{ getText('mandate_approval_in_progress') }}
          </p>
          <!-- Div to show Credit Card data -->
          <div
            v-if="
              !(
                paymentMethodType === 'Autogiro' ||
                paymentMethodType === 'Betalingsservice'
              ) &&
              dataDefaultPaymentMethod.creditCardData &&
              dataDefaultPaymentMethod.creditCardData.id
            "
            class="text-sm text-left mt-8"
          >
            <div v-if="dataDefaultPaymentMethod.creditCardData.cardType">
              <div class="font-bold text-left text-primaryColor-medium">
                {{ getText('type') }}
              </div>
              <div class="break-words">
                {{ dataDefaultPaymentMethod.creditCardData.cardType }}
              </div>
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('card_holder_name') }}
            </div>
            <div class="break-words">
              {{ dataDefaultPaymentMethod.creditCardData.cardHolderName }}
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('card_number') }}
            </div>
            <div class="break-words">
              {{ dataDefaultPaymentMethod.creditCardData.cardNumber }}
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('expiration_date') }}
            </div>
            <div class="break-words">
              {{ dataDefaultPaymentMethod.creditCardData.expirationMonth }}/{{
                dataDefaultPaymentMethod.creditCardData.expirationYear
              }}
            </div>
          </div>
          <!-- Div to show if its Autogiro or Betalingsservice -->
          <div
            v-else-if="
              (paymentMethodType === 'Autogiro' ||
                paymentMethodType === 'Betalingsservice') &&
              accountData?.mandateActive === 'Yes'
            "
            class="text-sm text-left mt-8"
          >
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('bank_account_number') }}
            </div>
            <div class="break-words">
              {{ dataDefaultPaymentMethod.mandateData.bankAccountNumber }}
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('mandate_active') }}
            </div>
            <div class="break-words">
              {{ dataDefaultPaymentMethod.mandateData.mandateActive }}
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('mandate_start_date') }}
            </div>
            <div class="break-words">
              {{
                formatDate(
                  dataDefaultPaymentMethod.mandateData.mandateStartDate,
                  bloomreach
                )
              }}
            </div>
            <div
              v-if="
                dataDefaultPaymentMethod.paymentMethodType === 'Autogiro' &&
                showDirectDebitMsg
              "
              class="break-words"
            >
              {{ getText('payment_collected_with_autogiro_msg') }}
            </div>
            <div
              v-if="
                dataDefaultPaymentMethod.paymentMethodType ===
                  'Betalingsservice' && showDirectDebitMsg
              "
              class="break-words"
            >
              {{ getText('payment_collected_with_betalingsservice_msg') }}
            </div>
          </div>
          <div v-if="hasButtons" class="flex my-4">
            <GVButton
              v-if="
                paymentMethodType !== 'Autogiro' &&
                paymentMethodType !== 'Betalingsservice'
              "
              class="text-sm mr-2"
              @click="openPaymentWindow('payWithDefault')"
            >
              {{
                payButtonsText ? getText('pay_with_default') : getText('pay')
              }}
            </GVButton>
            <GVButton
              class="text-sm mr-2"
              @click="openPaymentWindow('newPaymentMethod')"
            >
              {{
                payButtonsText
                  ? getText('pay_with_credit_card')
                  : getText('add_new_payment_method')
              }}
            </GVButton>
          </div>
        </div>
        <!-- Div to show if there isn't a Default Payment Method -->
        <div v-else>
          <p class="text-sm text-left">
            {{
              isMandateRequested
                ? getText('mandate_approval_in_progress')
                : getText('no_default_payment')
            }}
          </p>
          <GVButton
            v-if="hasButtons"
            class="flex text-sm my-4"
            @click="openPaymentWindow('newPaymentMethod')"
            >{{ getText('add_new_payment_method') }}</GVButton
          >
        </div>
      </div>
    </template>
  </GVCard>
</template>
<script lang="ts">
import { defineComponent, inject, ref } from 'vue'
import { useRoute } from 'vue-router'

import { GVButton, GVCard, bloomreachKey } from '@gv/core'

import RightArrowIcon from '../assets/right_arrow.svg'
import { formatDate } from '@gv/core'

export default defineComponent({
  name: 'DefaultPaymentMethodCard',
  components: {
    GVButton,
    GVCard,
    RightArrowIcon,
  },
  props: {
    payButtonsText: {
      type: Boolean,
      default: false,
    },
    hasClosingArrow: {
      type: Boolean,
      default: false,
    },
    hasButtons: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataDefaultPaymentMethod: {
      type: Object,
      default: {},
    },
    isMandateRequested: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openPaymentWindow', 'updateDefaultPaymentMethodCard'],
  setup(props, { emit }) {
    const bloomreach = inject(bloomreachKey)
    const getText = (key: String) => bloomreach.getText(key)
    const route = useRoute()
    const showDirectDebitMsg = ref(route.name != 'PaymentMethod')
    const accountData = ref(
      JSON.parse(localStorage.getItem('userCache') || '{}')
    )
    const paymentMethodType = props.dataDefaultPaymentMethod?.paymentMethodType

    const openPaymentWindow = (option: string) => {
      emit('openPaymentWindow', {
        option,
        paymentMethodType: props.dataDefaultPaymentMethod?.paymentMethodType,
      })
    }

    const closePaymentDefaultCard = () => {
      emit('updateDefaultPaymentMethodCard', false)
    }

    return {
      getText,
      openPaymentWindow,
      closePaymentDefaultCard,
      accountData,
      paymentMethodType,
      showDirectDebitMsg,
      formatDate,
      bloomreach,
    }
  },
})
</script>

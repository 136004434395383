<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol class="flex items-center space-x-4">
      <li>
        <div>
          <a href="/" class="text-gray-400 hover:text-secondaryColor-medium">
            <svg
              class="flex-shrink-0 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>

      <li v-for="(breadcrumb, idx) in breadcrumbList" :key="idx">
        <div class="flex items-center">
          <svg
            class="flex-shrink-0 h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          <a
            href="#"
            @click="routeTo(idx)"
            class="ml-4 text-sm font-medium text-gray-500 hover:text-secondaryColor-medium"
            >{{ breadcrumb?.rawName || bloomreach.getText(breadcrumb.name) }}</a
          >
        </div>
      </li>
    </ol>
  </nav>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { bloomreachKey } from '@gv/core'

export default defineComponent({
  name: 'SBreadcrumbs',
  data() {
    let breadcrumbList: Breadcrumb[] = []
    return {
      breadcrumbList,
    }
  },
  mounted() {
    this.updateList()
  },
  watch: {
    $route() {
      this.updateList()
    },
  },
  methods: {
    routeTo(pRouteTo: number) {
      if (this.breadcrumbList[pRouteTo].link)
        this.$router.push({ name: this.breadcrumbList[pRouteTo].link })
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumbs
    },
  },
  setup() {
    const bloomreach = inject(bloomreachKey)!
    return { bloomreach }
  },
})

interface Breadcrumb {
  name: string
  rawName?: string
  link: string
}
</script>

<template>
  <div class="flex items-center">
    <slot name="left"></slot>
    <label class="relative inline-block h-4 leading-4">
      <input type="checkbox" class="w-4 h-4" 
      @change="handleChange($event)" 
      :id="id" 
      :disabled="disabled"
      :checked="checked" />
    </label>
    <slot name="label">
      <label
        v-if="label"
        :for="id"
        class="cursor-pointer ml- text-right text-sm font-medium text-gray-700 px-2"
      >
        {{ label }}
      </label>
    </slot>
    <slot name="right"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'GVCheckbox',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  emits: ['on-change', 'update:modelValue'],

  setup(props, { attrs, emit }) {

    const isChecked = ref(false);

    const guid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
        })
    }

    const id = attrs.id || guid()
    const handleChange = ({ target }: { target: HTMLInputElement }) => {
      // // To optimize
      if (!props.disabled) {
        emit('on-change', target.checked)
        emit('update:modelValue', target.checked)
      }
    }

    return {
      id,
      handleChange,
    }
  },
})
</script>

<style>

</style>

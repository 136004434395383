import { inject } from 'vue'
import { defineStore } from 'pinia'
import {
  getCustomerInvoicesPP,
  formatInvoicesData,
  LOCALES,
  bloomreachKey,
} from '@gv/core'

export const useInvoicesDataStore = defineStore('invoicesData', {
  state: () => {
    return {
      data: [],
      invoicesTable: {},
      loading: true,
      error: {},
      refresh: false,
      storeNotStarted: true,
    }
  },
  actions: {
    async fetchInvoices(bloomreach: any, currency: string) {
      const { fetch, data, error } = getCustomerInvoicesPP()
      try {
        this.loading = true
        await fetch()
        if (data && data.value) {
          this.data = data.value
          this.formatInvoicesData(bloomreach, currency)
        }
      } catch (er) {
        this.error = error
        console.error(er)
      } finally {
        this.loading = false
        this.storeNotStarted = false
      }
    },
    formatInvoicesData(bloomreach: any, currency: string) {
      const {
        headers: invoicesHeader,
        data: originalInvoicesData,
      } = formatInvoicesData(this.data, bloomreach, currency);

      const invoicesData = originalInvoicesData.map((invoice) => {
        if (invoice.type.toLowerCase() === 'reminderletter') {
          return {
            ...invoice,
            type: bloomreach.getText('dunningletter'),
            paymentAmount: null,
            period: null,
          };
        }
        return invoice;
      });

      this.invoicesTable = {
        headers: invoicesHeader,
        data: invoicesData,
      };
    },
    applyFilters(filteredData: Array<any>, bloomreach: any, currency: string) {
      const {
        headers: invoicesHeader,
        data: invoicesData,
      } = formatInvoicesData(filteredData, bloomreach, currency)
      this.invoicesTable = {
        headers: invoicesHeader,
        data: invoicesData,
      }
    },
    // Generic function to start and populate the store.
    async startStore() {
      const bloomreach = inject(bloomreachKey)
      const currency = LOCALES.split(', ')[0] == 'da-DK' ? 'DKK' : 'SEK'
      try {
        this.loading = true
        await this.fetchInvoices(bloomreach, currency)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
})

<template>
  <div>
    <h2 class="text-xl pb-2 mb-4 border-b text-primaryColor-medium">
      {{ getText('help_options') }}
    </h2>

    <GVCard class="relative">
      <template v-slot:header>
        <div class="mt-10 text-lg space-y-2 font-normal">
          <p>
            {{ getText('benefits_text') }}
            <a
              v-if="
                getText('extended_vision_tests_link').length > 0 &&
                getText('extended_vision_tests_link') !==
                  'extended_vision_tests_link'
              "
              class="text-primaryColor-medium"
              target="_blank"
              :href="getText('extended_vision_tests_link')"
              >{{ getText('extended_vision_test_text') }}</a
            >
            <span
              v-if="
                getText('benefits_text_cnt').length > 0 &&
                getText('benefits_text_cnt') !== 'benefits_text_cnt'
              "
            >
              {{ getText('benefits_text_cnt') }}
            </span>
          </p>

          <p>
            {{ getText('get_help_easily') }}
          </p>

          <p class="pt-6">
            <span class="font-bold">
              {{ getText('book_eye_test_appointment') }}
            </span>
            - {{ getText('part_of_spectacle_subscription') }}
          </p>

          <GVButton
            class="h-12 w-min whitespace-nowrap px-8 flex items-center justify-center"
            data-testid="book_time"
            :href="getText('book_time_link')"
            target="_blank"
            >{{ getText('book_time') }}
          </GVButton>

          <p class="pt-8 font-bold">
            {{ getText('questions_about_subscription') }}
          </p>

          <p>
            {{ getText('about_subscription_text') }}
          </p>

          <!-- <p>Your store is xxx</p> -->

          <p>
            {{ getText('other_synoptik_stores_text') }}
          </p>
          <GVButton
            class="h-12 w-40 flex items-center justify-center"
            data-testid="book_time"
            :href="getText('synoptik_store_link')"
            target="_blank"
          >
            {{ getText('find_store') }}
          </GVButton>

          <p class="pt-10 font-bold">{{ getText('other_questions') }}</p>

          <div>
            <span class="mr-1">{{ getText('other_questions_summary') }} </span>
            <a
              class="text-primaryColor-medium mr-1"
              target="_blank"
              :href="`mailto:${getText('synoptik_email')}`"
              >{{ getText('synoptik_email') }}
            </a>
            <span class="mr-1">{{ getText('or_on') }}</span>
            <a
              class="text-primaryColor-medium mr-1"
              target="_blank"
              :href="`tel:${getText('synoptik_phone')}`"
              >{{ getText('synoptik_phone') }}.
            </a>
            <span>{{ getText('other_questions_summary_cont') }}</span>
          </div>
        </div>
      </template>
    </GVCard>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { GVCard, GVButton, bloomreachKey } from '@gv/core'

export default defineComponent({
  name: 'ContactsPage',
  components: { GVCard, GVButton },

  setup() {
    const bloomreach = inject(bloomreachKey)
    const imageLink = bloomreach.getText('contact_image_link')
    const getText = (key: string) => bloomreach.getText(key)

    return { imageLink, getText }
  },
})
</script>

<style></style>

<template>
  <div class="flex items-center">
    <slot name="left"></slot>
    <label class="switch relative inline-block">
      <input type="checkbox" class="opacity-0 w-0 h-0" @change="handleChange" />
      <span class="slider round"></span>
    </label>
    <slot name="right"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  emits: ['update:modelValue', 'on-change'],

  setup(props, { emit }) {
    const handleChange = ({ target }: { target: HTMLInputElement }) => {
      // To optimize
      if (!props.disabled) {
        emit('update:modelValue', target.checked)
        emit('on-change', target.checked)
      }
    }

    return {
      handleChange,
    }
  },
})
</script>

<style>
.switch {
  width: 60px;
  height: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #fe5000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fe5000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

<template>
  <div class="w-80 m-auto mt-40">
    <EmptySVG />
    <div class="mt-20 italic text-2xl text-center w-full">
      {{ getText('no_data') }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import EmptySVG from '../assets/undraw_empty.svg'
import { bloomreachKey } from '@gv/core'

export default defineComponent({
  name: 'NoData',
  components: { EmptySVG },
  setup() {
    const bloomreach = inject(bloomreachKey),
      getText = (key: string) => bloomreach.getText(key)

    return { getText }
  },
})
</script>

import { defineStore } from 'pinia'
import {
  getAccountOverview,
  getOutstandingBalance,
  getNotDueBalance,
  getRelatedAccountsPP,
  updateAccountData,
} from '@gv/core'
import * as Sentry from '@sentry/vue'

export const useAccountDataStore = defineStore('accountData', {
  state: () => {
    return {
      data: {},
      pristineData: {},
      relatedAccounts: {
        children: [],
        parent: null,
      },
      accountLoading: true,
      outstandingBalanceLoading: true,
      notDueBalanceLoading: true,
      relatedAccountsLoading: true,
      refresh: false,
      storeNotStarted: true,
    }
  },
  actions: {
    async fetchAccountOverview() {
      const { fetch, data } = getAccountOverview()

      try {
        this.accountLoading = true
        await fetch()
        if (data && data.value) {
          this.data = data.value
          const userData = {
              firstName: data.value.personalInfo.firstName,
              accountNumber: data.value.customerNumber,
              mandateActive: data.value.mandateActive,
          }
          Sentry.setUser({
            id: userData.accountNumber,
            username: userData.firstName + ' ' + data.value.personalInfo.lastName,
            email: data.value.contactInfo.email
          })

          const userCache = JSON.stringify(userData);

          // TODO: encrypt userCache
          localStorage.setItem('userCache', userCache)
          this.pristineData = JSON.parse(JSON.stringify(data.value))
        }
      } catch (error) {
        return error.response
      } finally {
        this.accountLoading = false
        this.storeNotStarted = false
      }
    },
    async fetchOutstandingBalance() {
      const { fetch, data } = getOutstandingBalance()
      try {
        this.outstandingBalanceLoading = true
        this.notDueBalanceLoading = true
        await fetch()
        if (data && data.value) {
          this.data.outstandingBalance = data.value.outstandingBalance
          this.data.notDueBalance = data.value.notDueBalance
        }
        this.pristineData = JSON.parse(JSON.stringify(this.data))
      } catch (error) {
        console.error(error)
      } finally {
        this.outstandingBalanceLoading = false
        this.notDueBalanceLoading = false
      }
    },
    // async fetchNotDueBalance() {
    //   const { fetch, data } = getNotDueBalance()
    //   try {
    //     this.notDueBalanceLoading = true
    //     await fetch()
    //     if (data && typeof data.value == 'number') {
    //       this.data.notDueBalance = data.value
    //     }
    //     this.pristineData = JSON.parse(JSON.stringify(this.data))
    //   } catch (error) {
    //     console.error(error)
    //   } finally {
    //     this.notDueBalanceLoading = false
    //   }
    // },
    async fetchAllData() {
      try {
        this.accountLoading = true
        this.outstandingBalanceLoading = true
        this.notDueBalanceLoading = true
        await this.fetchAccountOverview()
        await this.fetchOutstandingBalance()
        // await this.fetchNotDueBalance()
      } catch (error) {
        console.error(error)
      } finally {
        this.accountLoading = false
        this.outstandingBalanceLoading = false
        this.notDueBalanceLoading = false
      }
    },
    async fetchRelatedAccounts() {
      const { fetch, data } = getRelatedAccountsPP()
      try {
        this.relatedAccountsLoading = true
        await fetch()
        if (data && data.value) {
          this.relatedAccounts.children = data.value.children
          this.relatedAccounts.parent = data.value.parent
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.relatedAccountsLoading = false
      }
    },
    async updateAccountData(emailPrint: string) {
      switch (emailPrint) {
        case 'emailPrint':
          this.data.invoiceChannelEmail = true
          this.data.invoiceChannelPrint = true
          break
        case 'print':
          this.data.invoiceChannelEmail = false
          this.data.invoiceChannelPrint = true
          break
        case 'none':
          this.data.invoiceChannelEmail = false
          this.data.invoiceChannelPrint = false
          break
        default:
          this.data.invoiceChannelEmail = true
          this.data.invoiceChannelPrint = false
          break
      }
      const dataToUpdate = {
        additionalEmailAddresses: this.data.additionalEmailAddresses,
        storeNumber: this.data.storeNumber,
        customerStatus: this.data.customerStatus,
        personalInfo: this.data.personalInfo,
        contactInfo: this.data.contactInfo,
        addresses: this.data.addresses,
        creditStatus: this.data.creditStatus,
        dunningStatus: this.data.dunningStatus,
        nextDunningStatus: this.data.nextDunningStatus,
        nextDunningStatusDate: this.data.nextDunningStatusDate,
        dunningPausedUntilDate: this.data.dunningPausedUntilDate,
        salesRep: this.data.salesRepName,
        invoiceChannelEmail: this.data.invoiceChannelEmail,
        invoiceChannelPrint: this.data.invoiceChannelPrint,
        billCycleDay: this.data.billCycleDay,
        blackOutDate: this.data.blackOutDate,
        soldToSameAsBillTo: this.data.soldToSameAsBillTo,
        aCCSSN: this.data.personalInfo.socialSecurityNumber,
      }
      const { fetch } = updateAccountData(dataToUpdate)

      try {
        await fetch()
        this.pristineData = JSON.parse(JSON.stringify(this.data))
      } catch (error) {
        console.error(error)
      }
    },
    hasChanged() {
      return JSON.stringify(this.data) !== JSON.stringify(this.pristineData)
    },
    // Generic function to start and populate the store.
    async startStore() {
      try {
        this.relatedAccountsLoading = true
        await this.fetchAllData()
        await this.fetchRelatedAccounts()
      } catch (error) {
        console.error(error)
      } finally {
        this.relatedAccountsLoading = false
      }
    },
  },
})

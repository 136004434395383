<template>
  <li class="accordion__item relative py-5 border-b border-gray-200">
    <div
      class="cursor-pointer flex justify-between items-start"
      @click.prevent="open()"
    >
      <div class="mr-5 w-full">
        <slot name="question"></slot>
      </div>
      <button
        aria-label="Toggle Accordion"
        type="button"
        class="text-gray-800 dark:text-white cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
      >
        <svg
          v-if="visible"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.16602 10H15.8327"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <svg
          v-else
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="path2"
            class=""
            d="M10 4.1665V15.8332"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.16602 10H15.8327"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-show="visible" class="font-normal text-lg text-gray-700 mr-6">
        <slot name="answer"></slot>
      </div>
    </transition>
  </li>
</template>
<script lang="ts">
import { defineComponent, inject, ref, computed } from 'vue'
import { accordionKey } from '@gv/core'

export default defineComponent({
  name: 'GVAccordion',

  setup() {
    const Accordion = inject(accordionKey)!
    const index = ref(Accordion.value.count++)

    const visible = computed(() => index.value === Accordion.value.active)

    const open = () => {
      if (visible.value) {
        Accordion.value.active = null
      } else {
        Accordion.value.active = index.value
      }
    }
    const start = (el: HTMLElement) =>
      (el.style.height = el.scrollHeight + 'px')
    const end = (el: HTMLElement) => (el.style.height = '')

    return { visible, open, start, end }
  },
})
</script>

<style scoped>
.accordion__trigger {
  display: flex;
  justify-content: space-between;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height;
  transition: height 0.5s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
}
</style>

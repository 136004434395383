<template>
  <div class="relative w-full">
    <div class="text-gray-500 absolute ml-0 inset-0 m-auto w-4 h-4">
      <div
        class="cursor-pointer hover:text-green-500 px-5"
        @click="searchAction"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-search"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <circle cx="10" cy="10" r="7" />
          <line x1="21" y1="21" x2="15" y2="15" />
        </svg>
      </div>
    </div>
    <!-- 
      
     -->
    <input
      v-bind="$attrs"
      v-bind:value="value"
      v-on:keyup="captureReturnKey"
      class="border border-gray-100 focus:outline-none focus:border-green-700 rounded w-full text-sm text-gray-500 bg-gray-100 pl-12 py-2"
      type="text"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'SSearchbarGV',
  emits: ['searchAction'],
  props: ['value'],
  inheritAttrs: false,
  setup(props, context) {
    const value = ref(props.value)
    const captureReturnKey = (e) => {
      if (e.keyCode === 13) {
        searchAction()
      }
    }
    const searchAction = () => {
      context.emit('searchAction')
    }
    return { captureReturnKey, searchAction, value }
  },
})
</script>

<template>
  <nav
    v-bind="$attrs"
    class="h-16 flex items-center lg:items-stretch justify-end lg:justify-between bg-white nav-width shadow fixed top-0 z-10"
  >
    <div class="hidden lg:flex w-full pr-6">
      <slot name="content"> </slot>
    </div>
    <div
      class="text-gray-600 flex items-center w-full h-full visible lg:hidden relative"
    >
      <slot name="mobileContent">
        <div
          class="w-1/4 pl-2 cursor-pointer h-full flex items-center"
          @click="$emit('toggleMobileNav')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-menu-2"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="4" y1="6" x2="20" y2="6" />
            <line x1="4" y1="12" x2="20" y2="12" />
            <line x1="4" y1="18" x2="20" y2="18" />
          </svg>
        </div>

        <div class="p-1 lg:p-32 md:p-20">
          <slot name="mobileBranding"></slot>
        </div>
      </slot>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SNavbar',
  emits: ['toggleMobileNav'],
})
</script>

<style scoped>
.nav-width {
  width: calc(100% - 256px);
}
@media screen and (max-width: 768px) {
  .nav-width {
    width: 100%;
  }
}
</style>

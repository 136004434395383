<template>
  <div>
    <p class="font-bold mb-2">
      {{ getText('welcome_to_synoptik') }}
    </p>
    <div class="space-y-8 md:space-y-0 mb-6 md:flex">
      <div>
        <div
          v-if="accountDataStore.data.personalInfo?.firstName"
          class="my-5 font-bold text-primaryColor-medium xl:text-left h-auto left-0"
        >
          <h4 class="text-3xl">
            {{
              accountDataStore.data.personalInfo?.firstName +
              ' ' +
              accountDataStore.data.personalInfo?.lastName
            }}
          </h4>
          <h4 class="text-2xl">
            <p>
              {{ getText('customer_number') }}:
              {{ accountDataStore.data.customerNumber }}
            </p>
          </h4>
        </div>
        <p class="text-sm font-bold">
          {{ getText('subscription_welcome') }}
          <router-link
            class="text-primaryColor-medium"
            :to="{ name: 'Subscriptions' }"
          >
            {{ getText('glasses_subscription').toLowerCase() }}
          </router-link>
        </p>
      </div>
      <GVCard
        class="ml-auto absolute h-auto md:min-w-max min-w-full w-1/3 overflow-x-hidden"
        :skeletonLoader="accountDataStore.accountLoading"
      >
        <template v-slot:body>
          <div class="text-sm text-left">
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('payment_method') }}
            </div>
            <div class="mb-4">
              {{
                setPaymentMethod(
                  paymentsDataStore.defaultPaymentMethod.paymentMethodType
                )
              }}
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('invoice_method') }}
            </div>
            <div>
              {{
                invoiceDeliveryPreference(
                  accountDataStore.data.invoiceChannelEmail,
                  accountDataStore.data.invoiceChannelPrint
                )
              }}
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-cols-2 xl:flex xl:justify-left">
            <GVButton
              class="h-12 xl:col-span-1 col-span-2 flex justify-center"
              data-testid="changePaymentMethodBtn"
              @click="goToPage('PaymentMethod')"
              >{{ getText('change_payment_method') }}</GVButton
            >
            <GVButton
              class="h-12 xl:col-span-1 col-span-2 flex justify-center"
              data-testid="changeInvoicesDeliveryBtn"
              @click="goToPage('AccountDetails')"
              >{{ getText('change_invoice_delivery') }}</GVButton
            >
          </div>
        </template>
      </GVCard>
    </div>
    <div
      class="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-6 overflow-visible"
    >
      <GVCard
        class="flex flex-col xl:col-span-1 md:col-span-3 h-auto"
        :skeletonLoader="accountDataStore.accountLoading"
        :hasTooltip="true"
      >
        <template v-slot:tooltipText>
          {{ getText('account_balance_tooltip') }}
          <br /><br />
          {{ getText('account_balance_tooltip_cnt') }}
        </template>
        <template v-slot:title>
          {{ getText('account_balance') }}
        </template>
        <!-- <template v-slot:icon><FlagIcon /></template> -->
        <template v-slot:body>
          <div class="">
            <p class="text-primaryColor-medium">
              {{
                formatCurrency(
                  accountDataStore.data.totalOutstandingBalance,
                  bloomreach.getLocale(),
                  true,
                  true
                )
              }}
            </p>
          </div>
        </template>
        <!-- <template v-slot:extra v-if="accountData.totalOutstandingBalance > 0">{{
          getText('account_balance_notice')
        }}</template> -->
        <template v-slot:fixedFooter>
          <div class="mt-auto grid grid-cols-2 xl:flex xl:justify-left">
            <GVButton
              class="h-12 xl:col-span-1 col-span-1 flex justify-center"
              data-testid="goToInvoicesBtn"
              @click="goToPage('Bills')"
              >{{ getText('go_to_invoices') }}</GVButton
            >
          </div>
        </template>
      </GVCard>
      <GVCard
        class="xl:col-span-1 flex flex-col md:col-span-3 h-auto overflow-visible"
        :skeletonLoader="
          accountDataStore.accountLoading ||
          accountDataStore.notDueBalanceLoading
        "
        :hasTooltip="true"
      >
        <template v-slot:tooltipText>
          {{ getText('not_due_balance_tooltip') }}
        </template>
        <template v-slot:title>{{ getText('not_due_balance') }}</template>
        <!-- <template v-slot:icon><FlagIcon /></template> -->
        <template v-slot:body>
          <div class="">
            <p class="text-primaryColor-medium">
              {{
                formatCurrency(
                  accountDataStore.data.notDueBalance,
                  bloomreach.getLocale(),
                  true,
                  true
                )
              }}
            </p>
          </div>
        </template>
        <template v-slot:extra v-if="accountDataStore.data.notDueBalance > 0">{{
          getText('not_due_balance_notice')
        }}</template>
        <template v-slot:fixedFooter>
          <div class="mt-auto grid grid-cols-2 xl:flex xl:justify-left">
            <GVButton
              class="h-12 xl:col-span-1 col-span-1 flex justify-center"
              data-testid="goToInvoicesBtn"
              @click="goToPage('Bills')"
              >{{ getText('go_to_invoices') }}</GVButton
            >
          </div>
        </template>
      </GVCard>
      <GVCard
        class="xl:col-span-1 flex flex-col md:col-span-3 h-auto overflow-visible"
        :skeletonLoader="
          accountDataStore.accountLoading ||
          accountDataStore.outstandingBalanceLoading
        "
        :hasTooltip="true"
      >
        <template v-slot:tooltipText>
          {{ getText('outstanding_balance_tooltip') }}
        </template>
        <template v-slot:title>{{ getText('outstanding_balance') }}</template>
        <!-- <template v-slot:icon><FlagIcon /></template> -->
        <template v-slot:body>
          <div class="">
            <p class="text-primaryColor-medium">
              {{
                formatCurrency(
                  accountDataStore.data.outstandingBalance,
                  bloomreach.getLocale(),
                  true,
                  true
                )
              }}
            </p>
          </div>
        </template>
        <template
          v-slot:extra
          v-if="accountDataStore.data.outstandingBalance > 0"
          >{{ getText('outstanding_balance_notice') }}</template
        >
        <template v-slot:fixedFooter>
          <div class="mt-auto grid grid-cols-2 xl:flex xl:justify-left">
            <GVButton
              class="h-12 xl:col-span-1 col-span-1 flex justify-center"
              data-testid="goToInvoicesBtn"
              @click="goToPage('Bills')"
            >
              {{ getText('go_to_invoices') }}
            </GVButton>
          </div>
        </template>
      </GVCard>
    </div>
    <!-- <div class="space-y-8 md:space-y-0 md:grid md:grid-cols-3 mt-6 md:gap-6">
      <GVCard
        class="md:col-span-3 xl:col-span-3 h-auto"
        :skeletonLoader="accountDataStore.relatedAccountsLoading"
        v-if="
          accountDataStore.relatedAccounts.children.length > 0 ||
          (accountDataStore.relatedAccounts.parent &&
            accountDataStore.relatedAccounts.parent != null)
        "
      >
        <template v-slot:body>
          <div
            class=""
            v-if="accountDataStore.relatedAccounts.children.length > 0"
          >
            <div v-if="!isMobile()" class="flex flex-col">
              <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="overflow-hidden">
                    <table class="min-w-full">
                      <thead class="bg-white border-b">
                        <tr>
                          <th
                            scope="col"
                            class="text-base font-bold text-gray-900 pl-2 pr-6 py-4 text-left"
                          >
                            {{ getText('name') }}
                          </th>
                          <th
                            scope="col"
                            class="text-base font-bold text-gray-900 pl-2 pr-6 py-4 text-left"
                          >
                            {{ getText('customer_number') }}
                          </th>
                          <th
                            scope="col"
                            class="text-base font-bold text-gray-900 pl-2 pr-6 py-4 text-right whitespace-nowrap"
                          >
                            {{ getText('total_monthly_price') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                          v-for="account in accountDataStore.relatedAccounts
                            .children"
                          :key="account.customerNumber"
                        >
                          <td
                            class="text-base text-gray-900 font-normal pl-2 pr-6 py-4"
                          >
                            {{ account.customerName }}
                          </td>
                          <td
                            class="text-base text-gray-900 font-normal pl-2 pr-6 py-4 whitespace-nowrap"
                          >
                            {{ account.customerNumber }}
                          </td>
                          <td
                            class="text-base text-gray-900 font-normal pl-2 pr-6 py-4 whitespace-nowrap text-right"
                          >
                            {{
                              formatCurrency(
                                account.totalMonthlyPrice,
                                bloomreach.getLocale(),
                                true,
                                true
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              v-for="account in accountDataStore.relatedAccounts.children"
              :key="account.customerNumber"
              class="my-4 border shadow-md px-4 py-2 w-full"
            >
              <table class="w-full">
                <tr class="border-b">
                  <th class="text-sm font-bold text-gray-900 text-left py-3">
                    {{ getText('name') }}
                  </th>
                  <td
                    class="text-sm text-gray-900 font-normal text-right px-2 text-small w-1/2"
                  >
                    {{ account.customerName }}
                  </td>
                </tr>
                <tr class="border-b">
                  <th class="text-sm font-bold text-gray-900 text-left py-3">
                    {{ getText('customer_number') }}
                  </th>
                  <td
                    class="text-sm text-gray-900 font-normal text-right px-2 text-small w-1/2"
                  >
                    {{ account.customerNumber }}
                  </td>
                </tr>
                <tr>
                  <th class="text-sm font-bold text-gray-900 text-left py-3">
                    {{ getText('total_monthly_price') }}
                  </th>
                  <td
                    class="text-sm text-gray-900 font-normal text-right px-2 text-small w-1/2"
                  >
                    {{
                      formatCurrency(
                        account.totalMonthlyPrice,
                        bloomreach.getLocale(),
                        true,
                        true
                      )
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </template>
        <template
          v-slot:extra
          v-if="
            accountDataStore.relatedAccounts.parent &&
            accountDataStore.relatedAccounts.parent != null
          "
          >{{ getText('customer_has_guardian_msg') }}</template
        >
      </GVCard>
    </div> -->
    <GVPaymentTypeModal
      :payment-method-type="paymentMethodType"
      :open-modal="choosePaymentMethodModal"
      :has-default-payment-method="
        paymentsDataStore.defaultPaymentMethod?.paymentMethodType &&
        paymentsDataStore.defaultPaymentMethod.paymentMethodType != 'None'
      "
      @update-modal="closePaymentWindow"
      @payment-type="choosePaymentType"
    />
    <GVPaymentModal
      :open-modal="payNowModal"
      :payment-option="paymentOption"
      :invoice="paymentInvoice"
      :has-default-payment-method="
        paymentsDataStore.defaultPaymentMethod?.paymentMethodType &&
        paymentsDataStore.defaultPaymentMethod.paymentMethodType != 'None'
      "
      @update-modal="changeOpenModal"
      @payment-successful="paymentComplete"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, inject } from 'vue'
import { useRouter } from 'vue-router'
import {
  SLoading,
  GVCard,
  GVButton,
  GVPaymentModal,
  GVPaymentTypeModal,
  formatCurrency,
  formatDate,
  STooltip,
  isMobile,
  bloomreachKey,
} from '@gv/core'

import DefaultPaymentMethodCard from '../components/DefaultPaymentMethodCard.vue'

import FlagIcon from '../assets/icon-tabler-flag.svg'
import BusinessPlanIcon from '../assets/icon-tabler-businessplan.svg'
import FileIcon from '../assets/icon-tabler-file-invoice.svg'
import ReportIcon from '../assets/icon-tabler-report.svg'
import RightArrowIcon from '../assets/right_arrow.svg'

import { useAccountDataStore } from '../stores/accountData'
import { usePaymentsDataStore } from '../stores/paymentsData'
import { useInvoicesDataStore } from '../stores/invoicesData'
import { useSubscriptionDataStore } from '../stores/subscriptionData'

export default defineComponent({
  name: 'MyAccount',
  components: {
    SLoading,
    GVButton,
    GVCard,
    FlagIcon,
    BusinessPlanIcon,
    FileIcon,
    ReportIcon,
    RightArrowIcon,
    GVPaymentModal,
    GVPaymentTypeModal,
    DefaultPaymentMethodCard,
    STooltip,
  },
  setup() {
    const payNowModal = ref(false)
    const accountData = ref({})
    const showPaymentMethodAccountBalance = ref(false)
    const showPaymentMethodOutstandingBalance = ref(false)
    const showPaymentMethodNotDueBalance = ref(false)
    const paymentOption = ref('')
    const choosePaymentMethodModal = ref(false)
    const paymentMethodType = ref('')
    const bloomreach = inject(bloomreachKey)!
    const paymentInvoice = ref({ number: '', id: '' })
    const getText = (key: string) => bloomreach.getText(key)

    const accountDataStore = useAccountDataStore()
    const paymentsDataStore = usePaymentsDataStore()
    const invoicesDataStore = useInvoicesDataStore()
    const subscriptionDataStore = useSubscriptionDataStore()

    const router = useRouter()

    const payNowAccountBalance = (value) => {
      showPaymentMethodAccountBalance.value = value
    }
    const payNowOutstandingBalance = (value) => {
      showPaymentMethodOutstandingBalance.value = value
    }
    const payNowNotDueBalance = (value) => {
      showPaymentMethodNotDueBalance.value = value
    }

    const openPaymentWindow = (object: object) => {
      paymentInvoice.value = { number: object.number, id: object.id }
      paymentOption.value = object.option
      choosePaymentType('CreditCard')
      // if (object.id === 'OUTSTANDING') {
      //   choosePaymentType('CreditCard')
      // } else {
      //   if (object.option === 'newPaymentMethod') {
      //     if (
      //       accountDataStore.data.personalInfo?.mandateActive == 'Requested' ||
      //       object.paymentMethodType === 'Autogiro' ||
      //       object.paymentMethodType === 'Betalingsservice'
      //     ) {
      //       choosePaymentType('CreditCard')
      //     } else {
      //       choosePaymentMethodModal.value = true
      //     }
      //   } else {
      //     payNowModal.value = true
      //   }
      // }
    }

    const closePaymentWindow = () => {
      choosePaymentMethodModal.value = false
      payNowModal.value = false
    }

    const choosePaymentType = (option: string) => {
      if (option === 'CreditCard') {
        choosePaymentMethodModal.value = false
        payNowModal.value = true
      }
    }

    const changeOpenModal = async (value: boolean) => {
      payNowModal.value = value
    }

    const paymentComplete = async (value: boolean) => {
      if (value) {
        await accountDataStore.fetchAllData()
        paymentInvoice.value = { number: '', id: '' }
      }
    }

    const goToPage = (page: string) => {
      router.push({ name: page })
    }

    const invoiceDeliveryPreference = (email, print) => {
      if (email) {
        if (print) {
          return `${bloomreach.getText('email')}, ${bloomreach.getText(
            'print'
          )}`
        }
        return `${bloomreach.getText('email')}`
      } else if (print) {
        return `${bloomreach.getText('print')}`
      }
      return ''
    }

    const setPaymentMethod = (paymentMethod) => {
      if (!paymentMethod || ['None', 'none'].includes(paymentMethod)) {
        return bloomreach.getText('manual_payment')
      }

      if (paymentMethod.toLowerCase().includes('credit')) {
        return bloomreach.getText('credit_card')
      }

      return paymentMethod
    }

    onMounted(() => {
      // In case the page refreshes or this is the first route accessed
      if (accountDataStore.storeNotStarted) accountDataStore.startStore()
      // ? Pre-loading other pages on first access
      if (paymentsDataStore.storeNotStarted) paymentsDataStore.startStore()
      if (invoicesDataStore.storeNotStarted) invoicesDataStore.startStore()
      if (invoicesDataStore.storeNotStarted) invoicesDataStore.startStore()
      if (subscriptionDataStore.storeNotStarted)
        subscriptionDataStore.startStore()
    })

    return {
      accountData,
      payNowAccountBalance,
      payNowOutstandingBalance,
      payNowNotDueBalance,
      showPaymentMethodAccountBalance,
      showPaymentMethodOutstandingBalance,
      showPaymentMethodNotDueBalance,
      openPaymentWindow,
      payNowModal,
      changeOpenModal,
      paymentOption,
      paymentComplete,
      choosePaymentMethodModal,
      choosePaymentType,
      closePaymentWindow,
      getText,
      formatCurrency,
      paymentMethodType,
      bloomreach,
      formatDate,
      paymentInvoice,
      goToPage,
      isMobile,
      invoiceDeliveryPreference,
      accountDataStore,
      paymentsDataStore,
      setPaymentMethod,
    }
  },
})
</script>

<template>
  <transition
    enter-active-class="transition ease-out duration-200 delay-200 transform"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-200 transform"
    leave-from-class="opacity-0"
    leave-to-class="opacity-0"
  >
    <div v-if="isActive" class="min-h-full">
      <slot></slot>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, watch, onBeforeMount, inject, ref } from 'vue'

export default defineComponent({
  name: 'STabItem',
  props: {
    title: {
      type: String,
      default: 'Tab',
    },
  },
  setup(props) {
    const isActive = ref(false)
    const tabs: TabsProvider = <TabsProvider>inject('TabsProvider')

    watch(
      () => tabs.selectedIndex,
      () => {
        isActive.value = props.title === tabs.selectedIndex
      }
    )

    onBeforeMount(() => {
      isActive.value = props.title === tabs.selectedIndex
    })
    return { isActive }
  },
})

interface TabsProvider {
  selectedIndex: ''
}
</script>

export const countryVars = [
  {
    LOCALE: 'sv-SE',
    BANNER: 'Synoptik AB',
    CURRENCY: 'SEK',
  },
  {
    LOCALE: 'da-DK',
    BANNER: 'Synoptik A/S',
    CURRENCY: 'DKK',
  },
  {
    LOCALE: 'nl-NL',
    BANNER: 'Pearle',
    CURRENCY: 'EUR',
  },
]

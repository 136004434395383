<template>
  <div class="mt-6">
    <FaqAnswers />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { bloomreachKey } from '@gv/core'
import FaqAnswers from '../components/contact/FaqAnswers.vue'

export default defineComponent({
  name: 'ContactsPage',
  components: { FaqAnswers },

  setup() {
    const bloomreach = inject(bloomreachKey)
    return { bloomreach }
  },
})
</script>

<style></style>

<template>
  <div class="relative flex flex-no-wrap h-full overflow-y-auto min-h-screen">
    <div class="w-full">
      <div
        class="h-16 flex items-center lg:items-stretch justify-end lg:justify-between bg-white shadow relative z-10"
      >
        <div class="md:w-11/12 w-3/4 h-full py-1 flex pl-10">
          <SynoptikLogo />
        </div>
        <div class="flex align-middle items-center pl-8">
          <div>
            <FlagDK
              v-if="bloomreach.getLocale() === 'da-DK'"
              class="w-10 px-2 mx-4"
            />
            <FlagSV
              v-if="bloomreach.getLocale() === 'sv-SE'"
              class="w-10 px-2 mx-4"
            />
          </div>
        </div>
      </div>

      <div
        class="container mx-auto pt-6 pb-12 px-2 sm:px-10 md:px-20 overflow-auto"
      >
        <div
          v-if="!goBackUrl || (goBackUrl && goBackUrl.length <= 0)"
          class="p-6 mb-10 border text-black shadow"
        >
          <p class="mb-3">
            <span class="font-semibold">
              {{ bloomreach.getText('stay_up_to_date') }}:
            </span>
            {{ bloomreach.getText('register_easily') }}
          </p>
          <GVButton
            class="h-12 w-40 flex items-center justify-center"
            data-testid="my_synoptics"
            href="https://www.synoptik.dk/mit-synoptik-brilleabonnement"
            target="_blank"
            >{{ bloomreach.getText('my_synoptics') }}
          </GVButton>
        </div>

        <div class="grid grid-cols-1">
          <div class="mt-5 max-h-screen">
            <div v-if="loading">
              <SLoading class="inset-1/2 -ml-8 mt-8 relative h-24 w-24" />
            </div>
            <div v-else>
              <div class="flex justify-center font-semibold text-xl">
                <p
                  :class="{
                    'text-green-500': resultMsg.status === 'Success',
                    'text-red-500': resultMsg.status === 'Error',
                    'text-primaryColor-light': resultMsg.status === 'Warning',
                  }"
                >
                  {{ resultMsg.msg }}
                </p>
              </div>
              <GVButton
                v-if="goBackUrl && goBackUrl.length > 0"
                class="h-12 w-40 relative items-center uppercase justify-center left-1/2 -ml-20 mt-12"
                data-testid="goBackUrl"
                :href="goBackUrl"
              >
                {{ bloomreach.getText('ok') }}
              </GVButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject } from 'vue'
import { useRoute } from 'vue-router'
import { validatePCPayment, SLoading, GVButton, bloomreachKey } from '@gv/core'
import SynoptikLogo from '../assets/SynoptikLogo.svg'
import FlagDK from '../assets/dk.svg'
import FlagSV from '../assets/sv.svg'

export default defineComponent({
  name: 'PaymentCallback',
  components: {
    FlagDK,
    FlagSV,
    SynoptikLogo,
    SLoading,
    GVButton,
  },
  setup() {
    const resultMsg = ref({
      status: '',
      msg: '',
    })
    const route = useRoute()
    const bloomreach = inject(bloomreachKey)
    const invoices = route.query.invoices
    const redirectResult = route.query.redirectResult
    const recurringPayment = route.query.recurringPayment
    const accountId = route.query.accountId
    const totalAmount = route.query.totalAmount
    const currency = route.query.currency
    const country = route.query.country

    const goBackUrl =
      route.query.goBackUrl && route.query.goBackUrl != 'null'
        ? route.query.goBackUrl
        : undefined

    const payload = {
      invoices: invoices && invoices.length > 0 ? invoices : null,
      redirectResult:
        redirectResult && redirectResult.length > 0 ? redirectResult : null,
      recurringPaymentMethod:
        recurringPayment && recurringPayment.length > 0
          ? recurringPayment.toLowerCase() === 'true'
          : null,
      accountId: accountId && accountId.length > 0 ? accountId : null,
      totalAmount:
        totalAmount && totalAmount.length > 0 ? parseFloat(totalAmount) : null,
      currency: currency && currency.length > 0 ? currency : null,
      country: country && country.length > 0 ? country : null,
    }

    const { fetch, loading } = validatePCPayment(payload)

    fetch()
      .then(() => {
        resultMsg.value = {
          status: 'Success',
          msg: bloomreach.getText('payment_validation_msg_success'),
        }
      })
      .catch((error) => {
        resultMsg.value = {
          status: 'Error',
          msg: bloomreach.getText('payment_validation_msg_error'),
        }
        console.error(error)
        throw Error(error)
      })

    return {
      resultMsg,
      loading,
      bloomreach,
      goBackUrl,
    }
  },
})
</script>

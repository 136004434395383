<template>
  <GVCard class="mb-5">
    <template v-slot:header>
      <h1 class="mt-3">{{ bloomreach.getText('faq') }}</h1>
    </template>

    <template v-slot:body>
      <div class="mt-10">
        <GVAccordion>
          <!-- Q1 -->
          <GVAccordionItem>
            <template v-slot:question>
              <h3
                class="font-semibold text-lg dark:text-white leading-6 text-gray-800 w-full"
              >
                {{ bloomreach.getText('question_one') }}
              </h3>
            </template>
            <template v-slot:answer>
              <p class="pt-5">{{ bloomreach.getText('answer_one') }}</p>
            </template>
          </GVAccordionItem>

          <!-- Q2 -->
          <GVAccordionItem>
            <template v-slot:question>
              <h3
                class="font-semibold text-lg dark:text-white leading-6 text-gray-800 w-full"
              >
                {{ bloomreach.getText('question_two') }}
              </h3>
            </template>
            <template v-slot:answer>
              <p class="py-5">
                {{ bloomreach.getText('answer_two') }}
              </p>
              <GVButton
                class="h-12 px-8 w-min whitespace-nowrap flex items-center justify-center"
                data-testid="book_time"
                :href="bloomreach.getText('book_time_link')"
                target="_blank"
              >
                {{ bloomreach.getText('book_time') }}
              </GVButton>
            </template>
          </GVAccordionItem>

          <!-- Q3 -->
          <GVAccordionItem>
            <template v-slot:question>
              <h3
                class="font-semibold text-lg dark:text-white leading-6 text-gray-800 w-full"
              >
                {{ bloomreach.getText('question_three') }}
              </h3>
            </template>
            <template v-slot:answer>
              <p class="pt-5">{{ bloomreach.getText('answer_three') }}</p>
            </template>
          </GVAccordionItem>

          <!-- Q4 -->
          <GVAccordionItem>
            <template v-slot:question>
              <h3
                class="font-semibold text-lg dark:text-white leading-6 text-gray-800 w-full"
              >
                {{ bloomreach.getText('question_four') }}
              </h3>
            </template>
            <template v-slot:answer>
              <p class="pt-5">{{ bloomreach.getText('answer_four') }}</p>
            </template>
          </GVAccordionItem>

          <!-- Q5 -->
          <GVAccordionItem>
            <template v-slot:question>
              <h3
                class="font-semibold text-lg dark:text-white leading-6 text-gray-800 w-full"
              >
                {{ bloomreach.getText('question_five') }}
              </h3>
            </template>
            <template v-slot:answer>
              <!-- <p class="py-5">{{ bloomreach.getText('answer_five') }}</p> -->

              <div class="ml-5 pt-5">
                <ul class="list-disc">
                  <li>{{ bloomreach.getText('invoice_bullet_one') }}</li>
                  <li>{{ bloomreach.getText('invoice_bullet_two') }}</li>
                  <li v-if="bloomreach.getLocale() === 'sv-SE'">
                    {{ bloomreach.getText('invoice_bullet_three') }}
                  </li>
                  <ul class="ml-5 list-disc">
                    <li>{{ bloomreach.getText('invoice_sub_bullet_one') }}</li>
                    <li>{{ bloomreach.getText('invoice_sub_bullet_two') }}</li>
                    <li>
                      {{ bloomreach.getText('invoice_sub_bullet_three') }}
                    </li>
                    <li>{{ bloomreach.getText('invoice_sub_bullet_four') }}</li>
                    <li>{{ bloomreach.getText('invoice_sub_bullet_five') }}</li>
                    <li>{{ bloomreach.getText('invoice_sub_bullet_six') }}</li>
                    <li>
                      {{ bloomreach.getText('invoice_sub_bullet_seven') }}
                    </li>
                    <li v-if="bloomreach.getLocale() === 'sv-SE'">
                      {{ bloomreach.getText('invoice_sub_bullet_eight') }}
                    </li>
                  </ul>
                </ul>
              </div>
            </template>
          </GVAccordionItem>

          <!-- Q6 -->
          <GVAccordionItem>
            <template v-slot:question>
              <h3
                class="font-semibold text-lg dark:text-white leading-6 text-gray-800 w-full"
              >
                {{ bloomreach.getText('question_six') }}
              </h3>
            </template>
            <template v-slot:answer>
              <p class="pt-5">{{ bloomreach.getText('answer_six') }}</p>
            </template>
          </GVAccordionItem>

          <!-- Q7 -->
          <GVAccordionItem>
            <template v-slot:question>
              <h3
                class="font-semibold text-lg dark:text-white leading-6 text-gray-800 w-full"
              >
                {{ bloomreach.getText('question_seven') }}
              </h3>
            </template>
            <template v-slot:answer>
              <p class="pt-5">{{ bloomreach.getText('answer_seven') }}</p>
            </template>
          </GVAccordionItem>

          <!-- Q8 -->
          <GVAccordionItem>
            <template v-slot:question>
              <h3
                class="font-semibold text-lg dark:text-white leading-6 text-gray-800 w-full"
              >
                {{ bloomreach.getText('question_eight') }}
              </h3>
            </template>
            <template v-slot:answer>
              <p class="pt-5">{{ bloomreach.getText('answer_eight') }}</p>
            </template>
          </GVAccordionItem>

          <!-- Q9 -->
          <GVAccordionItem>
            <template v-slot:question>
              <h3
                class="font-semibold text-lg dark:text-white leading-6 text-gray-800 w-full"
              >
                {{ bloomreach.getText('question_nine') }}
              </h3>
            </template>
            <template v-slot:answer>
              <p class="pt-5">{{ bloomreach.getText('answer_nine') }}</p>
            </template>
          </GVAccordionItem>

          <!-- Q10 -->
          <GVAccordionItem>
            <template v-slot:question>
              <h3
                class="font-semibold text-lg dark:text-white leading-6 text-gray-800 w-full"
              >
                {{ bloomreach.getText('question_ten') }}
              </h3>
            </template>
            <template v-slot:answer>
              <p class="pt-5">{{ bloomreach.getText('answer_ten') }}</p>
            </template>
          </GVAccordionItem>
        </GVAccordion>
      </div>
    </template>
  </GVCard>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import {
  GVCard,
  GVButton,
  GVAccordion,
  GVAccordionItem,
  bloomreachKey,
} from '@gv/core'

export default defineComponent({
  name: 'FaqComponent',
  components: { GVCard, GVButton, GVAccordion, GVAccordionItem },

  setup() {
    const bloomreach = inject(bloomreachKey)

    return { bloomreach }
  },
})
</script>

<template>
  <component
    :is="tag"
    v-focus
    v-bind="$attrs"
    class="bg-black text-white hover:bg-white hover:text-black cursor-pointer text-center py-3 px-5 flex shadow-md border transition duration-300 ease-in-out outline-none focus:outline-none"
    :class="{
      'opacity-60 hover:cursor-not-allowed cursor-not-allowed': buttonActive,
      'hover:text-gray-500 hover:cursor-not-allowed': linkActive,
    }"
    :disabled="disabled"
    :to="to"
    :link="link"
    :href="href"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'GVButton',
  inheritAttrs: false,
  directives: {
    focus,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: [String, Object],
      default: '',
    },
    href: {
      type: [String, Object],
      default: '',
    },
    to: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    tag() {
      if (this.to) return 'router-link'
      return this.link || this.href ? 'a' : 'button'
    },
    linkActive() {
      return this.link && this.disabled
    },
    buttonActive() {
      return !this.link && this.disabled
    },
  },
})
</script>

// TODO: Split this file between the 3 new created files and delete after. Or rename/keep if there's anything shared between portals

/**
 * EP = EndPoint
 * AR = Customer Care Portal
 * PP = Payments Portal / Customer Portal
 * PC = Payment Capture / Customer Portal
 */

//Authentication
export const API_BASE_AUTHENTICATE = '/api/authenticate'

export const EP_AUTH_CLIENT_IDENTITY = `${API_BASE_AUTHENTICATE}/client/identity`
export const EP_AUTH_CLIENT_SERVICE = `${API_BASE_AUTHENTICATE}/client/service`
export const EP_AUTH_CUSTOMER_IDENTITY = `${API_BASE_AUTHENTICATE}/customer/identity`
export const EP_AUTH_CUSTOMER_TRANSACTION = `${API_BASE_AUTHENTICATE}/customer/identity/transaction`
export const EP_AUTH_CUSTOMER_IDENTITY_VALIDATION_TOKEN = `${EP_AUTH_CUSTOMER_IDENTITY}/validateToken`
export const EP_AUTH_AZURE_AD_VALIDATION_TOKEN = `${API_BASE_AUTHENTICATE}/azure-ad/client/identity/validate-token`

//CustomerCarePortalCustomer aka AR Portal
export const API_BASE_AR = '/api/customerCare'
export const API_NEW_BASE_AR = '/api/customer-care'

export const EP_AR_CUSTOMERS = (accountNumber: string) =>
  `${API_BASE_AR}/customers/${accountNumber}`
export const EP_AR_CUSTOMERS_SEARCH = `${API_BASE_AR}/customers/search/`
export const ER_AR_CUSTOMERS_QUICK_SEARCH = `${API_BASE_AR}/customers/quickSearch/`
export const EP_AR_DUNNING_SEARCH = `${API_BASE_AR}/customers/dunning`
export const EP_AR_EXECUTE_DUNNING = `${API_NEW_BASE_AR}/dunning/execute`
export const EP_AR_GET_DUNNING_WF_STATUS = (id: string) =>
  `${API_BASE_AR}/customers/dunning/status/${id}`
export const EP_AR_CUSTOMERS_UNMATCHED_SEARCH = `${API_BASE_AR}/customers/unmatched/`
export const EP_AR_INVOICES = (accountNumber: string) =>
  `${API_BASE_AR}/invoices/${accountNumber}`
export const EP_AR_INVOICES_DOWNLOAD = (invoiceId: string) =>
  `${API_BASE_AR}/invoices/${invoiceId}/download`
export const EP_AR_DEBIT_MEMO_DOWNLOAD = (latestPDFFileId: string) =>
  `${API_BASE_AR}/debitmemo/${latestPDFFileId}/download`
export const EP_AR_REFUND_DOWNLOAD = (account: string, id: string) =>
  `${API_BASE_AR}/refunds/${account}/${id}/download`
export const EP_AR_INVOICE = (invoiceId: string) =>
  `${API_BASE_AR}/invoices/${invoiceId}`
export const EP_AR_RESEND_INVOICE = (invoiceId: string) =>
  `${API_BASE_AR}/financial/invoice/${invoiceId}/sendEmail`
export const EP_AR_INVOICES_DETAILS = (
  accountNumber: string,
  invoiceId: string
) => `${API_BASE_AR}/invoices/${accountNumber}/${invoiceId}`
export const EP_AR_FINANCIAL = (accountNumber: string) =>
  `${API_BASE_AR}/financial/${accountNumber}`
export const EP_AR_OPUS_DETAILS = (accountNumber: string) =>
  `${API_BASE_AR}/customers/${accountNumber}/opus/details`
export const EP_AR_SUBSCRIPTIONS = (accountNumber: string) =>
  `${API_NEW_BASE_AR}/customers/${accountNumber}/subscriptions`
export const EP_AR_PAYMENT_METHOD = (
  accountNumber: string,
  user: string | null
) => `${API_BASE_AR}/customers/${accountNumber}/${user}/paymentmethod`
export const EP_AR_INVOICES_DETAILED = (
  accountNumber: string,
  invoiceNumber: string
) =>
  `${API_NEW_BASE_AR}/customers/${accountNumber}/invoices/${invoiceNumber}/details`
export const EP_AR_STANDALONE_CREDITMEMO = `${API_NEW_BASE_AR}/financial/credit-memo`
export const EP_AR_STANDALONE_DEBITMEMO = `${API_NEW_BASE_AR}/financial/debit-memo`
export const EP_AR_CREDITMEMO_REFUND = (creditMemoId: string) =>
  `${API_NEW_BASE_AR}/financial/credit-memos/${creditMemoId}/refund`
export const EP_AR_CREATE_DEBITMEMO = (creditMemoId: string) =>
  `${API_NEW_BASE_AR}/financial/credit-memos/${creditMemoId}/debit-memo`
export const EP_AR_PAYMENT_METHODS_ACCOUNT = (accountNumber: string) =>
  `${API_BASE_AR}/financial/payment/method/${accountNumber}/all`
export const EP_AR_PAYMENT_DETAILS = (
  accountNumber: string,
  paymentId: string
) => `${API_BASE_AR}/financial/payment/${accountNumber}/${paymentId}`
export const EP_AR_EXTERNAL_PAYMENT = `${API_BASE_AR}/financial/payment`
export const EP_AR_UNAPPLY_PAYMENT = (paymentId: string) =>
  `${API_BASE_AR}/financial/payment/${paymentId}/unapply`
export const EP_AR_REFUND_PAYMENT = (paymentId: string) =>
  `${API_BASE_AR}/financial/payment/${paymentId}/refunds`
export const EP_AR_CREDITMEMO_DETAILED = (creditMemoId: string) =>
  `${API_BASE_AR}/invoices/creditmemo/${creditMemoId}`
export const EP_AR_DEBITMEMO_DETAILED = (debitMemoId: string) =>
  `${API_BASE_AR}/invoices/debitmemo/${debitMemoId}`
export const EP_AR_OPEN_INVOICES_CREDITMEMO = (accountNumber: string) =>
  `${API_NEW_BASE_AR}/financial/invoice/open/${accountNumber}`
export const EP_AR_CREATE_AND_APPLY_CREDITMEMO = (
  accountNumber: string,
  invoiceNumber: string
) =>
  `${API_NEW_BASE_AR}/customers/${accountNumber}/invoices/${invoiceNumber}/credit-memo`
export const EP_AR_REVERSE_CREDITMEMO = (
  accountNumber: string,
  creditMemoId: string
) =>
  `${API_NEW_BASE_AR}/customers/${accountNumber}/credit-memos/${creditMemoId}/reverse`
export const EP_AR_REVERSE_DEBITMEMO = (
  accountNumber: string,
  debitMemoId: string
) =>
  `${API_NEW_BASE_AR}/customers/${accountNumber}/debit-memos/${debitMemoId}/reverse`
export const EP_AR_APPLY_CREDITMEMO_TO_OPEN = (creditmemoId: string) =>
  `${API_BASE_AR}/invoices/creditmemo/${creditmemoId}/apply`
export const EP_AR_WRITE_OFF_INVOICE = (
  accountNumber: string,
  invoiceId: string
) =>
  `${API_NEW_BASE_AR}/customers/${accountNumber}/invoices/${invoiceId}/write-off`
export const EP_AR_WRITE_OFF_DEBIT_MEMO = () =>
  `${API_NEW_BASE_AR}/financial/debit-memo/write-off`
export const EP_AR_CREDIT_PARTIAL = (
  accountNumber: string,
  debitMemoId: string
) =>
  `${API_NEW_BASE_AR}/customers/${accountNumber}/debit-memos/${debitMemoId}/credit`
export const EP_AR_BILLING_BATCHES = `${API_BASE_AR}/billing/batches`
export const EP_AR_BILLRUNCHECK = (billingPreviewRunId: string) =>
  `${API_BASE_AR}/customers/billing/preview/${billingPreviewRunId}`
export const EP_AR_BILLRUNID = (subscriptionNumber: string) =>
  `${API_NEW_BASE_AR}/subscriptions/${subscriptionNumber}/bill-run/preview`
export const EP_AR_CAMPAIGNS = (accountNumber: string) =>
  `${API_BASE_AR}/campaigns/${accountNumber}`
export const EP_AR_CAMPAIGNPROMOCODES = (campaignId: number) =>
  `${API_BASE_AR}/campaigns/promo/${campaignId}`
export const EP_AR_SUBSCRIPTIONORDER = `${API_BASE_AR}/customers/subscription/order`
export const EP_AR_CUSTOMER_DETAILS_SUBSCRIPTIONS = (accountNumber: string) =>
  `${API_BASE_AR}/customers/${accountNumber}/subscriptionSummary`
export const EP_AR_UNMATCHED_TRANSACTIONS = `${API_BASE_AR}/financial/unmatched/transactions`
export const EP_AR_FINANCIAL_STATEMENT_DOWNLOAD = () =>
  `${API_BASE_AR}/financial/statement/download`
export const EP_AR_CUSTOMER_HISTORY_LOGS = (customerNumber: string) =>
  `${API_BASE_AR}/customers/${customerNumber}/history`
export const EP_AR_CUSTOMER_NEW_HISTORY_LOG = (accountNumber: string) =>
  `${API_BASE_AR}/customers/${accountNumber}/log`
export const EP_AR_CUSTOMER_EMAIL_HISTORY = (accountNumber: string) =>
  `${API_BASE_AR}/customers/${accountNumber}/emails`
export const EP_AR_CUSTOMER_CANCEL_SUBSCRIPTION = (
  accountNumber: string,
  subscriptionNumber: string
) =>
  `${API_NEW_BASE_AR}/customers/${accountNumber}/subscriptions/${subscriptionNumber}/cancel`
export const EP_AR_CUSTOMER_PREVIEW_CANCEL_SUBSCRIPTION = (
  accountNumber: string,
  subscriptionNumber: string
) =>
  `${API_NEW_BASE_AR}/customers/${accountNumber}/subscriptions/${subscriptionNumber}/cancel/preview`
export const EP_AR_CUSTOMER_ABORT_CANCEL_SUBSCRIPTION = (
  accountNumber: string,
  subscriptionNumber: string
) =>
  `${API_BASE_AR}/customers/${accountNumber}/cancelSubscription/${subscriptionNumber}/abort`
export const EP_AR_CUSTOMER_EDIT_SUBSCRIPTION = (
  accountNumber: string,
  subscriptionNumber: string
) =>
  `${API_NEW_BASE_AR}/customers/${accountNumber}/modify-subscription/${subscriptionNumber}`
export const EP_AR_CUSTOMER_EDIT_OPUS = (
  accountNumber: string,
  subscriptionNumber: string,
  opusNumber: string
) =>
  `${API_BASE_AR}/customers/${accountNumber}/modifyRatePlan/${subscriptionNumber}/${opusNumber}`
export const EP_AR_CUSTOMER_RELATED_ACCOUNTS = (accountNumber: string) =>
  `${API_BASE_AR}/customers/relatedAccounts/${accountNumber}`
export const EP_AR_DRAFT_BILL_RUNS = (locale: string) =>
  `${API_BASE_AR}/customers/billrun/draft/preview/${locale}`
export const EP_AR_BILL_RUNS_PREVIEW = (billRunId: string) =>
  `${API_NEW_BASE_AR}/bill-runs/${billRunId}/draft`
export const EP_AR_CANCEL_INVOICE = (invoiceId: string) =>
  `${API_BASE_AR}/invoices/status/${invoiceId}`
export const EP_AR_UPLOAD_CSV = `${API_BASE_AR}/financial/payment/csv/upload`
export const EP_AR_UPLOAD_MANUAL_PAYMENT_TRANSACTION = `${API_BASE_AR}/financial/payment/csv/create`
export const EP_AR_POST_DRAFT_BILL_RUN = (billRunId: string) =>
  `${API_BASE_AR}/customers/billrun/status/${billRunId}`
export const EP_AR_UNMATCH_PAYMENT = (
  accountNumber: string,
  paymentId: string,
  user: string
) =>
  `${API_BASE_AR}/financial/payment/${accountNumber}/ummatch/${paymentId}?user=${user}`
export const EP_AR_REFUND_DETAILS = (accountNumber: string, refundId: string) =>
  `${API_BASE_AR}/refunds/${accountNumber}/${refundId}`
export const EP_AR_CUSTOMER_REMAINDER_LETTER = (accountNumber: string) =>
  `${API_BASE_AR}/attachments/account/${accountNumber}`
export const EP_AR_ATTACHMENT_DOWNLOAD = (attachmentId: string) =>
  `${API_BASE_AR}/attachment/${attachmentId}/download`
export const EP_AR_SEND_CREDITMEMO_MAIL = (creditmemoId: string) =>
  `${API_BASE_AR}/financial/creditmemo/${creditmemoId}/sendEmail`
export const EP_AR_GET_DEDITMEMO_ITEMS = (debitmemoId: string) =>
  `${API_NEW_BASE_AR}/debit-memos/${debitmemoId}/items`
export const EP_AR_APPLY_DEDITMEMO_TO_A_CREDIT = `${API_BASE_AR}/financial/debitmemo/reminderfee`
export const EP_AR_CREDIT_MEMO_DOWNLOAD = (latestPDFFileId: string) =>
  `${API_BASE_AR}/creditmemo/${latestPDFFileId}/download`
export const EP_AR_CREDIT_MEMO_ITEMS = (creditMemoId: string) =>
  `${API_NEW_BASE_AR}/financial/credit-memos/${creditMemoId}/items`
export const EP_AR_FINANCIAL_UNMATCHEDTRANSACTION = (
  unmatchedTransactionId: string
) => `${API_BASE_AR}/financial/unmatched/transactions/${unmatchedTransactionId}`
export const EP_AR_RESEND_CREDITMEMO = (creditmemoId: string) =>
  `${API_BASE_AR}/financial/creditmemo/${creditmemoId}/sendEmail`
export const EP_AR_RESEND_DEBITMEMO = (debitmemoId: string) =>
  `${API_BASE_AR}/financial/debitmemo/${debitmemoId}/sendEmail`
export const EP_AR_APPLY_PAYMENT = (paymentId: string) =>
  `${API_BASE_AR}/financial/payment/${paymentId}/apply`
export const EP_AR_TRANSFER_PAYMENT = (paymentId: string) =>
  `${API_BASE_AR}/financial/payment/${paymentId}/transfer`
export const EP_AR_INVOICES_UNMATCHED_SEARCH = `${API_BASE_AR}/customers/unmatched/invoices/`
export const EP_AR_DUNNING_DOWNLOAD = (latestPdfId: string, filename: string) =>
  `${API_BASE_AR}/file/${latestPdfId}/${filename}/download`
export const EP_AR_BATCH_PAUSEDUNNING = `${API_BASE_AR}/customers/batchPauseDunning`
export const EP_AR_RESEND_DUNNING_LETTER = `${API_BASE_AR}/financial/attachment/sendEmail`
export const EP_AR_CUSTOMERS_SEARCH_BAR = `${API_NEW_BASE_AR}/customers/simple-search`
export const EP_AR_EXPORT_DATA = `${API_NEW_BASE_AR}/exports/data`
export const EP_AR_REVERT_PAYMENT_METHOD = (accountNumber: string) =>
  `${API_BASE_AR}/customers/${accountNumber}/paymentmethod/revert`
export const EP_AR_CUSTOMER_BILLRUN = `${API_NEW_BASE_AR}/customers/billrun/create`
export const EP_AR_UPDATE_DUNNING_STATUS = `${API_BASE_AR}/customers/batchUpdateDunningStatus`
export const EP_AR_CUSTOMER_MODIFY_BINDING_PERIOD = (
  accountNumber: string,
  subscriptionNumber: string
) =>
  `${API_BASE_AR}/customers/${accountNumber}/modifySubscriptionTerm/${subscriptionNumber}`
export const EP_AR_CREATE_SUBSCRIPTION = `${API_BASE_AR}/subscription`
export const EP_AR_CUSTOMER_OUTSTANDING_BALANCE = (accountNumber: string) =>
  `${API_NEW_BASE_AR}/customers/${accountNumber}/outstanding-balance`
export const EP_AR_ADYEN_PAYMENT_METHODS = (country: string) =>
  `${API_BASE_AR}/payments/allAdyenMethods/${country}`
export const EP_AR_SEPA_PAYMENT_METHOD = (accountNumber: string) =>
  `${API_BASE_AR}/customers/${accountNumber}/paymentmethod`
export const EP_AR_CUSTOMER_NUMBER_EXISTS = (customerNumber: string) =>
  `${API_BASE_AR}/customers/${customerNumber}/exists`
export const EP_AR_ACTIVATE_SUBSCRIPTION_AND_CUSTOMER = (
  accountNumber: string
) => `${API_BASE_AR}/customers/${accountNumber}/activateSubscription`
export const EP_AR_ADMIN_LOGS = (countryCode: string) =>
  `${API_BASE_AR}/customers/admin/${countryCode}/history`

export const EP_AR_PREVIEW_EMAIL_CONTENT = (contentFileId: string) =>
  `${API_BASE_AR}/file/${contentFileId}/content`
export const EP_AR_TAKE_AS_INCOME = `${API_NEW_BASE_AR}/financial/credit-memos/take-as-income`
export const EP_AR_TAKE_AS_INCOME_PAYMENT = (paymentId: string) =>
  `${API_NEW_BASE_AR}/payments/${paymentId}/take-as-income`
export const EP_AR_SEND_REFUND_EMAIL = (
  accountNumber: string,
  refundId: string
) => `${API_BASE_AR}/refunds/${accountNumber}/${refundId}/sendEmail`
export const EP_AR_CANCEL_BILLRUN_CREDIT_MEMO = (creditmemoId: string) =>
  `${API_BASE_AR}/financial/creditmemo/${creditmemoId}/cancel`
export const EP_AR_UNAPPLY_CREDIT_MEMO = (creditMemoId: string) =>
  `${API_NEW_BASE_AR}/financial/credit-memos/${creditMemoId}/unapply`

export const EP_AR_QUEUE_DATA_QUERY_STATUS = `${API_BASE_AR}/queues/data-query/status`

export const EP_AR_WORKFLOW_STATUS = (workflowId: string) =>
  `${API_NEW_BASE_AR}/workflows/${workflowId}/status`

export const ER_AR_SUBSCRIPTION_UPDATE_INVOICE_STOP_DATE = (
  subscriptionNumber: string
) => `${API_NEW_BASE_AR}/subscriptions/${subscriptionNumber}/invoice-stop-date`

//Seflhelp AKA Payments Portal / Customer Portal
export const API_BASE_PP = '/api/selfHelp'

export const EP_PP_ACCOUNT_OVERVIEW = `${API_BASE_PP}/account/overview`
export const EP_PP_ACCOUNT_OUTSTANDINGBALANCE = `${API_BASE_PP}/account/outstandingBalance`
export const EP_PP_ACCOUNT_NOTDUEBALANCE = `${API_BASE_PP}/account/notDue`
export const EP_PP_ACCOUNT_CONTACTS = `${API_BASE_PP}/account/contacts`
export const EP_PP_ACCOUNT = `${API_BASE_PP}/account`
export const EP_PP_INVOICES = `${API_BASE_PP}/invoices`
export const EP_PP_INVOICES_ITEMS = `${API_BASE_PP}/invoices/items`
export const EP_PP_INVOICES_DOWNLOAD = (invoiceId: string) =>
  `${API_BASE_PP}/invoices/${invoiceId}/download`
export const EP_PP_FINANCIAL = (customerNumber: string) =>
  `${API_BASE_PP}/financial/${customerNumber}`
export const EP_PP_PAYMENTS = `${API_BASE_PP}/payments`
export const EP_PP_PAYMENTS_DEFAULT = `${API_BASE_PP}/payments/default`
export const EP_PP_PAYMENTS_NETS = `${API_BASE_PP}/payments/nets/mandate`
export const EP_PP_SUBSCRIPTION_DETAILS = `${API_BASE_PP}/subscriptions/details`
export const EP_PP_SUBSCRIPTIONS = `${API_BASE_PP}/subscriptions`
export const EP_PP_DEFAULT_PAYMENT_METHOD = `${API_BASE_PP}/payments/method/default`
export const EP_PP_PAYMENT_METHODS = `${API_BASE_PP}/payments/method`
export const EP_PP_ALL_PAYMENT_METHODS = `${API_BASE_PP}/payments/method/all`
export const EP_PP_RELATED_ACCOUNTS = `${API_BASE_PP}/account/relatedAccounts`
export const EP_PP_CREDITMEMO_DOWNLOAD = (creditMemoId: string) =>
  `${API_BASE_PP}/creditmemo/${creditMemoId}/download`
export const EP_PP_DOCUMENTYPE_DOWNLOAD = (latestPdfId: string, filename: string) =>
  `${API_BASE_PP}/file/${latestPdfId}/${filename}/download`
//Selfhelp Payment Capture Page /CustomerPortal without authentication
export const API_BASE_PC = '/api/paymentCapture'

export const EP_PC_METHODS = `${API_BASE_PC}/methods`
export const EP_PC_PAYMENT = `${API_BASE_PC}/payment`
export const EP_PC_PAYMENTS_VALIDATE = `${API_BASE_PC}/payment/validate`

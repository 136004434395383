import { createApp } from 'vue'
import { createPinia } from 'pinia'
// TypeScript error? Run VSCode command
// TypeScript: Select TypeScript version - > Use Workspace Version
import App from './App.vue'
import router from './router'
import './index.css'
import {
  init as sentryInit,
  BrowserTracing as SentryBrowserTracing,
  Replay as SentryReplay,
  vueRouterInstrumentation as sentryVueRouterInstrumentation,
} from '@sentry/vue'
import {
  SENTRY_TRACES_SAMPLE_RATE,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_RELEASE,
  SENTRY_ENABLED,
} from '@gv/core'
import { BloomreachAdapter } from '../../core/src/lib/bloomreach/bloomreach'

const app = createApp(<any>App)

BloomreachAdapter().then((brPlugin: any) => {
  sentryInit({
    app,
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    tracesSampleRate: parseFloat(SENTRY_TRACES_SAMPLE_RATE),
    release: SENTRY_RELEASE,
    enabled: SENTRY_ENABLED == 'true',
    integrations: [
      new SentryBrowserTracing({
        routingInstrumentation: sentryVueRouterInstrumentation(router),
      }),
      new SentryReplay(),
    ],

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/azurewebsites\.com\/api/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.1,
  })
  app.use(router).use(brPlugin).use(createPinia()).mount('#app')
})

<template>
  <div class="w-full text-right mt-8">
    <SDialogBox :show="openModal" @close-modal="closeModal">
      <template v-slot:header>
        <p class="text-2xl font-bold">
          {{ bloomreach.getText('payment_type') }}
        </p>
      </template>

      <template v-slot:body>
        <div
          v-if="
            paymentTypeIsDD &&
            !directDebitConfirmation.confirmed &&
            locale === 'sv-SE'
          "
          class="text-left text-sm"
        >

          <p
            v-if="activePaymentMethod?.paymentMethodType === 'Credit card'"
            class="text-red-600 mb-3 text-lg font-bold">
            {{ bloomreach.getText('payment_change_confirmation') }}
          </p>

          <h1 class="text-4xl font-thin mb-8">
            {{ bloomreach.getText('direct_debit_detail') }}
          </h1>

          <p class="text-lg font-thin mb-8">
            {{ bloomreach.getText('direct_debit_invoice_details') }}
          </p>

          <h2 class="text-lg font-semibold mb-4">
            {{ bloomreach.getText('how_to_get_direct_debit') }}
          </h2>

          <h3 class="font-bold mb-2">
            {{ bloomreach.getText('login_to_bank') }}
          </h3>

          <p class="px-2 mb-2">
            {{ bloomreach.getText('find_customer_number') }}
          </p>

          <div class="bg-gray-200 mt-8 py-8 px-4">
            <h2 class="text-lg font-medium mb-2">
              {{ bloomreach.getText('good_to_know_about_direct_debit') }}
            </h2>

            <h3 class="font-bold">
              {{ bloomreach.getText('use_internet_bank') }}
            </h3>
            <p class="px-2 mb-2">
              {{ bloomreach.getText('use_internet_bank_details') }}
            </p>

            <h3 class="font-bold">
              {{ bloomreach.getText('no_form') }}
            </h3>

            <h3 class="font-bold">
              {{ bloomreach.getText('receiving_invoice') }}
            </h3>
            <p class="px-2 mb-2">
              {{ bloomreach.getText('receiving_invoice_details') }}
            </p>
          </div>
          <GVButton
            class="my-4 text-center"
            @click="choosePaymentType('Autogiro')"
          >
            {{ bloomreach.getText('confirm') }}
          </GVButton>
        </div>
        <div
          v-else-if="
            paymentTypeIsDD &&
            !directDebitConfirmation.confirmed &&
            locale === 'da-DK'
          "
          class="text-left text-sm"
        >
         <p v-if="activePaymentMethod?.paymentMethodType === 'Credit card'"
            class="text-red-600 mb-6 text-lg font-bold">
            {{ bloomreach.getText('payment_change_confirmation') }}
          </p>

          <h1 class="font-bold mb-3 text-xl">
            {{ bloomreach.getText('signup_for_betalingservice') }}
          </h1>

          <p class="mb-6 text-base">
            {{ bloomreach.getText('betalingservice_signup_details') }}
          </p>

          <!-- Temporarily removed since we're using the Link solution instead of API -->
          <!-- <h2 class="mb-2">{{ bloomreach.getText('enter_cpr_number') }}</h2>

          <div class="mb-2 md:w-1/3">
            <SInput
              class="text-sm"
              :name="'cprNumber'"
              placeholder="DDMMAAXXXX"
              v-model="cprNumber"
            />
          </div> -->

          <!-- <h2 class=" mb-2">Enter your account number</h2>

          <div class="mb-6 w-1/3">
            <SInput
              class="text-sm"
              :name="'bankAccountNumber'"
              placeholder="Reg.nr.-Kontonr."
              v-model="bankAccountNumber"
            />
          </div> -->

          <p class="mb-4 text-base">
            {{
              bloomreach.getText('when_you_press_signup_for_betalingservice')
            }}
          </p>
          <p class="mb-6 text-base">
            {{ bloomreach.getText('betalingservice_charge') }}
          </p>

          <GVButton
            class="my-4 text-center text-base"
            @click="choosePaymentType('Betalingsservice')"
            >{{ bloomreach.getText('signup_for_betalingservice') }}
          </GVButton>
        </div>
        <div v-else-if="paymentTypeIsDD && directDebitConfirmation.confirmed">
          <div v-if="isLoading">
            <p
              class="mb-4 text-center"
              v-if="directDebitConfirmation.type === 'Betalingsservice'"
            >
              {{ bloomreach.getText('waiting_to_approve_mandate') }}
            </p>
            <SLoading class="inset-1/2 -ml-8 mt-8 relative h-24 w-24" />
          </div>
          <p class="text-center my-4 px-2" v-else>
            {{ confirmationMessage }}
          </p>
        </div>
        <div v-else>
          <p class="text-sm text-left">
            {{ bloomreach.getText('choose_payment_type') }}
          </p>
          <div class="md:flex space-y-3 md:space-y-0 my-4">
            <GVButton
              v-if="
                !activePaymentMethod.mandateActive &&
                (!paymentMethodModalBtn ||
                  (paymentMethodModalBtn &&
                    paymentMethodModalBtn == 'DirectDebit'))
              "
              class="text-sm mr-2"
              @click="choosePaymentType('DirectDebit')"
            >
              {{ bloomreach.getText('direct_debit') }}
            </GVButton>
            <GVButton
              v-if="
                !paymentMethodModalBtn ||
                (paymentMethodModalBtn && paymentMethodModalBtn == 'CreditCard')
              "
              class="text-sm mr-2"
              @click="choosePaymentType('CreditCard')"
            >
              {{ bloomreach.getText('credit_card') }}
            </GVButton>
            <GVButton
              v-if="
                !paymentMethodModalBtn ||
                (paymentMethodModalBtn &&
                  paymentMethodModalBtn == 'ManualPayment')
              "
              class="text-sm mr-2"
              @click="choosePaymentType('ManualPayment')"
            >
              {{ bloomreach.getText('manual_payment') }}
            </GVButton>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end mt-8 border-t pt-10">
          <button
            variant="bare"
            class="text-sm text-primaryColor-dark hover:text-black"
            @click="closeModal"
          >
            {{ bloomreach.getText('cancel') }}
          </button>
        </div>
      </template>
    </SDialogBox>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, inject, watch } from 'vue'

import {
  GVButton,
  SDialogBox,
  SLoading,
  updatePaymentType,
  SInput,
  makeNetsPayment,
  deletePaymentMethods,
  bloomreachKey,
} from '@gv/core'

// import uuid from 'uuid'

export default defineComponent({
  name: 'GVPaymentTypeModal',
  components: {
    GVButton,
    SDialogBox,
    SLoading,
    SInput,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    activePaymentMethod: {
      type: Object,
      default: {},
    },
    paymentMethodModalBtn: {
      type: String,
      required: false,
    },
  },
  emits: ['updateModal', 'paymentType', 'setManualPayment', 'paymentChanged'],
  setup(props, { emit }) {
    const bloomreach = inject(bloomreachKey)!
    const userData = ref(JSON.parse(localStorage.getItem('userCache') || '{}'))
    const locale = ref(bloomreach.getLocale())
    const paymentTypeIsDD = ref(false)
    const directDebitConfirmation = ref({ confirmed: false, type: '' })
    const isLoading = ref(false)
    const confirmationMessage = ref('')

    const bankAccountNumber = ref('')
    const cprNumber = ref('')

    const closeModal = () => {
      confirmationMessage.value = ''
      directDebitConfirmation.value = { confirmed: false, type: '' }
      paymentTypeIsDD.value = false
      bankAccountNumber.value = ''
      cprNumber.value = ''
      emit('updateModal', false)
    }

    const updatePayment = async (option: string) => {
      directDebitConfirmation.value.confirmed = true
      directDebitConfirmation.value.type = option
      const { fetch, data } = updatePaymentType({
        paymentMethodType: option,
      })

      const { fetch: fetchDelete, data: dataDelete } = deletePaymentMethods()
      try {
        isLoading.value = true
        // First remove the previous payment method and then add the new one
        await fetchDelete()
        if (dataDelete) {
          await fetch()
          if (data) {
            confirmationMessage.value = `Succesfully updated to ${option} method.`
          }
        }
      } catch (error) {
        console.error(error)
        confirmationMessage.value = bloomreach.getText('request_error')
      } finally {
        isLoading.value = false
        setTimeout(() => {
          closeModal()
        }, 2000)
      }
    }

    const choosePaymentType = async (option: string) => {
      switch (option) {
        case 'DirectDebit':
          paymentTypeIsDD.value = true
          break
        case 'Autogiro':
          // updatePayment(option)
          closeModal()
          break
        case 'Betalingsservice':
          // Link solution, opens the URL and then closes the modal
          window.open(
            'https://www.betalingsservice.dk/DOB/BS?id=3&pbs=30037227&pbscheck=wcUONoJ27QswZBfscR%2B25Q%3D%3D&dbnr=&dbgr=00001&kundenrLabel=Kundenummer&version=2',
            '_blank'
          )
          closeModal()
          //? Temporarily remove the API Solution to use the Link Solution instead
          // directDebitConfirmation.value = { confirmed: true, type: option }
          // const payload = {
          //   uuid: uuid.v4(),
          //   creditorReference: `${userData.value.accountNumber}`,
          //   nationalId: cprNumber.value,
          //   productDescription: 'Synoptik GLS',
          //   productTitle: 'Synoptik GLS',
          // }
          // const { fetch, data } = makeNetsPayment(payload)
          // const {
          //   fetch: fetchDelete,
          //   data: dataDelete,
          // } = deletePaymentMethods()
          // try {
          //   isLoading.value = true
          //   // First remove the previous payment method and then add the new one
          //   await fetchDelete()
          //   if (dataDelete) {
          //     await fetch()
          //     if (
          //       data &&
          //       data.value.statusMandate?.statusCodeEnum ===
          //         'REJECTED_BY_DEBTOR'
          //     ) {
          //       confirmationMessage.value = bloomreach.getText(
          //         'mandate_rejected'
          //       )
          //     } else if (data) {
          //       confirmationMessage.value = bloomreach.getText(
          //         'payment_method_add_success_msg'
          //       )
          //     }
          //   }
          // } catch (error) {
          //   console.error(error)
          //   confirmationMessage.value = bloomreach.getText('request_error')
          // } finally {
          //   isLoading.value = false
          //   setTimeout(() => {
          //     closeModal()
          //   }, 2000)
          // }
          break
        case 'ManualPayment':
          emit('setManualPayment')
          closeModal()

          break
        default:
          emit('paymentType', option)
          break
      }

      emit('paymentChanged')
    }

    watch(
      () => props.openModal,
      (newData) => {
        if (props.paymentMethodModalBtn === 'DirectDebit') {
          paymentTypeIsDD.value = true
        }
      }
    )

    return {
      closeModal,
      choosePaymentType,
      paymentTypeIsDD,
      directDebitConfirmation,
      isLoading,
      confirmationMessage,
      locale,
      bankAccountNumber,
      cprNumber,
      bloomreach,
    }
  },
})
</script>

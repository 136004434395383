<template>
  <div class="tooltip-box">
    <div class="tooltip-icon">
      <slot />  
    </div>
    <div
      class="tooltip"
    >
      <span
        class="text"
      >{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'STooltip',
  props: {
      text: {
        type: String,
        required: true
      }
  },
}
</script>

<style>
.tooltip-box { 
  position: relative;
  display: inline-block;
}

.tooltip-icon:hover + .tooltip{
  opacity: 1;
}

.tooltip { 
  color: #000;
  text-align: center;
  padding: 15px 0;
  border-radius: 6px;
  
  width: 320px;
  bottom: 100%;
  left: 50%;
  margin-left: -160px;

  opacity: 0;
  transition: opacity 1s;


  position: absolute;
  z-index: 1;

  background: #eee;
}

.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -13px;
  border-width: 5px;
  border-style: solid;
  border-color: #eee transparent transparent transparent;
}
</style>


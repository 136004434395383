import { InjectionKey, Ref } from 'vue'
import { BloomreachPlugin } from '../lib/bloomreach/bloomreach'

export type Bloomreach = typeof BloomreachPlugin

export const bloomreachKey: InjectionKey<Bloomreach> = Symbol('$bloomreach')

export const accordionKey: InjectionKey<Ref<{
  count: number,
  active: number | null
}>> = Symbol('$accordion')

<template>
  <ul class="list-none m-0 p-0">
    <slot></slot>
  </ul>
</template>

<script lang="ts">
import { defineComponent, provide, ref } from 'vue'
import { accordionKey } from '@gv/core'

export default defineComponent({
  name: 'GVAccordion',
  setup() {
    const accordion = ref({
      count: 0,
      active: null,
    })
    provide(accordionKey, accordion)
  },
})
</script>

<style>
.accordion__item:last-child {
  border-bottom: none;
}
</style>

<template>
  <teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform delay-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="showModal"
        ref="modal-backdrop"
        class="fixed inset-0 overflow-y-auto bg-black bg-opacity-50 z-10"
      >
        <div
          class="flex items-start justify-end min-h-screen text-center"
          @click.self="$emit('closeModal')"
        >
          <transition
            enter-active-class="transition ease-out duration-200 transform "
            enter-from-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="ease-in duration-200 transform"
            leave-from-class="translate-x-0"
            leave-to-class="translate-x-full"
          >
            <div
              v-show="showModal"
              class="bg-white text-left overflow-hidden shadow-xl w-full lg:w-1/2 h-screen pl-10"
              role="dialog"
              ref="modal"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div class="flex justify-between items-center my-3 py-2 border-b">
                <slot name="header"
                  ><p class="text-2xl font-bold">Simple Modal</p>
                </slot>
              </div>
              <slot name="body"></slot>
              <slot name="footer">
                <div class="flex justify-end mt-20 border-t pt-10">
                  <button
                    class="px-4 bg-transparent p-3 rounded-lg text-gray-500 hover:bg-gray-100 hover:text-gray-400 mr-2"
                    @click="$emit('closeModal')"
                  >
                    Close
                  </button>
                  <button
                    class="modal-close px-4 text-green-500 p-3 rounded-lg hover:text-green-400"
                    @click="$emit('confirmModal')"
                  >
                    Save
                  </button>
                </div>
              </slot>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { ref, watch, defineComponent } from 'vue'
export default defineComponent({
  inheritAttrs: false,
  name: 'Ssidepane',
  emits: ['closeModal', 'confirmModal'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const showModal = ref(props.show)

    function closeModal() {
      showModal.value = false
    }

    watch(
      () => props.show,
      (show) => {
        showModal.value = show
      }
    )

    return {
      closeModal,
      showModal,
    }
  },
})
</script>

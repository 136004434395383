<template>
  <div class="overflow-auto">
    <table class="min-w-full divide-y divide-gray-200 table-auto border">
      <thead class="bg-gray-50">
        <tr v-if="paginatedTop" class="border-b">
          <td
            :colspan="tableHeaders.length"
            :class="[alingPaginationLeft ? 'text-left' : 'text-right']"
            class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            <SPagination
              :tablePage="tablePage"
              :limitPerPage="tableLimitPerPage"
              :tableLength="data.length"
              :showGotoPage="true"
              @next-page="nextPage"
              @previous-page="previousPage"
              @gotoPage="gotoPage"
            />
          </td>
        </tr>
        <th
          v-for="(header, headerKey) in tableHeaders"
          :key="headerKey"
          scope="col"
          class="pl-6 pr-2 py-3 text-xs font-bold text-black uppercase tracking-wider text-left"
          :class="[
            sortKey == header.field ? 'text-secondaryColor-medium' : '',
            header.currency ? 'text-right' : '',
          ]"
          @click="sortByKey(header.field)"
        >
          <div class="flex items-center">
            <span>{{ header.name }}</span>
            <div class="w-4 ml-3">
              <button
                v-show="sortKey == header.field && orderAsc"
                class="text-base"
              >
                &uarr;
              </button>
              <button
                v-show="sortKey == header.field && !orderAsc"
                class="text-base"
              >
                &darr;
              </button>
            </div>
          </div>
        </th>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr
          v-for="(row, rowKey) in tableData"
          :key="rowKey"
          :class="[
            row.rowClass ? row['rowClass'] : 'bg-white text-gray-700',
            [rowClickable ? 'cursor-pointer' : 'cursor-auto'],
          ]"
          :title = "row.title"
          class="hover:bg-gray-50 text-gray-700 hover:text-secondaryColor-medium whitespace-nowrap"
          @click="$emit('rowClick', row, rowKey)"
        >
          <slot :row="row" :index="rowKey">
            <td
              v-for="header in tableHeaders"
              :key="header.field"
              class="px-6 py-4"
              scope="row"
              @click="$emit('fieldClick', header.field, row)"
            >
              <div
                v-if="header.input"
                :class="[
                  header.extraClass ? row['_class'] : 'text-sm',
                  header.currency ? 'text-right' : '',
                ]"
              >
                <input
                  v-model="row[header.field]"
                  :type="header.inputType"
                  :disabled="row[header.disabled]"
                />
              </div>
              <div
                v-else-if="header.field === 'actions'"
                class="flex text-sm"
                :class="{ 'justify-end': !alingActionsLeft }"
              >
                <slot name="actions" :row="row" :index="rowKey">
                  <div v-for="(action, index) in row.actions" :key="index">
                    <GVButton
                      v-if="!action.condition || action.condition(row)"
                      @click.stop="callActionFunction(action, row, rowKey)"
                      class="border flex items-center bg-white whitespace-nowrap disabled:cursor-not-allowed text-secondaryColor-medium hover:text-primaryColor-medium outline-none focus:outline-none"
                      :class="{ 'ml-5': index > 0 }"
                      :disabled="
                        action.disabled?.(row) ||
                        (indexClicked === rowKey && action.isProcessing)
                      "
                    >
                      <span>{{ action.content }} </span>
                      <SLoading
                        v-if="indexClicked === rowKey && action.isProcessing"
                        class="h-7 w-7 ml-2"
                      />
                    </GVButton>
                  </div>
                </slot>
              </div>
              <div v-else-if="header.hasTooltip">
                <div class="relative hasTooltip text-sm">
                  <p class="flex">
                    {{ row[header.field] }}
                    <SInfoIcon v-if="row[header.tooltipField]" class="ml-1" />
                  </p>
                  <span
                    v-if="row[header.tooltipField]"
                    class="hidden text-black items-center p-3 rounded bg-gray-50 shadow-lg top-6"
                  >
                    {{ row[header.tooltipField] }}
                  </span>
                </div>
              </div>
              <template v-else-if="$slots[header.field]">
                <slot :name="header.field" :row="row" :index="rowKey"
                  >Row {{ rowKey }} {{ header.field }}</slot
                >
              </template>
              <div
                v-else
                :class="[
                  header.extraClass ? row['_class'] : 'text-sm',
                  header.currency ? 'text-right' : '',
                ]"
              >
                {{ row[header.field] }}
              </div>
            </td>
          </slot>
        </tr>
      </tbody>
      <tfoot v-if="paginated" class="bg-gray-50">
        <tr>
          <td
            :colspan="tableHeaders.length"
            :class="[alingPaginationLeft ? 'text-left' : 'text-right']"
            class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            <SPagination
              :tablePage="tablePage"
              :limitPerPage="tableLimitPerPage"
              :tableLength="data.length"
              :showGotoPage="true"
              @next-page="nextPage"
              @previous-page="previousPage"
              @gotoPage="gotoPage"
            />
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, PropType, computed, watch } from 'vue'
import draggable from 'vuedraggable'
import {
  GVButton,
  parsedCurrencyToFloat,
  SLoading,
  SInfoIcon,
  SPagination,
} from '@gv/core'

export default defineComponent({
  name: 'STable',
  emits: ['rowClick, fieldClick'],
  components: { SPagination, draggable, GVButton, SLoading, SInfoIcon },
  props: {
    headers: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    onRowClick: {
      type: Function,
    },
    onFieldClick: {
      type: Function,
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    limitPerPage: {
      type: Number,
      default: 10,
    },
    actions: {
      type: Object,
      default: {},
    },
    actionsTitle: {
      type: String,
      default: 'Actions',
    },
    alingActionsLeft: {
      type: Boolean,
      default: false,
    },
    rowClickable: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    alingPaginationLeft: {
      type: Boolean,
      default: false,
    },
    paginatedTop: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const inserActionsOnTable = function () {
      // Checks if tableData has actions injected
      const tdHasActions =
        tableData &&
        tableData.value.filter((data) => {
          return data.actions !== undefined
        }).length > 0
      // Checks if tableHeaders has actions injected
      const thHasActions =
        tableHeaders &&
        tableHeaders.value.filter((headers) => {
          return headers.field === 'actions'
        }).length > 0

      if (Object.keys(props.actions).length > 0) {
        if (!thHasActions)
          if (props.alingActionsLeft) {
            tableHeaders.value.unshift({
              name: props.actionsTitle,
              field: 'actions',
            })
          } else {
            tableHeaders.value.push({
              name: props.actionsTitle,
              field: 'actions',
            })
          }

        if (!tdHasActions)
          tableData.value.forEach((insideData) => {
            insideData.actions = props.actions
          })
      }
    }
    let tablePage = ref(1)
    let tableLimitPerPage = ref(props.limitPerPage)
    let pageLimit = computed(() =>
      Math.ceil(props.data.length / tableLimitPerPage.value)
    )
    let tableHeaders = ref(props.headers)
    let tableData = ref(
      props.paginated
        ? props.data.slice(0, tableLimitPerPage.value)
        : props.data
    )
    watch(
      () => props.data,
      () => {
        tablePage.value = 1
        tableData.value = props.paginated ? props.data.slice(0, tableLimitPerPage.value) : props.data
      }
    )
    let sortKey = ref('')
    let orderAsc = ref(true)
    let selectedOptions = ref([])
    inserActionsOnTable()

    let indexClicked = ref(0)

    const updateTableData = () => {
      if (props.paginated) {
        const startIndex = (tablePage.value - 1) * tableLimitPerPage.value
        const endIndex = tablePage.value * tableLimitPerPage.value
        tableData.value = props.data.slice(startIndex, endIndex)
      } else {
        tableData.value = props.data
      }
      inserActionsOnTable()
    }

    const sortByKey = (selectedSortKey) => {
      // Checks if the order is Ascending or Descending
      orderAsc.value =
        selectedSortKey === sortKey.value ? !orderAsc.value : true
      // If the order is Descending invert the logic for the sort
      const reverse = orderAsc.value ? 1 : -1
      // Updates the sortKey to the new one
      sortKey.value = selectedSortKey
      // Sorts the pristine data array
      props.data.sort((dataX, dataY) => {
        const compareKey = selectedSortKey.toLowerCase()
        // Date sorting
        if (compareKey.includes('date')) {
          let dateX, dateY
          // Change the Date separator from - to / and splitting
          const splittedDateX = dataX[selectedSortKey]
            .replaceAll('-', '/')
            .split('/')
          const splittedDateY = dataY[selectedSortKey]
            .replaceAll('-', '/')
            .split('/')
          // If first part of date is of length 2, means that date is formatted as DD/MM/YYYY, so reverst to YYYYMMDD for comparison
          if (splittedDateX[0].length === 2) {
            dateX = splittedDateX.reverse().join()
            dateY = splittedDateY.reverse().join()
            // If first part of date is not length 2, means that date is formatted as YYYY/MM/DD, so just join into YYYYMMDD for comparison
          } else {
            dateX = splittedDateX.join()
            dateY = splittedDateY.join()
          }
          return dateX < dateY ? -1 * reverse : dateX > dateY ? 1 * reverse : 0
        }
        // Currency Sorting
        else if (
          compareKey.includes('amount') ||
          compareKey.includes('balance') ||
          compareKey.includes('price')
        ) {
          const amountX = parsedCurrencyToFloat(dataX[selectedSortKey])
          const amountY = parsedCurrencyToFloat(dataY[selectedSortKey])

          return amountX < amountY
            ? -1 * reverse
            : amountX > amountY
            ? 1 * reverse
            : 0
        }
        // General sorting
        else if (dataX[selectedSortKey] < dataY[selectedSortKey]) {
          return -1 * reverse
        } else if (dataX[selectedSortKey] > dataY[selectedSortKey]) {
          return 1 * reverse
        } else {
          return 0
        }
      })
      // Updates the tableData with the new sorted data
      updateTableData()
    }

    const callActionFunction = (action, row, index: number) => {
      indexClicked.value = index
      action.onClick(row, index)
    }

    const nextPage = () => {
      if (tablePage.value < pageLimit.value) {
        tablePage.value++
      }
    }

    const previousPage = () => {
      if (tablePage.value > 1) {
        tablePage.value--
      }
    }

    const gotoPage = (goto: number) => {
      if (goto <= pageLimit.value) {
        tablePage.value = goto
      }
    }

    return {
      sortByKey,
      tableHeaders,
      tableData,
      sortKey,
      orderAsc,
      tablePage,
      tableLimitPerPage,
      nextPage,
      previousPage,
      updateTableData,
      selectedOptions,
      indexClicked,
      callActionFunction,
      gotoPage,
    }
  },
  watch: {
    data() {
      this.updateTableData()
    },
    tablePage() {
      this.updateTableData()
    },
  },
  methods: {
    selectedRow(option) {
      this.$emit('selectedOptions', option.target.value)
    },
  },
})
</script>

<style scoped>
.hasTooltip:hover span {
  display: block;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 2px 10px;
  z-index: 1;
}
</style>

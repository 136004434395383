<template>
  <div class="w-full text-right my-8">
    <SDialogBox :show="openModal" @close-modal="closeModal">
      <template v-slot:header>
        <p class="text-2xl font-bold">
          {{
            Object.keys(invoice).length
              ? `${bloomreach.getText('invoice_payment_title')} ${
                  invoice.number
                }`
              : ''
          }}
        </p>
      </template>
      <template v-slot:body>
        <div v-if="paymentOption === 'newPaymentMethod'">
          <div class="flex justify-center">
            <div class="mt-2 pt-2">
              <SCheckbox
                class="text-sm"
                :name="'recurringPayment'"
                v-model="recurringPayment"
                :disabled="true"
              />
            </div>
            <div class="mt-2 p-2 block text-sm font-medium text-gray-700">
              {{ getText('recurring_payment_method_agreement_msg') }}
            </div>
          </div>
          <div class="flex justify-center">
            <div v-if="isLoading">
              <SLoading class="inset-1/2 -ml-8 mt-8 relative h-24 w-24" />
            </div>
            <div id="dropin-container"></div>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-center">
            <div v-if="loadingDefaultPayment">
              <SLoading class="inset-1/2 -ml-8 mt-8 relative h-24 w-24" />
            </div>
            <div
              v-else-if="
                defaultPaymentMessage && defaultPaymentMessage.length > 0
              "
            >
              {{ defaultPaymentMessage }}
            </div>
            <div v-else>Something went wrong.</div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end mt-20 border-t pt-10">
          <button
            variant="bare"
            class="text-sm text-primaryColor-dark hover:text-black"
            @click="closeModal"
          >
            {{ getText('cancel') }}
          </button>
        </div>
      </template>
    </SDialogBox>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onUpdated, inject } from 'vue'
import {
  SDialogBox,
  SCheckbox,
  PAYMENT_CLIENT_KEY,
  getPaymentMethods,
  makePayment,
  SLoading,
  makePaymentWithDefault,
  ENVIRONMENT,
  getBrowserInfo,
  bloomreachKey,
} from '@gv/core'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'

export default defineComponent({
  name: 'GVPaymentModal',
  components: {
    SDialogBox,
    SCheckbox,
    SLoading,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    paymentOption: {
      type: String,
      default: '',
    },
    invoice: {
      type: Object,
      default: {},
    },
    hasDefaultPaymentMethod: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateModal', 'paymentSuccessful'],
  setup(props, { emit }) {
    const recurringPayment = ref(true)
    const isLoading = ref(false)
    const isSubmitting = ref(false)
    const openPaymentModal = ref(false)
    const defaultPaymentMessage = ref('')
    const loadingDefaultPayment = ref(false)
    let adyenDropin = undefined

    const bloomreach = inject(bloomreachKey)
    const getText = (key: string) => bloomreach.getText(key)

    const closeModal = () => {
      if (adyenDropin) adyenDropin.unmount()
      emit('updateModal', false)
    }

    const {
      fetch: fetchPaymentMethods,
      data: dataPaymentMethods,
    } = getPaymentMethods()

    const openPaymentWindow = async (option: string) => {
      if (option === 'newPaymentMethod') {
        isLoading.value = true
        const clientKey = PAYMENT_CLIENT_KEY
        const env = ENVIRONMENT == 'PROD' ? 'live' : 'test'
        let configuration = {
          paymentMethodsResponse: { paymentMethods: [] },
          clientKey: clientKey, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
          locale: bloomreach.getLocale(),
          // TODO: change environment when releasing the system
          environment: env,
          paymentMethodsConfiguration: {
            card: {
              // Example optional configuration for Cards
              hasHolderName: true,
              holderNameRequired: true,
              enableStoreDetails: false,
            },
          },
          allowPaymentMethods: ['scheme'],
          onSubmit: (state, dropin) => {
            dropin.setStatus('loading')
          },
          onAdditionalDetails: (state, dropin) => {
            console.log('onAdditionalDetails')
          },
          onError: (error) => {
            console.log('error: ', error)
          },
        }

        fetchPaymentMethods()

        watch(dataPaymentMethods, (newData) => {
          if (newData !== null) {
            configuration.paymentMethodsResponse = { paymentMethods: newData }

            configuration.onSubmit = (state, dropin) => {
              dropin.setStatus('loading')
              isSubmitting.value = true
              const paymentData = {
                card: {
                  type: state.data.paymentMethod.type,
                  cardNumber: state.data.paymentMethod.encryptedCardNumber,
                  expiryMonth: state.data.paymentMethod.encryptedExpiryMonth,
                  expiryYear: state.data.paymentMethod.encryptedExpiryYear,
                  name: state.data.paymentMethod.holderName,
                  securityCode: state.data.paymentMethod.encryptedSecurityCode,
                },
                invoiceId: props.invoice.id,
                recurringPaymentMethod: recurringPayment.value,
                browserInformation: getBrowserInfo(),
                returnUrl: `${window.location.origin}/payment-callback`,
                origin: `${window.location.origin}`,
                goBackUrl: `${window.location.pathname}`,
              }
              const {
                fetch: fetchPostPayments,
                data: dataPostPayments,
              } = makePayment(paymentData)
              fetchPostPayments()
                .then(() => {
                  isSubmitting.value = false
                  if (dataPostPayments.value.resultCode === 'Authorised') {
                    dropin.setStatus('success')
                    setTimeout(() => {
                      dropin.setStatus('ready')
                      emit('paymentSuccessful', true)
                      closeModal()
                    }, 2500)
                  } else if (
                    dataPostPayments.value.resultCode === 'RedirectShopper' &&
                    dataPostPayments.value.action
                  ) {
                    const data = {
                      MD: dataPostPayments.value.action.data.md,
                      PaReq: dataPostPayments.value.action.data.paReq,
                      TermUrl: dataPostPayments.value.action.data.termUrl,
                    }

                    delete dataPostPayments.value.action.data
                    dataPostPayments.value.action.data = data
                    dropin.handleAction(dataPostPayments.value.action)
                  } else {
                    throw dataPostPayments.value.resultCode
                  }
                  // if (response.action) {
                  //   // Drop-in handles the action object from the /payments response
                  //   dropin.handleAction(response.action);
                  // } else {
                  //   // Your function to show the final result to the shopper
                  //   showFinalResult(response);
                  // }
                })
                .catch((error) => {
                  isSubmitting.value = false
                  dropin.setStatus('error')
                  setTimeout(() => {
                    dropin.setStatus('ready')
                    emit('paymentSuccessful', false)
                    closeModal()
                  }, 2500)
                  throw Error(error)
                })
            }

            const checkout = new AdyenCheckout(configuration)

            adyenDropin = checkout.create('dropin', {
              showPayButton: true,
            })

            adyenDropin.mount('#dropin-container')
            isLoading.value = false
          }
        })
      }

      if (option === 'payWithDefault') {
        const { fetch: makeDefaultPayment } = makePaymentWithDefault({
          invoiceId: props.invoice.id,
        })
        loadingDefaultPayment.value = true
        try {
          await makeDefaultPayment()
          loadingDefaultPayment.value = false
          defaultPaymentMessage.value = 'Payment successful.'
          emit('paymentSuccessful', true)
        } catch {
          loadingDefaultPayment.value = false
          defaultPaymentMessage.value = 'Payment Error.'
          emit('paymentSuccessful', false)
        } finally {
          setTimeout(() => {
            closeModal()
          }, 2500)
        }
      }
    }

    onUpdated(() => {
      if (props.openModal && props.paymentOption.length > 0) {
        openPaymentWindow(props.paymentOption)
        // recurringPayment.value = !props.hasDefaultPaymentMethod
      }
    })

    return {
      bloomreach,
      recurringPayment,
      isLoading,
      isSubmitting,
      openPaymentModal,
      closeModal,
      defaultPaymentMessage,
      loadingDefaultPayment,
      getText,
    }
  },
})
</script>

<style>
.adyen-checkout__payment-method,
.adyen-checkout__button,
.adyen-checkout__field,
.adyen-checkout__input {
  border-radius: 0px !important;
}
</style>

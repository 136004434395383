<template>
  <div
    class="mx-auto flex justify-center lg:items-center h-full min-h-screen loginbg bg-gray-600"
  >
    <div class="w-full container sm:w-4/6 md:w-3/6 lg:w-4/12 xl:w-3/12">
      <div
        v-if="loginError.msg || loginError.header"
        :class="[zuoraAccountIsPresent ? 'bg-red-500' : 'bg-black']"
        class="w-full py-3 px-4 mb-4 rounded text-white"
      >
        <h2 v-if="loginError.header" class="font-bold mb-2">
          {{ loginError.header }}
        </h2>
        <p v-if="loginError.msg">{{ loginError.msg }}</p>
      </div>
      <form
        id="login"
        class="w-full text-gray-800 py-12 sm:my-4 px-20 sm:px-2 bg-gray-200 sm:bg-opacity-60 rounded-lg shadow-md transition-all duration-500 ease-in-out"
        @submit.prevent="onSubmit"
      >
        <div class="pt-0 px-2 flex flex-col items-center justify-center">
          <SynoptikLogo />
          <h2 class="md:text-4xl text-3xl text-center leading-tight pt-8">
            {{ getText('customer_care_portal') }}
          </h2>
        </div>
        <div class="mt-12 w-full px-2 sm:px-6">
          <transition
            enter-active-class="transition ease-in-out duration-500 delay-500"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in-out duration-500"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            mode="out-in"
          >
            <div v-if="isLoading">
              <SLoading class="mt-8 relative h-12 w-12 m-auto" />
            </div>
            <div v-else>
              <div class="pt-5 px-2 flex flex-col items-center justify-center">
                <h3 class="text-xl leading-tight text-gray-800 text-center">
                  {{ getText('login_to_continue') }}
                </h3>
              </div>
              <!-- <div>
              Client Secret:
              <SInput
                class="text-sm my-2"
                :name="'clientSecret'"
                v-model="clientSecret"
              />
            </div>
            <div>
              {{ getText('identity_id') }}
              <SInput
                class="text-sm my-2"
                :name="'identityId'"
                v-model="identityId"
              />
            </div>
            <div>
              {{ getText('account_number') }}
              <SInput
                class="text-sm my-2"
                :name="'accountNumber'"
                v-model="accountNumber"
              />
            </div> -->
              <div class="mt-5 pb-10 text-center">
                <SButton
                  variant="bare"
                  type="button"
                  @click="authUser"
                  class="focus:outline-none bg-primaryColor-medium text-white transition duration-150 ease-in-out hover:bg-gray-200 rounded hover:text-gray-800 px-8 py-3 text-sm mt-6"
                >
                  {{ getText('login') }}
                </SButton>
              </div>
            </div>
          </transition>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, inject } from 'vue'
import {
  SButton,
  SInput,
  SLogin,
  SLoading,
  getAccountOverview,
  setAccessToken,
  getCustomerIdentityToken,
  ENVIRONMENT,
  bloomreachKey,
} from '@gv/core'
import SynoptikLogo from '../assets/SynoptikLogo.svg'
import { useRouter, useRoute } from 'vue-router'

import { useAccountDataStore } from '../stores/accountData'
import { usePaymentsDataStore } from '../stores/paymentsData'

interface StartNewTransactionResponse {
  accessUrl: string,
}

export default defineComponent({
  name: 'Login',
  components: { SButton, SInput, SLogin, SynoptikLogo, SLoading },
  setup() {
    const router = useRouter(),
      route = useRoute(),
      bloomreach = inject(bloomreachKey),
      getText = (key: string) => bloomreach.getText(key),
      isLoading = ref(false),
      clientSecret = ref(''),
      loginError = ref({
        header: '',
        msg: '',
      }),
      zuoraAccountIsPresent = ref(true),
      identityId = ref(''),
      accountNumber = ref(''),
      accountDataStore = useAccountDataStore(),
      paymentsDataStore = usePaymentsDataStore(),
      onSubmit = (e) => {
        e.preventDefault()
      },
      userLoginWithStrive = async () => {
        const redirectUrl = `${window.location.origin}/auth-callback`
        const { fetch, data } = getCustomerIdentityToken(redirectUrl)
        
        try {
          await fetch()

          if (!data || (data && !data.value)) return;

          const response = data.value as StartNewTransactionResponse
          // Redirect to access URL
          window.location.href = response.accessUrl
        } catch (error) {
          loginError.value.msg = bloomreach.getText('login_error_msg')
        }
      },
      authUser = async () => {
        //Auth user here?
        loginError.value.msg = ''
        loginError.value.header = ''
        zuoraAccountIsPresent.value = true
        isLoading.value = true
        animateBoxHeight(550)
        //Auth the user
        await userLoginWithStrive()
      },
      animateBoxHeight = (timeout) => {
        setTimeout(() => {
          let form = document.getElementById('login')
          let height = 150
          form.children.forEach((child) => {
            height = height + child.offsetHeight
          })
          form.style.height = height + 'px'
        }, timeout)
      }


    function processError() {
      const error = route.query['error']?.toString()

      if (error == 'no_zuora_account') {
        loginError.value.header = bloomreach.getText('preparing_my_account_header')
        loginError.value.msg = bloomreach.getText('preparing_my_account_msg')
      } else if (error == 'failed') {
        loginError.value.msg = bloomreach.getText('login_error_msg')
      }
    }

    onMounted(() => {    
      animateBoxHeight(100)
    })

    processError()
    // Hack to wait for bloomreach to load. If you find a better way, please improve this
    watch(
      () => bloomreach?.getText('preparing_my_account_header'), 
      () => processError()
    )

    return {
      onSubmit,
      isLoading,
      authUser,
      clientSecret,
      identityId,
      accountNumber,
      loginError,
      getText,
      zuoraAccountIsPresent,
    }
  },
})
</script>
<style scoped>
.loginbg {
  background: #ffffff; /*  url('../assets/loginbg.jpg'); */
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}
.loginbg form {
  /* Not supported in all browsers. But for those that support it, it looks nice :)  */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
</style>

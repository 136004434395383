<template>
  <GVCard :skeletonLoader="loading">
    <template v-slot:title>
      <h1 class="text-lg">{{ getText('payment_method') }}</h1>
      <div
        v-if="hasClosingArrow"
        class="absolute top-6 right-6 transform -rotate-90"
        @click="closePaymentDefaultCard"
      >
        <RightArrowIcon />
      </div>
    </template>
    <template v-slot:body class="pb-0">
      <div
        class="md:grid md:grid-flow-col w-full"
        :class="{
          'md:grid-cols-4': paymentMethodTabs.length === 4,
          'md:grid-cols-3': paymentMethodTabs.length === 3,
          'md:grid-cols-2': paymentMethodTabs.length === 2,
        }"
        v-if="
          dataDefaultPaymentMethod &&
          Object.keys(dataDefaultPaymentMethod).length > 0
        "
      >
        <div
          v-for="tab in paymentMethodTabs"
          :key="tab.id"
          class="p-4 border-b md:border-0 cursor-pointer"
          :class="tab.active ? 'bg-blue-100' : 'bg-gray-100'"
          @click="changeActiveTab(tab.id)"
        >
          <h1
            class="text-lg font-normal"
            :class="{ 'text-gray-500': !tab.active }"
          >
            {{ tab.title }}
          </h1>
          <h3
            v-if="tab.subtitle"
            class="text-sm font-light"
            :class="{ 'text-gray-500': !tab.active }"
          >
            {{ tab.subtitle }}
          </h3>
        </div>
      </div>
      <!-- Div to show if its Credit Card -->
      <div
        v-if="isDefaultCreditcard && paymentMethodTabs[0].active"
        class="p-4 w-full bg-blue-100 text-base text-left h-auto"
      >
        <div
          v-if="
            !(
              paymentMethodType === 'Autogiro' ||
              paymentMethodType === 'Betalingsservice'
            ) &&
            dataDefaultPaymentMethod.defaultPaymentMethod &&
            dataDefaultPaymentMethod.creditCardData &&
            dataDefaultPaymentMethod.creditCardData.id
          "
          class="p-6 bg-white h-auto"
        >
          <div v-if="dataDefaultPaymentMethod.creditCardData.cardType">
            <div class="font-medium text-left">
              {{ getText('type') }}
            </div>
            <div class="break-words font-normal pb-3">
              {{ dataDefaultPaymentMethod.creditCardData.cardType }}
            </div>
          </div>
          <div class="font-medium text-left">
            {{ getText('card_holder_name') }}
          </div>
          <div class="break-words font-normal pb-3">
            {{ dataDefaultPaymentMethod.creditCardData.cardHolderName }}
          </div>
          <div class="font-medium text-left">
            {{ getText('card_number') }}
          </div>
          <div class="break-words font-normal pb-3">
            {{ dataDefaultPaymentMethod.creditCardData.cardNumber }}
          </div>
          <div class="font-medium text-left">
            {{ getText('expiration_date') }}
          </div>
          <div class="break-words font-normal pb-3">
            {{ dataDefaultPaymentMethod.creditCardData.expirationMonth }}/{{
              dataDefaultPaymentMethod.creditCardData.expirationYear
            }}
          </div>

          <GVButton
            class="text-sm mt-4 outline-none focus:outline-none"
            @click="$emit('addNewPaymentMethod', 'CreditCard')"
          >
            {{ bloomreach.getText('change_payment_card') }}
          </GVButton>
        </div>
        <div v-else class="p-6 bg-white h-full">
          <p class="font-normal text-base mb-4">
            {{ bloomreach.getText('recurring_cc_text_one') }}
          </p>
          <p class="mb-4 font-normal text-base">
            {{ bloomreach.getText('recurring_cc_text_two') }}
          </p>

          <p class="mb-4 font-normal text-base">
            {{ bloomreach.getText('recurring_cc_text_registered') }}
            {{ profileData?.billAddress?.email }}
            {{ bloomreach.getText('recurring_cc_text_registered_cont') }}
            <router-link class="text-primaryColor-medium" to="/account-details"
              >{{ bloomreach.getText('my_profile') }}
            </router-link>
          </p>

          <GVButton
            class="text-sm mr-2 mt-5 outline-none focus:outline-none"
            @click="$emit('addNewPaymentMethod', 'CreditCard')"
          >
            {{ bloomreach.getText('sign_up_for_recurring_card_payment') }}
          </GVButton>
        </div>
      </div>
      <!-- Div to show if its Autogiro or Betalingsservice -->
      <div
        v-if="
          isDefaultCreditcard
            ? paymentMethodTabs[1].active
            : paymentMethodTabs[0].active
        "
        class="p-4 w-full bg-blue-100 text-sm text-left h-auto"
      >
        <div
          v-if="
            (paymentMethodType === 'Autogiro' ||
              paymentMethodType === 'Betalingsservice') &&
            dataDefaultPaymentMethod.defaultPaymentMethod
          "
          class="p-6 bg-white h-72"
        >
          <p class="mb-3 font-normal text-base">
            {{ bloomreach.getText('autogiro_text_one') }}
          </p>
          <!-- <p v-if="isMandateRequested" class="text-sm text-left bg">
            {{ getText('mandate_approval_in_progress') }}
          </p>
          <div
            v-else-if="accountData?.mandateActive === 'Yes'"
            class="text-sm text-left mt-8"
          >
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('bank_account_number') }}
            </div>
            <div class="break-words">
              {{ dataDefaultPaymentMethod.mandateData.bankAccountNumber }}
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('mandate_active') }}
            </div>
            <div class="break-words">
              {{ dataDefaultPaymentMethod.mandateData.mandateActive }}
            </div>
            <div class="font-bold text-left text-primaryColor-medium">
              {{ getText('mandate_start_date') }}
            </div>
            <div class="break-words">
              {{
                formatDate(
                  dataDefaultPaymentMethod.mandateData.mandateStartDate,
                  bloomreach
                )
              }}
            </div>
            <div
              v-if="
                dataDefaultPaymentMethod.paymentMethodType === 'Autogiro' &&
                showDirectDebitMsg
              "
              class="break-words"
            >
              {{ getText('payment_collected_with_autogiro_msg') }}
            </div>
            <div
              v-if="
                dataDefaultPaymentMethod.paymentMethodType ===
                  'Betalingsservice' && showDirectDebitMsg
              "
              class="break-words"
            >
              {{ getText('payment_collected_with_betalingsservice_msg') }}
            </div>
          </div> -->
        </div>
        <div v-else class="p-6 bg-white h-full">
          <div class="">
            <p class="mb-5 font-normal text-base">
              {{ bloomreach.getText('autogiro_text_one') }}
            </p>
            <!-- <p class="font-normal text-base mb-5">
              {{ bloomreach.getText('confirm_payment_change') }}
            </p> -->

            <GVButton
              v-if="bloomreach.getLocale() !== 'sv-SE'"
              class="text-sm outline-none focus:outline-none"
              @click="$emit('addNewPaymentMethod', 'DirectDebit')"
            >
              {{ bloomreach.getText('subscribe_to_payment_service') }}
            </GVButton>
          </div>

          <!-- <div>
            <p class="font-bold text-base mb-1">
              {{ bloomreach.getText('alternative') }}
            </p>
            <p class="mb-3 font-normal text-base">
              <span class="font-bold"
                >{{ bloomreach.getText('save_fee') }}:
              </span>
              {{ bloomreach.getText('register_for_self_service') }}
            </p>

            <GVButton
              class="text-sm my-1 outline-none focus:outline-none"
              @click="$emit('addNewPaymentMethod', 'CreditCard')"
            >
              {{ bloomreach.getText('sign_up_for_recurring_card_payment') }}
            </GVButton>
          </div> -->
        </div>
      </div>
      <!-- Div to show if its Manual Payment -->
      <div
        v-else-if="
          isDefaultCreditcard
            ? paymentMethodTabs[2].active
            : paymentMethodTabs[1].active
        "
        class="p-4 w-full bg-blue-100 text-sm text-left h-auto"
      >
        <div
          v-if="!dataDefaultPaymentMethod.paymentMethodType"
          class="p-6 bg-white h-full"
        >
          <!-- <h2
            v-if="
              bloomreach.getText('manual_payment_active_title').length > 0 &&
              bloomreach.getText('manual_payment_active_title') !==
                'manual_payment_active_title'
            "
            class="font-medium text-xl text-left py-2"
          >
            {{ bloomreach.getText('manual_payment_active_title') }}
          </h2>
          <h4 class="font-normal text-lg text-left py-1">
            {{
              isMandateRequested
                ? getText('mandate_approval_in_progress')
                : getText('no_default_payment')
            }}
          </h4>
          <p
            v-if="
              bloomreach.getText('manual_payment_active_text').length > 0 &&
              bloomreach.getText('manual_payment_active_text') !==
                'manual_payment_active_text'
            "
            class="font-normal text-base text-left py-1"
          >
            {{ bloomreach.getText('manual_payment_active_text') }}
          </p>
          <GVButton
            v-if="hasButtons"
            class="flex text-sm my-4"
            @click="openPaymentWindow('newPaymentMethod')"
            >{{ getText('add_new_payment_method') }}</GVButton
          > -->

          <p class="my-3 font-normal text-base">
            {{ bloomreach.getText('manual_payment_text_one') }}
          </p>
        </div>
        <div v-else class="p-6 bg-white h-full">
          <p class="mb-5 font-normal text-base">
            {{ bloomreach.getText('manual_payment_text_one') }}
          </p>

          <GVButton class="text-sm mr-2" @click="$emit('setManualPayment')">
            {{ bloomreach.getText('change_to_manual_payment') }}
          </GVButton>
          <!-- CREDIT CARD REMOVAL -->
          <!-- <div>
            <p class="font-bold text-base mb-1 uppercase">
              {{ bloomreach.getText('alternative') }}
            </p>
            <p class="mb-3 font-normal text-base">
              <span class="font-bold"
                >{{ bloomreach.getText('save_fee') }}:</span
              >
              {{ bloomreach.getText('pay_your_subscription') }}
            </p>

            <p class="mb-5 font-normal text-base">
              {{ bloomreach.getText('manual_payment_text_two') }}
            </p>

            <GVButton
              class="text-sm my-1 outline-none focus:outline-none"
              @click="$emit('addNewPaymentMethod', 'CreditCard')"
            >
              {{ bloomreach.getText('sign_up_for_recurring_card_payment') }}
            </GVButton>
          </div> -->
          <!-- <h2
            v-if="
              bloomreach.getText('manual_payment_unactive_title').length > 0 &&
              bloomreach.getText('manual_payment_unactive_title') !==
                'manual_payment_unactive_title'
            "
            class="font-medium text-xl text-left py-2"
          >
            {{ bloomreach.getText('manual_payment_unactive_title') }}
          </h2>
          <h4
            v-if="
              bloomreach.getText('manual_payment_unactive_subtitle').length >
                0 &&
              bloomreach.getText('manual_payment_unactive_subtitle') !==
                'manual_payment_unactive_subtitle'
            "
            class="font-normal text-lg text-left py-1"
          >
            {{ bloomreach.getText('manual_payment_unactive_subtitle') }}
          </h4>
          <p
            v-if="
              bloomreach.getText('manual_payment_unactive_text').length > 0 &&
              bloomreach.getText('manual_payment_unactive_text') !==
                'manual_payment_unactive_text'
            "
            class="font-normal text-base text-left py-1"
          >
            {{ bloomreach.getText('manual_payment_unactive_text') }}
          </p> -->
        </div>
      </div>
      <!-- Div to show if its Sweden and E-Invoice -->
      <div
        v-else-if="
          bloomreach.getLocale() === 'sv-SE' &&
          (isDefaultCreditcard
            ? paymentMethodTabs[3].active
            : paymentMethodTabs[2].active)
        "
        class="p-4 w-full bg-blue-100 text-sm text-left h-auto"
      >
        <h2
          v-if="
            bloomreach.getText('se_einvoice_text').length > 0 &&
            bloomreach.getText('se_einvoice_text') !== 'se_einvoice_text'
          "
          class="font-medium text-xl text-left py-2"
        >
          {{ bloomreach.getText('se_einvoice_text') }}
        </h2>
        <div class="p-6 bg-white h-full">
          <p class="my-3 font-normal text-base">
            {{ bloomreach.getText('e_invoice_payment_text') }}
          </p>
        </div>
      </div>
    </template>
  </GVCard>
</template>
<script lang="ts">
import { defineComponent, inject, ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'

import { GVButton, GVCard } from '@gv/core'

import RightArrowIcon from '../assets/right_arrow.svg'
import { formatDate, bloomreachKey } from '@gv/core'

export default defineComponent({
  name: 'NewPaymentMethodCard',
  components: {
    GVButton,
    GVCard,
    RightArrowIcon,
  },
  props: {
    payButtonsText: {
      type: Boolean,
      default: false,
    },
    hasClosingArrow: {
      type: Boolean,
      default: false,
    },
    hasButtons: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataDefaultPaymentMethod: {
      type: Object,
      default: {},
    },
    isMandateRequested: {
      type: Boolean,
      default: false,
    },
    profileData: {
      type: Object,
      default: {},
    },
  },
  emits: [
    'openPaymentWindow',
    'updateDefaultPaymentMethodCard',
    'addNewPaymentMethod',
    'paymentTabActive',
    'setManualPayment',
  ],
  setup(props, { emit }) {
    const bloomreach = inject(bloomreachKey)!
    const getText = (key: string) => bloomreach.getText(key)
    const route = useRoute()
    const showDirectDebitMsg = ref(route.name != 'PaymentMethod')
    const accountData = ref(
      JSON.parse(localStorage.getItem('userCache') || '{}')
    )
    const paymentMethodType = ref(
      props.dataDefaultPaymentMethod?.paymentMethodType
    )

    const isDefaultCreditcard = computed(() => {
      return !!(
        props.dataDefaultPaymentMethod.defaultPaymentMethod &&
        paymentMethodType.value.toLowerCase().includes('credit') &&
        props.dataDefaultPaymentMethod.creditCardData &&
        props.dataDefaultPaymentMethod.creditCardData.id
      )
    })

    const isDefaultAutogiro = computed(() => {
      return (
        props.dataDefaultPaymentMethod.defaultPaymentMethod &&
        paymentMethodType.value === 'Autogiro'
      )
    })

    const isDefaultBetalingsservice = computed(() => {
      return (
        props.dataDefaultPaymentMethod.defaultPaymentMethod &&
        paymentMethodType.value === 'Betalingsservice'
      )
    })

    const paymentMethodTabs = ref([
      {
        id: 1,
        title: bloomreach.getText('direct_debit_tab_title'),
        subtitle: bloomreach.getText('direct_debit_tab_subtitle'),
        active:
          props.dataDefaultPaymentMethod.defaultPaymentMethod &&
          (paymentMethodType.value === 'Autogiro' ||
            paymentMethodType.value === 'Betalingsservice'),
      },
      {
        id: 2,
        title: bloomreach.getText('manual_payment_tab_title'),
        subtitle: '',
        active: !props.dataDefaultPaymentMethod.defaultPaymentMethod,
      },
    ])

    if (isDefaultCreditcard.value) {
      paymentMethodTabs.value.unshift({
        id: 3,
        title: bloomreach.getText('recurring_cc_tab_title'),
        subtitle: '',
        active: isDefaultCreditcard.value,
      })
    }

    if (bloomreach.getLocale() === 'sv-SE') {
      paymentMethodTabs.value.push({
        id: 4,
        title: bloomreach.getText('se_einvoice_tab_title'),
        subtitle: '',
        active: false,
      })
    }

    const changeActiveTab = (id) => {
      paymentMethodTabs.value.map((tab) => {
        if (tab.id === id) tab.active = true
        else tab.active = false
      })
      emit('paymentTabActive', true)
    }

    const openPaymentWindow = (option: string) => {
      emit('openPaymentWindow', {
        option,
        paymentMethodType: props.dataDefaultPaymentMethod?.paymentMethodType,
      })
    }

    const closePaymentDefaultCard = () => {
      emit('updateDefaultPaymentMethodCard', false)
    }

    watch(
      () => props.dataDefaultPaymentMethod,
      (newData) => {
        let manualPaymentActive = false
        // if (
        //   !newData?.code ||
        //   (!newData.defaultPaymentMethod && paymentMethodType.value === 'None')
        // ) {
        //   manualPaymentActive = true
        // }
        if (!newData.paymentMethodType) {
          manualPaymentActive = true
        }

        if (newData) {
          paymentMethodType.value = newData.paymentMethodType || ''
          paymentMethodTabs.value = [
            {
              id: 1,
              title: bloomreach.getText('direct_debit_tab_title'),
              subtitle: bloomreach.getText('direct_debit_tab_subtitle'),
              active:
                newData.defaultPaymentMethod &&
                (paymentMethodType.value === 'Autogiro' ||
                  paymentMethodType.value === 'Betalingsservice'),
            },
            {
              id: 2,
              title: bloomreach.getText('manual_payment_tab_title'),
              subtitle: '',
              active: manualPaymentActive,
            },
          ]

          if (isDefaultCreditcard.value) {
            paymentMethodTabs.value.unshift({
              id: 3,
              title: bloomreach.getText('recurring_cc_tab_title'),
              subtitle: '',
              active: isDefaultCreditcard.value,
            })
          }

          if (bloomreach.getLocale() === 'sv-SE') {
            paymentMethodTabs.value.push({
              id: 4,
              title: bloomreach.getText('se_einvoice_tab_title'),
              subtitle: '',
              active: false,
            })
          }
        }
      }
    )

    return {
      getText,
      openPaymentWindow,
      closePaymentDefaultCard,
      accountData,
      paymentMethodType,
      showDirectDebitMsg,
      formatDate,
      bloomreach,
      paymentMethodTabs,
      changeActiveTab,
      isDefaultCreditcard,
      isDefaultAutogiro,
      isDefaultBetalingsservice,
    }
  },
})
</script>

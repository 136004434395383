import {
  Bloomreach,
  CancelSubscriptionPreviewData,
  CancelSubscriptionPreviewItem,
  CreditMemoItems,
  CsvTransaction,
  CustomerData,
  DebitMemoItem,
  PreviewDocumentType,
  QuickCustomerData,
  RefundDetails,
  SubscriptionPreviewData,
  SubscriptionPreviewDataWithType,
  UnmatchedTransaction,
} from '@gv/core'
// TODO: Split this file between the new 3 files created and delete this or rename/keep if there's anything that is shared between portals

import moment from 'moment'

import {
  convertProxyToJson,
  formatCurrency,
  formatToReadableDate,
} from '../../functions'

export function formatCustomersData(
  customerData: Array<CustomerData>,
  bloomreach: Bloomreach,
  currency: string
) {
  let formattedData: CustomerData[] = []

  let formattedHeaders = [
    { name: bloomreach.getText('customer_no_user'), field: 'customerNumber' },
    { name: bloomreach.getText('name'), field: 'name' },
    { name: bloomreach.getText('ssn'), field: 'socialSecurityNumber' },
    { name: bloomreach.getText('customer_status'), field: 'customerStatus' },
    { name: bloomreach.getText('credit_status'), field: 'creditStatus' },
    { name: bloomreach.getText('payment_type'), field: 'paymentType' },
    {
      name: bloomreach.getText('payment_agreement'),
      field: 'paymentAgreement',
    },
    { name: bloomreach.getText('reminder_status'), field: 'reminderStatus' },
    { name: bloomreach.getText('reminder_date'), field: 'reminderDate' },
    { name: bloomreach.getText('invoice_type'), field: 'invoiceType' },
    {
      name: bloomreach.getText('delivery_address_different'),
      field: 'deliveryAddressDifferent',
    },
    { name: bloomreach.getText('c_o'), field: 'careOfAddress' },
    { name: bloomreach.getText('address_1'), field: 'address1' },
    { name: bloomreach.getText('address_2'), field: 'address2' },
    { name: bloomreach.getText('zip_code'), field: 'postalCode' },
    { name: bloomreach.getText('town'), field: 'city' },
    { name: bloomreach.getText('country'), field: 'countryCode' },
    { name: bloomreach.getText('shop_id'), field: 'storeNumber' },
    { name: bloomreach.getText('email'), field: 'email' },
    { name: bloomreach.getText('godman_sweden'), field: 'godmanSweden' },
    { name: bloomreach.getText('phone_number'), field: 'phoneNumber' },
    { name: bloomreach.getText('cellphone_number'), field: 'mobileNumber' },
    { name: bloomreach.getText('work_number'), field: 'workNumber' },
    { name: bloomreach.getText('first_name'), field: 'firstName' },
    { name: bloomreach.getText('last_name'), field: 'lastName' },
    {
      name: bloomreach.getText('subscription_status'),
      field: 'subscriptionStatus',
    },
    { name: bloomreach.getText('ordered_date'), field: 'orderedDate' },
    { name: bloomreach.getText('activation_date'), field: 'activationDate' },
    {
      name: bloomreach.getText('subscription_type'),
      field: 'subscriptionType',
    },
    {
      name: bloomreach.getText('subscription_end_date'),
      field: 'subscriptionEndDate',
    },
    {
      name: bloomreach.getText('total_outstanding_balance'),
      field: 'totalOutstandingBalance',
      currency: true,
    },
    {
      name: bloomreach.getText('total_monthly_price'),
      field: 'totalMonthlyPrice',
      currency: true,
    },
    { name: bloomreach.getText('invoice_owner'), field: 'invoiceOwner' },
    {
      name: bloomreach.getText('reminder_paused_until_date'),
      field: 'reminderPausedUntilDate',
    },
    {
      name: bloomreach.getText('next_reminder_status'),
      field: 'nextReminderStatus',
    },
    {
      name: bloomreach.getText('next_reminder_date'),
      field: 'nextReminderDate',
    },
    { name: bloomreach.getText('black_out_date'), field: 'blackoutDate' },
  ]

  customerData.map((data) => {
    let auxData: any = {}
    // Account
    auxData.customerNumber = data.customerNumber
    auxData.name = data.accountName
    auxData.socialSecurityNumber = data.personalInfo.socialSecurityNumber
    auxData.customerStatus = data.customerStatus
    auxData.creditStatus = data.creditStatus
    auxData.paymentType = data.paymentType
    auxData.paymentAgreement = data.paymentAgreement
    auxData.reminderStatus = data.reminderStatus
    auxData.reminderDate = formatDate(data.reminderDate, bloomreach)
    auxData.invoiceType = data.invoiceType
    auxData.deliveryAddressDifferent = data.soldToSameAsBillTo ? 'NO' : 'YES'
    auxData.storeNumber = data.storeNumber
    auxData.workNumber = data.contactInfo.workNumber
    auxData.godmanSweden = data.godmanSweden

    auxData.careOfAddress = data.billToAddressInfo.careOfAddress
    auxData.address1 = data.billToAddressInfo.addressLine1
    auxData.address2 = data.billToAddressInfo.addressLine2
    auxData.postalCode = data.billToAddressInfo.postalCode
    auxData.city = data.billToAddressInfo.city
    auxData.countryCode = data.billToAddressInfo.countryCode
    auxData.email = data.contactInfo.email
    auxData.phoneNumber = data.contactInfo.phoneNumber
    auxData.mobileNumber = data.contactInfo.mobileNumber
    auxData.firstName = data.personalInfo.firstName
    auxData.lastName = data.personalInfo.lastName

    auxData.subscriptionStatus = data.subscriptionStatus
    auxData.orderedDate = formatDate(data.reminderDate, bloomreach)
    auxData.activationDate = formatDate(data.activationDate, bloomreach)
    auxData.subscriptionType = data.subscriptionType
    auxData.subscriptionEndDate = formatDate(
      data.subscriptionEndDate,
      bloomreach
    )
    auxData.totalOutstandingBalance = isNaN(data.totalOutstandingBalance)
      ? data.totalOutstandingBalance
      : formatCurrency(data.totalOutstandingBalance, currency, true, true)
    auxData.totalMonthlyPrice = isNaN(data.totalMonthlyPrice)
      ? data.totalMonthlyPrice
      : formatCurrency(data.totalMonthlyPrice, currency, true, true)
    auxData.invoiceOwner = data.invoiceOwner ? 'YES' : 'NO'
    auxData.reminderPausedUntilDate = formatDate(
      data.reminderPausedUntilDate,
      bloomreach
    )
    auxData.nextReminderStatus = data.nextReminderStatus
    auxData.nextReminderDate = formatDate(data.nextReminderDate, bloomreach)
    auxData.blackoutDate = formatDate(data.blackoutDate, bloomreach)

    auxData.accountNumber = data.accountNumber
    formattedData.push(auxData)
  })

  return {
    data: formattedData,
    headers: formattedHeaders,
  }
}

export function formatQuickSearchCustomerData(
  customerData: Array<QuickCustomerData>,
  bloomreach: Bloomreach,
  currency: string
) {
  let formattedData: QuickCustomerData[] = []

  let formattedHeaders = [
    { name: bloomreach.getText('customer_no_user'), field: 'customerNumber' },
    { name: bloomreach.getText('name'), field: 'name' },
    { name: bloomreach.getText('ssn'), field: 'socialSecurityNumber' },
    { name: bloomreach.getText('customer_status'), field: 'customerStatus' },
    { name: bloomreach.getText('c_o'), field: 'careOfAddress' },
    { name: bloomreach.getText('address_1'), field: 'address1' },
    { name: bloomreach.getText('address_2'), field: 'address2' },
    { name: bloomreach.getText('zip_code'), field: 'postalCode' },
    { name: bloomreach.getText('town'), field: 'city' },
    { name: bloomreach.getText('country'), field: 'countryCode' },
    { name: bloomreach.getText('shop_id'), field: 'storeNumber' },
    { name: bloomreach.getText('email'), field: 'email' },
    { name: bloomreach.getText('phone_number'), field: 'phoneNumber' },
    { name: bloomreach.getText('cellphone_number'), field: 'mobileNumber' },
    { name: bloomreach.getText('work_number'), field: 'workNumber' },
    { name: bloomreach.getText('first_name'), field: 'firstName' },
    { name: bloomreach.getText('last_name'), field: 'lastName' },
    { name: bloomreach.getText('invoice_owner'), field: 'invoiceOwner' },
  ]

  customerData.map((data) => {
    let auxData: any = {}
    // Account
    auxData.customerNumber = data.customerNumber
    auxData.name = data.accountName
    auxData.socialSecurityNumber = data.personalInfo.socialSecurityNumber
    auxData.customerStatus = data.customerStatus
    auxData.invoiceType = data.invoiceType
    auxData.storeNumber = data.storeNumber
    auxData.workNumber = data.contactInfo.workNumber

    auxData.careOfAddress = data.billToAddressInfo.careOfAddress
    auxData.address1 = data.billToAddressInfo.addressLine1
    auxData.address2 = data.billToAddressInfo.addressLine2
    auxData.postalCode = data.billToAddressInfo.postalCode
    auxData.city = data.billToAddressInfo.city
    auxData.countryCode = data.billToAddressInfo.countryCode
    auxData.email = data.contactInfo.email
    auxData.phoneNumber = data.contactInfo.phoneNumber
    auxData.mobileNumber = data.contactInfo.mobileNumber
    auxData.firstName = data.personalInfo.firstName
    auxData.lastName = data.personalInfo.lastName

    auxData.subscriptionStatus = data.subscriptionStatus
    auxData.invoiceOwner = data.invoiceOwner ? 'YES' : 'NO'

    auxData.accountNumber = data.accountNumber
    formattedData.push(auxData)
  })

  return {
    data: formattedData,
    headers: formattedHeaders,
  }
}

export function formatDunningData(
  dunningData: Array<DunningData>,
  bloomreach: any,
  currency: string
) {
  const formattedData: DunningData[] = []

  const formattedHeaders = [
    {
      name: bloomreach.getText('select'),
      field: 'select',
      input: true,
      inputType: 'checkbox',
      disabled: 'checkboxDisabled',
    },
    { name: bloomreach.getText('customer_number'), field: 'customerNumber' },
    { name: bloomreach.getText('customer_name'), field: 'customerName' },
    {
      name: bloomreach.getText('account_balance'),
      field: 'accountBalance',
      currency: true,
    },
    {
      name: bloomreach.getText('overdue_amount'),
      field: 'overdueAmount',
      currency: true,
    },
    {
      name: bloomreach.getText('next_reminder_status'),
      field: 'nextReminderStatus',
    },
    {
      name: bloomreach.getText('credit_notes_count'),
      field: 'creditNotesCount',
    },
    { name: bloomreach.getText('payments_count'), field: 'paymentsCount' },
    {
      name: bloomreach.getText('payment_method_type'),
      field: 'paymentMethodType',
    },
    { name: bloomreach.getText('invoice_type'), field: 'invoiceType' },
    {
      name: bloomreach.getText('reminder_paused_until_date'),
      moment: true,
      field: 'reminderPausedUntilDate',
    },
    {
      name: bloomreach.getText('next_reminder_status_date'),
      moment: true,
      field: 'nextReminderStatusDate',
    },
  ]

  dunningData.map((data) => {
    let auxData: any = {}

    formattedHeaders.forEach((header) => {
      // @ts-ignore
      if (header.currency && (data[header.field] || data[header.field] === 0)) {
        //@ts-ignore
        auxData[header.field] = formatCurrency(data[header.field], currency)
      }
      //@ts-ignore
      else if (header.moment && data[header.field]) {
        //@ts-ignore
        auxData[header.field] = formatDate(data[header.field], bloomreach)
      } else {
        //@ts-ignore
        auxData[header.field] = data[header.field]
      }
    })

    auxData.accountNumber = data.accountNumber
    auxData.id = data.id

    formattedData.push(auxData)
  })

  return {
    data: formattedData,
    headers: formattedHeaders,
  }
}

export function formatExternalAccountData(
  externalAccountData: Array<ExternalAccountData>,
  bloomreach: any,
  currency: string
) {
  const formattedData: ExternalAccountData[] = []

  const formattedHeaders = [
    { name: bloomreach.getText('name'), field: 'accountName' },
    // { name: bloomreach.getText('account_number'), field: 'accountNumber' },
    { name: bloomreach.getText('customer_number'), field: 'customerNumber' },
    { name: bloomreach.getText('invoice_number'), field: 'invoiceNumber' },
    {
      name: bloomreach.getText('total_invoice_balance'),
      field: 'totalInvoiceBalance',
      currency: true,
    },
  ]

  externalAccountData.map((data) => {
    let auxData: any = {}

    formattedHeaders.forEach((header) => {
      // @ts-ignore
      if (header.currency && (data[header.field] || data[header.field] === 0)) {
        //@ts-ignore
        auxData[header.field] = formatCurrency(data[header.field], currency)
        // bloomreach.getLocale()
      }
      //@ts-ignore
      else if (header.moment && data[header.field]) {
        //@ts-ignore
        auxData[header.field] = formatDate(data[header.field], bloomreach)
      } else {
        //@ts-ignore
        auxData[header.field] = data[header.field]
      }
      auxData.accountNumber = data.accountNumber
    })
    formattedData.push(auxData)
  })

  return {
    data: formattedData,
    headers: formattedHeaders,
  }
}

export function formatInvoicesData(
  invoicesData: Array<InvoicesData>,
  bloomreach: any,
  currency: string
) {
  const formattedData: InvoicesData[] = []
  // const currency = invoicesData[0] ? `${invoicesData[0].currency}` : ''

  const formattedHeaders = [
    {
      name: bloomreach.getText('select'),
      field: 'select',
      input: true,
      inputType: 'checkbox',
      disabled: 'checkboxDisabled',
    },
    { name: bloomreach.getText('created_date'), field: 'date' },
    { name: bloomreach.getText('invoice_type'), field: 'type' },
    { name: bloomreach.getText('number'), field: 'number' },
    { name: bloomreach.getText('period'), field: 'period' },
    { name: bloomreach.getText('amount'), field: 'amount', currency: true },
    { name: bloomreach.getText('due_date'), field: 'dueDate' },
    { name: bloomreach.getText('status'), field: 'status', extraClass: true },
    {
      name: bloomreach.getText('outstanding_balance'),
      field: 'balance',
      currency: true,
    },
  ]

  invoicesData.map((data) => {
    let auxData: any = {}
    auxData.number = data.legacyNumber ? `I_${data.legacyNumber}` : data.number
    auxData.type = data.type ? bloomreach.getText(data.type.toLowerCase()) : ''
    auxData.date = formatDate(data.date, bloomreach)
    auxData.amount = isNaN(data.amount)
      ? data.amount
      : formatCurrency(data.amount, currency, true, true)
    auxData.paymentAmount = isNaN(data.paymentAmount)
      ? data.paymentAmount
      : formatCurrency(data.paymentAmount, currency, true, true)
    auxData.dueDate = formatDate(data.dueDate, bloomreach)
    auxData.status = data.status
      ? bloomreach.getText(data.status.toLowerCase())
      : ''
    auxData.balance = isNaN(data.balance)
      ? data.balance
      : formatCurrency(data.balance, currency, true, true)
    let period: string
    if (data.period) {
      period = `${bloomreach.getText(data.period.toLowerCase())} ${
        data.yearPeriod
      }`
    } else {
      // Figure out the month + year from the due date
      const dueDate = moment(data.dueDate)
      const month = dueDate.format('MMMM')
      const year = dueDate.format('YYYY')

      period = `${bloomreach.getText(month.toLowerCase())} ${year}`
    }
    auxData.period = period
    auxData._class = data.status === 'Paid' ? 'text-green-500' : 'text-red-500'

    auxData.checkboxDisabled = !(
      auxData.number != auxData.number.toLowerCase().includes('mig_') ||
      auxData.number != auxData.number.toLowerCase().includes('i_')
    )

    auxData.checkboxDisabled
      ? (auxData.title = 'No PDF')
      : (auxData.title = auxData.number + '.pdf')

    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatInvoiceReviewData(
  invoicesData: Array<InvoiceReviewData>,
  bloomreach: any
) {
  const formattedData: InvoiceReviewData[] = []

  const formattedHeaders = [
    { name: bloomreach.getText('number'), field: 'number' },
    { name: bloomreach.getText('status'), field: 'status' },
    { name: bloomreach.getText('amount'), field: 'amount', currency: true },
    { name: bloomreach.getText('balance'), field: 'balance', currency: true },
    { name: bloomreach.getText('date'), field: 'date' },
    { name: bloomreach.getText('due_date'), field: 'dueDate' },
    { name: bloomreach.getText('currency'), field: 'currency' },
  ]

  invoicesData.map((data) => {
    let auxData: any = {}

    auxData.number = data.number
    auxData.status = data.status
    auxData.amount = data.amount
    auxData.balance = data.balance
    auxData.date = formatDate(data.date, bloomreach)
    auxData.dueDate = formatDate(data.dueDate, bloomreach)
    auxData.currency = data.currency

    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatPaymentsData(
  paymentsData: Array<PaymentsData>,
  bloomreach: any
) {
  const formattedData: PaymentsData[] = []

  const formattedHeaders = [
    { name: bloomreach.getText('payment_date'), field: 'createdDate' },
    { name: bloomreach.getText('type'), field: 'transactionType' },
    { name: bloomreach.getText('payment_reference'), field: 'number' },
    // { name: bloomreach.getText('due_date'), field: 'dueDate' },
    { name: bloomreach.getText('amount'), field: 'amount', currency: true },
    { name: bloomreach.getText('payment_type'), field: 'paymentType' },
    {
      name: bloomreach.getText('settled_with_invoice'),
      field: 'invoiceNumber',
      hasTooltip: true,
      tooltipField: 'period',
    },
    // { name: bloomreach.getText('balance'), field: 'balance' },
  ]

  paymentsData
    .filter(
      (x) => x.transactionType == 'Payment' || x.transactionType == 'Refund'
    )
    .map((data) => {
      let auxData: any = {}

      auxData.transactionType = data.transactionType
      auxData.number = data.number
      auxData.createdDate = formatDate(data.date, bloomreach)
      auxData.dueDate = formatDate(data.dueDate, bloomreach)
      auxData.amount = isNaN(data.amount)
        ? data.amount
        : formatCurrency(data.amount, bloomreach.getLocale(), true, true)
      auxData.balance = isNaN(data.balance)
        ? data.balance
        : formatCurrency(data.balance, bloomreach.getLocale(), true, true)
      auxData.paymentType = data.paymentType
        ?.toLocaleLowerCase()
        .includes('creditcard')
        ? 'CreditCard'
        : data.paymentType
      auxData.invoiceNumber = data.invoiceNumber.join(' ')
      auxData.transactionType = bloomreach.getText(
        data.transactionType.toLowerCase()
      )
      auxData.period =
        data.invoiceNumberWithPeriods && data.invoiceNumberWithPeriods.length
          ? data.invoiceNumberWithPeriods
              .map((invoice) => `${invoice.period}/${invoice.yearPeriod}`)
              .join(' ')
          : null

      formattedData.push(auxData)
    })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatFinancialDetailsData(
  financialDetailsData: Array<FinancialDetailsData>,
  bloomreach: any,
  currency: string
) {
  const formattedData: FinancialDetailsData[] = []

  const formattedHeaders = [
    { name: bloomreach.getText('posting_date'), field: 'createdDate' },
    {
      name: bloomreach.getText('document_type'),
      field: 'type',
      hasTooltip: true,
      tooltipField: 'relatedTransactions',
    },
    { name: bloomreach.getText('document_number'), field: 'number' },
    // { name: bloomreach.getText('customer_number'), field: 'customerNumber' },
    { name: bloomreach.getText('period'), field: 'monthPeriod' },
    { name: bloomreach.getText('payment_type'), field: 'paymentType' },
    {
      name: `${bloomreach.getText('amount')} (${currency})`,
      field: 'amount',
      currency: true,
    },
    {
      name: `${bloomreach.getText('remaining_amount')} (${currency})`,
      field: 'balance',
      currency: true,
    },
    {
      name: `${bloomreach.getText('account_balance')} (${currency})`,
      field: 'newAccountBalance',
      currency: true,
    },
    { name: bloomreach.getText('due_date'), field: 'dueDate' },
    { name: bloomreach.getText('open'), field: 'open' },
    { name: bloomreach.getText('remark'), field: 'comments' },
  ]

  financialDetailsData.map((data) => {
    let auxData: any = {}

    let relatedTransactions = ''
    if (Array.isArray(data.relatedTransactions)) {
      relatedTransactions = data.relatedTransactions.filter((tx) => tx != null)

      relatedTransactions = [...new Set(relatedTransactions)].toString().trim()
    }

    auxData.createdDate = formatDate(data.createdDate, bloomreach)
    auxData.type = data.type
    auxData.number = data.number
    auxData.customerNumber = data.customerNumber
    auxData.amount =
      auxData.type == 'CreditMemo' ||
      auxData.type == 'Payment' ||
      auxData.type == 'Write-off'
        ? `-${formatCurrency(data.amount, currency, false)}`
        : `${formatCurrency(data.amount, currency, false)}`
    auxData.balance = formatCurrency(data.balance, currency, false)
    auxData.newAccountBalance = formatCurrency(
      data.newAccountBalance,
      currency,
      false
    )
    auxData.dueDate = formatDate(data.dueDate, bloomreach)
    auxData.open = data.open
      ? data.open
      : data.balance > 0 && data.type === 'Invoice'
      ? 'Yes'
      : 'No'
    auxData.comments = data.comments
    auxData.monthPeriod = data.monthPeriod
    auxData.paymentType = data.paymentType
    if (data.type === 'Refund' && data.paymentType === 'AR Manual File') {
      auxData.paymentType = 'Manual'
    }
    auxData.latestPDFFileId = data.latestPDFFileId
    auxData.relatedTransactions = relatedTransactions
    auxData.legacyNumber = data.legacyNumber
    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatARSubscriptionData(
  subscriptionData: SubscriptionData,
  bloomreach: any,
  currency: string
) {
  const formattedData: SubscriptionOwnedData[] = []
  const formattedHeaders = [
    { name: bloomreach.getText('store_number'), field: 'storeNumber' },
    { name: bloomreach.getText('subscription_number'), field: 'number' },
    { name: bloomreach.getText('subscription_status'), field: 'status' },
    {
      name: bloomreach.getText('price_per_month'),
      field: 'totalMonthlyPrice',
      currency: true,
    },
    {
      name: bloomreach.getText('ordered_date'),
      field: 'orderedDate',
      moment: true,
    },
    {
      name: bloomreach.getText('activation_date'),
      field: 'activationDate',
      moment: true,
    },
    {
      name: bloomreach.getText('end_date'),
      field: 'subscriptionEndDate',
      moment: true,
    },
    {
      name: 'Invoice End Date',
      field: 'invoiceStopDate',
      moment: true,
    },
    {
      name: bloomreach.getText('customer_termination_date'),
      field: 'cancellationNoticeDate',
      moment: true,
    },
    { name: bloomreach.getText('reason_code'), field: 'cancellationReason' },
    {
      name: bloomreach.getText('binding_period'),
      field: 'earliestSubscriptionEndDate',
      moment: true,
    },
    {
      name: bloomreach.getText('next_swap_date'),
      field: 'nextSwapDate',
      moment: true,
    },
    {
      name: bloomreach.getText('return_date'),
      field: 'returnDate',
      moment: true,
    },
    { name: bloomreach.getText('subscription_type'), field: 'type' },
    {
      name: bloomreach.getText('subscription_total_items'),
      field: 'totalItems',
    },
  ]

  subscriptionData.subscriptionsOwned.map((data: any) => {
    let auxData: any = {}
    formattedHeaders.forEach((header) => {
      if (header.moment && data.subscription[header.field]) {
        auxData[header.field] = formatDate(
          data.subscription[header.field],
          bloomreach
        )
      }
      // @ts-ignore
      else if (header.currency && data.subscription[header.field]) {
        //@ts-ignore
        auxData[header.field] = formatCurrency(
          data.subscription[header.field],
          currency
        )
      } else {
        auxData[header.field] = data.subscription[header.field]
      }
    })
    const { headers: subHeaders, data: subData } = formatSubscriptionRatePlans(
      data.items,
      bloomreach,
      currency
    )
    auxData.id = data.subscription.number
    auxData.customerNumber = subscriptionData.customerNumber
    auxData.subHeaders = subHeaders
    auxData.subRow = subData
    auxData.cancellationScheduledDate =
      data.subscription.cancellationScheduledDate
    auxData.totalItems = data.items?.filter(
      (rp: any) => rp.state === 'Active'
    ).length
    formattedData.push(auxData)
  })
  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatARSubscriptionRelatedData(
  relatedArrays: RelatedSubscriptionData,
  bloomreach: any,
  currency: string
) {
  const formattedParentsData: RelatedAccountData[] = []
  const formattedChildrenData: RelatedAccountData[] = []
  const formattedHeaders = [
    {
      name: bloomreach.getText('subscription_number'),
      field: 'subscriptionNumber',
    },
    {
      name: bloomreach.getText('subscription_status'),
      field: 'subscriptionStatus',
    },
    {
      name: bloomreach.getText('activation_date'),
      field: 'activationDate',
      moment: true,
    },
    {
      name: bloomreach.getText('total_monthly_price'),
      field: 'totalMonthlyPrice',
      currency: true,
    },

    {
      name: bloomreach.getText('customer_termination_date'),
      field: 'customerCancellationDate',
      moment: true,
    },
    {
      name: bloomreach.getText('return_date'),
      field: 'returnDate',
      moment: true,
    },

    {
      name: bloomreach.getText('subscription_type'),
      field: 'subscriptionType',
    },
  ]

  const parentData = relatedArrays.parent
  let auxData: any = {}
  let auxParentData: any = {
    subscriptions: [],
  }
  if (parentData) {
    parentData.subscriptions.map((data: any) => {
      formattedHeaders.forEach((header) => {
        if (header.moment && data[header.field]) {
          auxData[header.field] = formatDate(data[header.field], bloomreach)
        }
        // @ts-ignore
        else if (header.currency && data[header.field]) {
          //@ts-ignore
          auxData[header.field] = formatCurrency(data[header.field], currency)
        } else {
          auxData[header.field] = data[header.field]
        }
      })

      auxParentData.subscriptions.push(auxData)
    })

    auxParentData.id = parentData.accountNumber
    auxParentData.accountNumber = parentData.accountNumber
    auxParentData.customerNumber = parentData.customerNumber
    auxParentData.name = `${parentData.personalInfo.firstName} ${parentData.personalInfo.lastName}`

    formattedParentsData.push(auxParentData)
  }

  relatedArrays.children.map((childrenData: any) => {
    let auxData: any = {}
    let auxChildrenData: any = {
      subscriptions: [],
    }
    formattedHeaders.forEach((header) => {
      if (header.moment && childrenData[header.field]) {
        auxData[header.field] = formatDate(
          childrenData[header.field],
          bloomreach
        )
      }
      // @ts-ignore
      else if (header.currency && childrenData[header.field]) {
        //@ts-ignore
        auxData[header.field] = formatCurrency(
          childrenData[header.field],
          currency
        )
      } else {
        auxData[header.field] = childrenData[header.field]
      }
    })

    auxChildrenData.subscriptions.push(auxData)

    auxChildrenData.id = childrenData.id
    auxChildrenData.accountNumber = childrenData.accountNumber
    auxChildrenData.customerNumber = childrenData.customerNumber
    auxChildrenData.name = childrenData.customerName

    formattedChildrenData.push(auxChildrenData)
  })

  return {
    headers: formattedHeaders,
    data: {
      parents: formattedParentsData,
      children: formattedChildrenData,
    },
  }
}

export function formatSubscriptionRatePlans(
  rateplans: Array<SubscriptionOwnedRatePlanData>,
  bloomreach: any,
  currency: string
) {
  const formattedData: SubscriptionOwnedRatePlanData[] = []
  const formattedHeaders = [
    { name: bloomreach.getText('opus_number'), field: 'opusNumber' },
    {
      name: bloomreach.getText('price_per_month'),
      field: 'decimalPricePerMonth',
      currency: true,
    },
    { name: bloomreach.getText('product_status'), field: 'state' },
    {
      name: bloomreach.getText('activation_date'),
      field: 'activationDate',
      moment: true,
    },
    {
      name: bloomreach.getText('return_date'),
      field: 'returnDate',
      moment: true,
    },
    { name: bloomreach.getText('glass_type'), field: 'glassType' },
    { name: bloomreach.getText('brand_name'), field: 'brandName' },
    { name: bloomreach.getText('item_description'), field: 'displayName' },
    { name: bloomreach.getText('reason_in'), field: 'activationReason' },
    {
      name:
        bloomreach.getText('replaced_pos') +
        ' ' +
        bloomreach.getText('item_number'),
      field: 'replacedByOpusNumber',
    },
    { name: bloomreach.getText('reason_out'), field: 'inactivationReason' },
    {
      name:
        bloomreach.getText('replacing_pos') +
        ' ' +
        bloomreach.getText('item_number'),
      field: 'replacingOpusNumber',
    },
    { name: bloomreach.getText('state_reason'), field: 'stateReason' },
    { name: bloomreach.getText('end_date'), field: 'endDate', moment: true },
    {
      name: bloomreach.getText('regret_date'),
      field: 'regretDate',
      moment: true,
    },
    {
      name: bloomreach.getText('cash_price'),
      field: 'decimalCashPrice',
      currency: true,
    },
    { name: bloomreach.getText('discount_code'), field: 'cashDiscountCode' },
    { name: bloomreach.getText('campaign_id'), field: 'campaignId' },
    { name: bloomreach.getText('promo_code'), field: 'promoCode' },
    {
      name:
        bloomreach.getText('frame') + ' ' + bloomreach.getText('item_number'),
      field: 'frameItemNumber',
    },
    {
      name:
        bloomreach.getText('right_lens') +
        ' ' +
        bloomreach.getText('item_number'),
      field: 'rightLensItemNumber',
    },
    {
      name:
        bloomreach.getText('left_lens') +
        ' ' +
        bloomreach.getText('item_number'),
      field: 'leftLensItemNumber',
    },
  ]

  rateplans.map((data: any) => {
    let auxData: any = {}
    formattedHeaders.forEach((header) => {
      if (header.moment && data[header.field]) {
        // @ts-ignore
        auxData[header.field] = formatDate(data[header.field], bloomreach)
      }
      // @ts-ignore
      else if (header.currency && data[header.field]) {
        //@ts-ignore
        auxData[header.field] = formatCurrency(data[header.field], currency)
      } else {
        auxData[header.field] = data[header.field]
      }
    })
    auxData.id = data.id
    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatOpusDetails(
  opusDetails: SubscriptionData,
  bloomreach: any,
  currency: any
) {
  const formattedData: SubscriptionOwnedData[] = []
  const formattedHeaders = [
    { name: bloomreach.getText('subscription_number'), field: 'number' },
    { name: bloomreach.getText('order_type'), field: 'type' },
    { name: bloomreach.getText('order_status'), field: 'status' },
  ]

  opusDetails.subscriptionsOwned.map((data: any) => {
    let auxData: any = {}

    //We  don't get an ID from the backend, we just need one to expand the table rows
    auxData.id = Math.floor(
      Math.random() * Math.floor(Math.random() * Date.now())
    )

    formattedHeaders.forEach((header) => {
      auxData[header.field] = data.subscription[header.field]
    })

    if (data.items) {
      const { headers: subHeaders, data: subData } = formatOpusRatePlans(
        data.items,
        bloomreach,
        currency
      )
      auxData.subHeaders = subHeaders
      auxData.subRow = subData
    }
    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatOpusRatePlans(
  rateplans: Array<SubscriptionOwnedRatePlanData>,
  bloomreach: any,
  currency: any
) {
  const formattedData: SubscriptionOwnedRatePlanData[] = []
  const formattedHeaders = [
    { name: bloomreach.getText('order_number'), field: 'opusNumber' },
    { name: bloomreach.getText('product_status'), field: 'state' },
    {
      name: bloomreach.getText('order_date'),
      field: 'createdDate',
      moment: true,
    },
    {
      name: bloomreach.getText('delivery_date'),
      field: 'activationDate',
      moment: true,
    },
    { name: bloomreach.getText('description'), field: 'displayName' },
    {
      name:
        bloomreach.getText('frame') + ' ' + bloomreach.getText('item_number'),
      field: 'frameItemNumber',
    },
    {
      name:
        bloomreach.getText('right_lens') +
        ' ' +
        bloomreach.getText('item_number'),
      field: 'rightLensItemNumber',
    },
    {
      name:
        bloomreach.getText('left_lens') +
        ' ' +
        bloomreach.getText('item_number'),
      field: 'leftLensItemNumber',
    },
    { name: bloomreach.getText('lens_type'), field: 'glassType' },
    { name: bloomreach.getText('discount_code'), field: 'cashDiscountCode' },
    {
      name: bloomreach.getText('mdr_price'),
      field: 'decimalPricePerMonth',
      currency: true,
    },
    { name: bloomreach.getText('promo_code'), field: 'promoCode' },
    { name: bloomreach.getText('reason_code_in'), field: 'activationReason' },
    {
      name: bloomreach.getText('reason_code_out'),
      field: 'inactivationReason',
    },
    {
      name: bloomreach.getText('return_date'),
      field: 'returnDate',
      moment: true,
    },
    { name: bloomreach.getText('end_date'), field: 'endDate', moment: true },
    {
      name: bloomreach.getText('regret_date'),
      field: 'regretDate',
      moment: true,
    },
    {
      name: bloomreach.getText('remove_ordered_date'),
      field: 'removeOrderedDate',
      moment: true,
    },
  ]

  rateplans.map((data: any) => {
    let auxData: any = {}
    formattedHeaders.forEach((header) => {
      if (header.moment && data[header.field]) {
        // @ts-ignore
        auxData[header.field] = formatDate(data[header.field], bloomreach)
      }
      // @ts-ignore
      else if (header.currency && data[header.field]) {
        //@ts-ignore
        auxData[header.field] = formatCurrency(data[header.field], currency)
      } else {
        auxData[header.field] = data[header.field]
      }
    })
    auxData.id = data.opusNumber
    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

// export function formatPPSubscriptionData(
//   subscriptionData: Array<SubscriptionData>,
//   bloomreach: any
// ) {
//   const formattedData: SubscriptionData[] = []
//   const formattedHeaders = [
//     {
//       name: bloomreach.getText('subscription_number'),
//       field: 'subscriptionNumber',
//     },
//     { name: bloomreach.getText('subscription_status'), field: 'status' },
//     {
//       name: bloomreach.getText('subscription_type'),
//       field: 'subscriptionType',
//     },
//     {
//       name: bloomreach.getText('activation_date'),
//       field: 'activationDate',
//       moment: true,
//     },
//     {
//       name: bloomreach.getText('return_date'),
//       field: 'returnDate',
//       moment: true,
//     },
//     {
//       name: bloomreach.getText('subscription_end_date'),
//       field: 'endDate',
//       moment: true,
//     },
//     {
//       name: bloomreach.getText('cancellation_order_date'),
//       field: 'cancellationOrderedDate',
//       moment: true,
//     },
//     {
//       name: bloomreach.getText('next_swap_date'),
//       field: 'nextSwapDate',
//       moment: true,
//     },
//     {
//       name: bloomreach.getText('created_date'),
//       field: 'createdDate',
//       moment: true,
//     },
//     {
//       name: bloomreach.getText('earliest_end_date'),
//       field: 'earliestSubscriptionEndDate',
//       moment: true,
//     },
//     {
//       name: bloomreach.getText('scheduled_cancel_date'),
//       field: 'cancellationScheduledDate',
//       moment: true,
//     },
//     {
//       name: bloomreach.getText('cancellation_reason'),
//       field: 'cancellationReason',
//     },
//     { name: bloomreach.getText('store_number'), field: 'storeNumber' },
//     { name: bloomreach.getText('store_user_id'), field: 'storeUserId' },
//   ]

//   subscriptionData.map((data: any) => {
//     let auxData: any = {}
//     formattedHeaders.forEach((header) => {
//       if (header.moment && data[header.field]) {
//         auxData[header.field] = formatDate(data[header.field], bloomreach)
//       } else {
//         auxData[header.field] = data[header.field]
//       }
//     })

//     auxData.id = data.subscriptionNumber
//     formattedData.push(auxData)
//   })

//   return {
//     headers: formattedHeaders,
//     data: formattedData,
//   }
// }

export function formatAllPaymentMethodsData(
  allPaymentMethodsData: Array<PaymentMethodsData>
) {
  const formattedData: PaymentMethodsData[] = []
  const formattedHeaders = [
    { name: 'Card Holder Name', field: 'cardHolderName' },
    { name: 'Card Number', field: 'cardNumber' },
    { name: 'Card Type', field: 'cardType' },
    { name: 'Expiration', field: 'expiration' },
  ]

  allPaymentMethodsData.map((data: any) => {
    if (data.creditCardData?.id) {
      let auxData: any = {}
      formattedHeaders.forEach((header) => {
        auxData[header.field] = data.creditCardData[header.field]
      })
      auxData.id = data.creditCardData.id

      auxData.expiration = `${data.creditCardData.expirationMonth}/${data.creditCardData.expirationYear}`
      formattedData.push(auxData)
    }
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatSubscriptionToOrder(subscription: SubscriptionData) {
  let orderObject: OrderData = {
    customerNumber: subscription.customerNumber.toString(),
    storeNumber: subscription.storeNumber,
    storeUserId: subscription.storeUSerID,
    subscriptionNumber: subscription.subscriptionNumber,
    subscriptionType: subscription.subscriptionType,
    orderType: 'Modify Subscription',
    orderReason: 'Apply discount',
    status: subscription.status,
    subscriptionItems: [],
  }
  subscription.ratePlans.forEach((plan) => {
    let item: OrderDataItem = {
      posItemNumber: plan.opusNumber,
      actionType: 'Add Product',
      actionReason: 'Customer Satisfaction', // plan.stateReason,
      subscriptionCampaignId: plan.subscriptionCampaignId.toString(),
      subscriptionPromotionCode: plan.subscriptionPromotionCode.toString(),
      itemType: plan.type,
    }
    orderObject.subscriptionItems.push(item)
  })
  return orderObject
}

export function formatBillRunPreview(
  billRun: Array<CancelSubscriptionPreviewItem>,
  bloomreach: any,
  currency: any
) {
  let formattedData: Array<CancelSubscriptionPreviewItem> = []
  let formattedHeaders = [
    { name: bloomreach.getText('product_type'), field: 'type' },
    { name: bloomreach.getText('glass_type'), field: 'glassType' },
    { name: bloomreach.getText('brand_name'), field: 'brandName' },
    { name: bloomreach.getText('product_code'), field: 'posItemNumber' },
    { name: bloomreach.getText('period'), field: 'servicePeriod' },
    {
      name: bloomreach.getText('amount'),
      field: 'total',
      currency: true,
    },
  ]

  billRun.map((data: any) => {
    let auxData: any = {}
    formattedHeaders.forEach((header) => {
      if (header.moment && data[header.field]) {
        // @ts-ignore
        auxData[header.field] = formatDate(data[header.field], bloomreach)
      }
      // @ts-ignore
      else if (header.currency && data[header.field]) {
        //@ts-ignore
        auxData[header.field] = data[header.field]
      } else {
        auxData[header.field] = data[header.field]
      }
      auxData.serviceStartDate = data['serviceStartDate']
      auxData.serviceEndDate = data['serviceEndDate']
    })
    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData.length ? [...formattedData, {}] : formattedData,
  }
}

export function formatARInvoiceDetails(
  invoiceDetails: InvoiceDetails,
  bloomreach: any
) {
  const invoiceDetailHeaders = [
    { name: bloomreach.getText('invoice_number'), field: 'number' },
    { name: bloomreach.getText('status'), field: 'status' },
    {
      name: bloomreach.getText('collection_status'),
      field: 'collectionStatus',
    },
    {
      name: bloomreach.getText('collection_status_reason'),
      field: 'collectionStatusReason',
    },
    {
      name: bloomreach.getText('invoice_date'),
      field: 'invoiceDate',
      moment: true,
    },
    { name: bloomreach.getText('due_date'), field: 'dueDate', moment: true },
    { name: bloomreach.getText('dunning_status'), field: 'dunningStatus' },
    {
      name: bloomreach.getText('dunning_status_date'),
      field: 'dunningStatusDate',
      moment: true,
    },
    {
      name: bloomreach.getText('open_balance'),
      field: 'balance',
      currency: true,
    },
    { name: bloomreach.getText('amount'), field: 'amount', currency: true },
    { name: bloomreach.getText('ocr'), field: 'ocr' },
    {
      name: bloomreach.getText('extended_due_date'),
      field: 'extendedDueDate',
      moment: true,
      format: 'YYYY-MM-DD',
    },
    {
      name: bloomreach.getText('payment_method_type'),
      field: 'paymentMethodType',
    },
    {
      name: bloomreach.getText('legacy_invoice_number'),
      field: 'legacyInvoiceNumber',
    },
  ]

  const invoiceDetailData: any = {}
  invoiceDetailHeaders.forEach((header) => {
    //@ts-ignore
    if (header.moment && invoiceDetails[header.field]) {
      if (header.format) {
        invoiceDetailData[header.field] = formatDate(
          //@ts-ignore
          invoiceDetails[header.field],
          bloomreach,
          header.format
        )
      } else {
        invoiceDetailData[header.field] = formatDate(
          //@ts-ignore
          invoiceDetails[header.field],
          bloomreach
        )
      }
    } else {
      //@ts-ignore
      invoiceDetailData[header.field] = invoiceDetails[header.field]
    }

    // @ts-ignore
    if (
      header.currency &&
      (invoiceDetails[header.field] || invoiceDetails[header.field] === 0)
    ) {
      //@ts-ignore
      invoiceDetailData[header.field] = formatCurrency(
        invoiceDetails[header.field],
        invoiceDetails.currency
      )
    }
  })

  const invoiceDetailItemsHeaders = [
    { name: bloomreach.getText('brand_name'), field: 'brandName' },
    { name: bloomreach.getText('glass_type'), field: 'glassType' },
    { name: bloomreach.getText('product_type'), field: 'type' },
    { name: bloomreach.getText('product_code'), field: 'posItemNumber' },
    { name: bloomreach.getText('period'), field: 'servicePeriod' },
    { name: bloomreach.getText('Amount'), field: 'total', currency: true },
    { name: 'Unapplied amount', field: 'unappliedAmount', currency: true },
    {
      name: 'Available to credit',
      field: 'availableToCreditAmount',
      currency: true,
    },
  ]

  const invoiceDetailItemsData: InvoiceDetailItems[] = []

  invoiceDetails.invoiceItems?.map((data: any) => {
    let auxData: any = {}
    invoiceDetailItemsHeaders.forEach((header) => {
      if (header.field === 'servicePeriod') {
        const splitted = data[header.field].split(' to ')
        auxData[header.field] =
          formatDate(splitted[0], bloomreach) +
          ' to ' +
          formatDate(splitted[1], bloomreach)
      } else {
        auxData[header.field] = data[header.field]
      }

      // @ts-ignore
      if (header.currency && (data[header.field] || data[header.field] === 0)) {
        //@ts-ignore
        auxData[header.field] = formatCurrency(
          data[header.field],
          invoiceDetails.currency
        )
      }
    })
    auxData['remainingAmount'] = data['total']
    invoiceDetailItemsData.push(auxData)
  })

  const invoiceTransactionItemsHeaders = [
    {
      name: bloomreach.getText('transaction_date'),
      field: 'date',
      moment: true,
    },
    { name: bloomreach.getText('transaction_number'), field: 'number' },
    { name: bloomreach.getText('type'), field: 'type' },
    { name: bloomreach.getText('status'), field: 'status' },
    {
      name: bloomreach.getText('applied_amount'),
      field: 'amount',
      currency: true,
    },
    { name: bloomreach.getText('balance'), field: 'balance', currency: true },
  ]

  const invoiceTransactionItemsData: any[] = []

  invoiceDetails.invoicePayments?.map((data: any) => {
    let auxData: any = {}
    invoiceTransactionItemsHeaders.forEach((header) => {
      switch (header.field) {
        case 'date':
          auxData[header.field] = formatDate(data['effectiveDate'], bloomreach)
          break
        case 'type':
          auxData.type = 'Payment'
          break
        case 'amount':
          auxData.amount = `${formatCurrency(
            data.appliedAmount,
            data.currency
          )}`
          break
        case 'balance':
          auxData.balance = `${formatCurrency(
            data.amount - data.appliedAmount,
            data.currency
          )}`
          break
        default:
          auxData[header.field] = data[header.field]
          break
      }
    })
    invoiceTransactionItemsData.push(auxData)
  })

  invoiceDetails.invoiceCreditMemos?.map((data: any) => {
    let auxData: any = {}
    invoiceTransactionItemsHeaders.forEach((header) => {
      switch (header.field) {
        case 'date':
          auxData[header.field] = formatDate(data['creditMemoDate'], bloomreach)
          break
        case 'type':
          auxData.type = 'Credit Memo Applied'
          break
        case 'amount':
          auxData.amount = `${formatCurrency(
            data.appliedAmount,
            data.currency
          )}`
          break
        case 'status':
          auxData.status = 'Posted'
          break
        case 'balance':
          auxData.balance = `${formatCurrency(
            data.unappliedAmount,
            data.currency
          )}`
          break
        default:
          auxData[header.field] = data[header.field]
          break
      }
    })
    invoiceTransactionItemsData.push(auxData)
  })

  return {
    invoiceDetailHeaders,
    invoiceDetailData,
    invoiceDetailItemsHeaders,
    invoiceDetailItemsData,
    invoiceTransactionItemsHeaders,
    invoiceTransactionItemsData,
  }
}

export function formatARCreditMemoDetails(
  creditMemoDetails: CreditMemoDetails,
  bloomreach: any
) {
  const creditMemoDetailHeaders = [
    { name: bloomreach.getText('credit_memo_number'), field: 'number' },
    {
      name: bloomreach.getText('issue_date'),
      field: 'createdDate',
      moment: true,
    },
    {
      name: bloomreach.getText('amount'),
      field: 'totalAmount',
      currency: true,
    },
    {
      name: bloomreach.getText('unapplied_amount'),
      field: 'unappliedAmount',
      currency: true,
    },
  ]

  const creditMemoDetailData: any = {}
  creditMemoDetailHeaders.forEach((header) => {
    //@ts-ignore
    if (header.moment && creditMemoDetails[header.field]) {
      creditMemoDetailData[header.field] = formatDate(
        //@ts-ignore
        creditMemoDetails[header.field],
        bloomreach
      )
    } else {
      //@ts-ignore
      creditMemoDetailData[header.field] = creditMemoDetails[header.field]
    }

    if (header.field === 'unappliedAmount' || header.field === 'totalAmount') {
      creditMemoDetailData[header.field] = `${formatCurrency(
        creditMemoDetails[header.field],
        creditMemoDetails.currency
      )}`
    }
  })

  return {
    creditMemoDetailHeaders,
    creditMemoDetailData,
  }
}

export function formatUnmatchedTransactions(
  unmatchedTransactions: UnmatchedTransaction[],
  bloomreach: any
) {
  const unmatchedTransactionsHeaders = [
    {
      name: bloomreach.getText('transaction_date'),
      field: 'transactionDate',
      moment: true,
    },
    { name: bloomreach.getText('reference'), field: 'reference' },
    {
      name: bloomreach.getText('payment_method_type'),
      field: 'paymentMethodType',
    },
    { name: bloomreach.getText('amount'), field: 'amount', currency: true },
    { name: bloomreach.getText('additional_info'), field: 'additionalInfo' },
    { name: bloomreach.getText('file_date'), field: 'filedate', moment: true },
    { name: bloomreach.getText('file_name'), field: 'filename' },
    { name: bloomreach.getText('transaction_type'), field: 'transactionType' },
    // {
    //   name: bloomreach.getText('status_date'),
    //   field: 'statusDate',
    //   moment: true,
    // },
  ]
  const unmatchedTransactionsData: any = []
  unmatchedTransactions.map((unmatchedTransaction) => {
    let aux = {}
    unmatchedTransactionsHeaders.forEach((header) => {
      //@ts-ignore
      if (header.moment && unmatchedTransaction[header.field]) {
        //@ts-ignore
        aux[header.field] = formatDate(
          //@ts-ignore
          unmatchedTransaction[header.field],
          bloomreach,
          unmatchedTransaction['locale'] === 'sv-SE'
            ? 'YYYY-MM-DD'
            : 'DD-MM-YYYY'
        )
      } else {
        //@ts-ignore
        aux[header.field] = unmatchedTransaction[header.field]
      }
      // @ts-ignore
      if (header.currency && unmatchedTransaction[header.field]) {
        //@ts-ignore
        aux[header.field] = formatCurrency(
          unmatchedTransaction[header.field],
          unmatchedTransaction['locale']
            ? unmatchedTransaction['locale']
            : bloomreach.getLocale()
        )
      }
    })
    aux.id = unmatchedTransaction.id
    unmatchedTransactionsData.push(aux)
  })

  return {
    headers: unmatchedTransactionsHeaders,
    data: unmatchedTransactionsData,
  }
}

export function formatHistoryLogs(
  historyLogsData: Array<HistoryLogs>,
  bloomreach: any
) {
  const formattedData: HistoryLogs[] = []

  const formattedHeaders = [
    { name: bloomreach.getText('created_date'), field: 'createdDate' },
    { name: bloomreach.getText('category'), field: 'eventCategory' },
    { name: bloomreach.getText('sub_category'), field: 'eventSubcategory' },
    { name: bloomreach.getText('description'), field: 'description' },
    { name: bloomreach.getText('originating_system'), field: 'system' },
    { name: bloomreach.getText('user'), field: 'user' },
  ]

  historyLogsData.map((data) => {
    let auxData: any = {}
    auxData.id = data.id
    auxData.createdDate = formatDate(data.createdDate, bloomreach)
    auxData.user = data.user
    auxData.eventCategory = data.eventCategory
    auxData.eventSubcategory = data.eventSubcategory
    auxData.description = data.description
    auxData.system = data.system

    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatAdminLogs(
  historyLogsData: Array<HistoryLogs>,
  bloomreach: any,
  countryCode: any
) {
  const formattedData: HistoryLogs[] = []

  const formattedHeaders = [
    { name: bloomreach.getText('created_date'), field: 'createdDate' },
    { name: bloomreach.getText('category'), field: 'eventCategory' },
    { name: bloomreach.getText('sub_category'), field: 'eventSubcategory' },
    { name: bloomreach.getText('description'), field: 'description' },
    { name: bloomreach.getText('originating_system'), field: 'system' },
    { name: bloomreach.getText('user'), field: 'user' },
  ]

  historyLogsData.map((data) => {
    let auxData: any = {}
    auxData.id = data.id
    // let dateFormat = 'YYYY-MM-DD'
    // switch (countryCode) {
    //   case 'SE':
    //     dateFormat = 'YYYY-MM-DD'
    //     break
    //   case 'DK':
    //     dateFormat = 'DD-MM-YYYY'
    //     break
    //   case 'NL':
    //     dateFormat = 'D-MM-YYYY'
    // }
    // auxData.createdDate = formatDate(data.createdDate, bloomreach, dateFormat)
    auxData.createdDate = formatDate(data.createdDate, bloomreach)
    auxData.user = data.user
    auxData.eventCategory = data.eventCategory
    auxData.eventSubcategory = data.eventSubcategory
    auxData.description = data.description
    auxData.system = data.system

    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatEmailHistory(
  emailHistoryData: Array<EmailHistory>,
  bloomreach: any
) {
  const formattedData: EmailHistory[] = []

  const formattedHeaders = [
    { name: bloomreach.getText('sent_date'), field: 'createdOn' },
    { name: bloomreach.getText('sent_time'), field: 'createdTime' },
    { name: bloomreach.getText('subject'), field: 'subject' },
    { name: bloomreach.getText('to'), field: 'to' },
    { name: bloomreach.getText('actions'), field: 'actions' },
  ]

  emailHistoryData.map((data) => {
    let auxData: any = {}

    auxData.createdOn = formatDate(data.createdOn, bloomreach)
    auxData.createdTime = formatTime(data.createdOn)
    auxData.subject = data.subject
    auxData.to = data.to
    auxData.id = data.id
    auxData.contentFileId = data.contentFileId
    auxData.objectId = data.objectId
    auxData.attachment = data.attachment

    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatDate(
  date: string | number,
  bloomreach: any,
  format?: string | undefined
) {
  let momentDateFormat
  if (format) {
    momentDateFormat = format
  } else {
    const banner = bloomreach.getBanner()
    const locale = bloomreach.getLocale()
    if (banner) {
      switch (banner) {
        case 'Synoptik AB':
          momentDateFormat = 'YYYY-MM-DD'
          break
        case 'Synoptik A/S':
          momentDateFormat = 'DD-MM-YYYY'
          break
        case 'Pearle':
          momentDateFormat = 'D-MM-YYYY'
          break
        default:
          momentDateFormat = 'DD-MM-YYYY'
      }
    } else if (locale) {
      switch (locale) {
        case 'sv-SE':
          momentDateFormat = 'YYYY-MM-DD'
          break
        case 'da-DK':
          momentDateFormat = 'DD-MM-YYYY'
          break
        case 'nl-NL':
          momentDateFormat = 'D-MM-YYYY'
          break
        default:
          momentDateFormat = 'DD-MM-YYYY'
      }
    }
  }

  let formattedDate
  if (!date) {
    formattedDate = ''
  } else if (date === '0001-01-01' || date === '01-01-0001') {
    formattedDate = ''
  } else {
    formattedDate = moment(date).format(momentDateFormat)
  }
  return formattedDate
}

export function formatTime(date: string, format: string = 'HH:mm:ss') {
  return moment(date).format(format)
}

export function formatCurrencyToFloat(value: string) {
  if (value.includes('kr')) {
    value = value.toString().slice(0, -3).trim()
  }
  //? Remove spacing on SEK currency before checking anything
  const newValue = value.replace(/\s/g, '')
  const indexComma = newValue.indexOf(',')
  const indexDot = newValue.indexOf('.')
  //? If no comma exists then, it can be parsed to float
  if (indexComma === -1) return newValue
  //? If there is a comma and no dots, then just replace the comma for dot so it can be parsed to float
  if (indexDot === -1) return newValue.replace(/\,/g, '.')
  //? If there is both commas and dots, then check the order and replace correctly
  return indexComma < indexDot
    ? newValue.replace(/\,/g, '')
    : newValue.replace(/\./g, '').replace(',', '.')
}

export function parsedCurrencyToFloat(value: string) {
  let formattedValue = formatCurrencyToFloat(value)
  const currency = formattedValue.toString().slice(-3)

  if (['EUR'].includes(formattedValue.toString().slice(0, 3))) {
    formattedValue = formattedValue.toString().slice(3).trim()
  }

  if (formattedValue.toString().includes('€')) {
    formattedValue = formattedValue.toString().slice(1).trim()
  }

  if (['DKK', 'SEK'].includes(currency)) {
    formattedValue = formattedValue.toString().slice(0, -3).trim()
    /* Negative amounts return NaN after a parseFloat,
     * The minus sign coming from the BE seems to be a strange symbol, which JS can't parse
     * So this is a replacement of that symbol
     */
    if (isNaN(Number(formattedValue[0]))) {
      return Number(
        parseFloat(formattedValue.replace(formattedValue[0], '-')).toFixed(2)
      )
    }
  } else {
    if (isNaN(Number(formattedValue[0]))) {
      return Number(
        parseFloat(formattedValue.replace(formattedValue[0], '-')).toFixed(2)
      )
    }
  }
  return Number(parseFloat(formattedValue).toFixed(2))
}

export function formatDraftBillRuns(
  BillRunData: Array<DraftBillRun>,
  bloomreach: any
) {
  const formattedData: DraftBillRun[] = []

  const formattedHeaders = [
    { name: bloomreach.getText('banner'), field: 'banner' },
    {
      name: bloomreach.getText('billingrun_number'),
      field: 'billingRunNumber',
    },
    { name: bloomreach.getText('billing_batch_or_customer'), field: 'batches' },
    { name: bloomreach.getText('document_date'), field: 'documentDate' },
    {
      name: bloomreach.getText('invoices_created'),
      field: 'numberOfInvoices',
      currency: true,
    },
    {
      name: bloomreach.getText('credit_memos'),
      field: 'numberOfCreditMemos',
      currency: true,
    },
    { name: bloomreach.getText('target_date'), field: 'targetDate' },
  ]

  BillRunData.map((data) => {
    let auxData: any = {}
    auxData.batches =
      data.numberOfAccounts > 1 ? data.batches : data.customerNumber
    auxData.numberOfInvoices = data.numberOfInvoices
    auxData.numberOfCreditMemos = data.numberOfCreditMemos
    auxData.targetDate = formatDate(data.targetDate, bloomreach)
    auxData.documentDate = formatDate(data.documentDate, bloomreach)
    auxData.banner = data.banner
    auxData.billingRunNumber = data.billingRunNumber
    auxData.id = data.id

    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatCSVTransactionData(
  transactionData: Array<CsvTransaction>,
  bloomreach: any,
  currency: string
) {
  const formattedData: CsvTransaction[] = []

  const formattedHeaders = [
    {
      name: bloomreach.getText('import'),
      field: 'select',
      input: true,
      inputType: 'checkbox',
      disabled: 'checkboxDisabled',
    },
    {
      name: `${bloomreach.getText('amount')} (${currency})`,
      field: 'amount',
      currency: true,
    },
    { name: bloomreach.getText('payment_date'), field: 'paymentDate' },
    { name: bloomreach.getText('invoice_warning'), field: 'invoiceWarning' },
    {
      name: bloomreach.getText('invoice_paid_warning'),
      field: 'invoicePaidWarning',
    },
    { name: bloomreach.getText('customer_warning'), field: 'customerWarning' },
    {
      name: bloomreach.getText('invoice_number'),
      field: 'invoiceNumber',
    },
    {
      name: bloomreach.getText('customer_number'),
      field: 'customerNumber',
    },
  ]
  const dateFormat = currency === 'SEK' ? 'YYYY-MM-DD' : 'DD-MM-YYYY'

  transactionData.map((data) => {
    let auxData: any = {}

    auxData.amount = formatCurrency(data.amount, currency, false)
    auxData.paymentDate = formatDate(data.paymentDate, bloomreach, dateFormat)
    auxData.invoiceWarning = data.invoiceWarning
      ? bloomreach.getText('invoice_not_found')
      : bloomreach.getText('invoice_found')
    auxData.invoicePaidWarning = data.invoicePaidWarning
      ? bloomreach.getText('invoice_not_paid')
      : bloomreach.getText('invoice_already_paid')
    auxData.customerWarning = data.customerWarning
      ? bloomreach.getText('customer_not_found')
      : bloomreach.getText('customer_found')
    auxData.invoiceNumber = data.invoiceNumber
    auxData.customerNumber = data.customerNumber

    auxData.checkboxDisabled = false

    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatBillRunInvoices(
  BillRunInvoice: Array<BillRunInvoice>,
  bloomreach: any,
  currency: string
) {
  const formattedData: BillRunInvoice[] = []

  const formattedHeaders = [
    {
      name: bloomreach.getText('customer_number'),
      field: 'customerNumber',
      link: true,
    },
    { name: bloomreach.getText('customer_name'), field: 'accountName' },
    { name: bloomreach.getText('amount'), field: 'amount', currency: true },
    { name: bloomreach.getText('currency'), field: 'currency' },
    {
      name: bloomreach.getText('document_number'),
      field: 'documentNumber',
      button: true,
    },
    { name: bloomreach.getText('due_date'), field: 'dueDate' },
    { name: bloomreach.getText('document_type'), field: 'documentType' },
    { name: bloomreach.getText('status'), field: 'status' },
    { name: bloomreach.getText('actions'), field: 'actions' },
  ]

  BillRunInvoice.filter((data) => data.status === 'Draft').map((data) => {
    let auxData: any = {}
    auxData.customerNumber = data.customerNumber
    auxData.accountNumber = data.accountNumber
    auxData.accountName = data.accountName
    auxData.amount = formatCurrency(data.amount, currency, false)
    auxData.documentNumber = data.documentNumber
    auxData.documentDate = formatDate(data.documentDate, bloomreach)
    auxData.dueDate = formatDate(data.dueDate, bloomreach)
    auxData.billRunNumber = data.billRunNumber
    auxData.paymentMethod = data.paymentMethod

    auxData.documentId = data.documentId
    auxData.documentType = data.documentType
    auxData.status = data.status
    auxData.currency = currency
    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatUploadedTransactionData(
  transactionData: Array<CsvTransaction>,
  bloomreach: any,
  currency: string
) {
  const formattedData: CsvTransaction[] = []

  const formattedHeaders = [
    { name: bloomreach.getText('imported'), field: 'import' },
    {
      name: `${bloomreach.getText('amount')} (${currency})`,
      field: 'amount',
      currency: true,
    },
    { name: bloomreach.getText('payment_date'), field: 'paymentDate' },
    {
      name: bloomreach.getText('invoice_number'),
      field: 'invoiceNumber',
    },
    {
      name: bloomreach.getText('customer_number'),
      field: 'customerNumber',
    },
    { name: bloomreach.getText('message'), field: 'message' },
  ]

  const dateFormat = currency === 'SEK' ? 'YYYY-MM-DD' : 'DD-MM-YYYY'

  transactionData.map((data) => {
    let auxData: any = {}

    if (data.reasons && Array.isArray(data.reasons)) {
      auxData.message = data.reasons.map((d) => d.message).join('. ')
    } else if (data.message) {
      auxData.message = data.message
    } else if (data.code === '000009') {
      auxData.message = bloomreach.getText('unmatch_transaction_created')
    } else if (data.code === '000008') {
      auxData.message = bloomreach.getText('balance_already_applied')
    } else {
      auxData.message = ''
    }

    auxData.import = data.import
      ? bloomreach.getText('yes')
      : bloomreach.getText('no')
    auxData.amount = formatCurrency(data.amount, currency, false)
    auxData.paymentDate = formatDate(data.paymentDate, bloomreach, dateFormat)
    auxData.invoiceNumber = data.invoiceNumber
    auxData.customerNumber = data.customerNumber
    auxData.checkboxDisabled = false
    auxData.checkboxDisabled = false

    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatReminderLetter(
  remainderLetter: Array<ReminderLetter>,
  bloomreach: any
) {
  const formattedData: ReminderLetter[] = []

  const formattedHeaders = [
    { name: bloomreach.getText('created_date'), field: 'createdOn' },
    { name: bloomreach.getText('file_content_type'), field: 'fileContentType' },
    { name: bloomreach.getText('file_name'), field: 'fileName' },
  ]

  remainderLetter.map((data) => {
    let auxData: any = {}

    auxData.createdOn = formatDate(data.createdOn, bloomreach)
    auxData.fileContentType = data.fileContentType
    auxData.fileName = data.fileName
    auxData.id = data.id
    auxData.fileId = data.fileId

    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatDebitMemoItems(
  debitMemoItems: Array<DebitMemoItem>,
  bloomreach: any,
  currency: string
) {
  const formattedData: DebitMemoItem[] = []

  const formattedHeaders = [
    {
      name: bloomreach.getText('created_date'),
      field: 'createdDate',
    },
    { name: bloomreach.getText('description'), field: 'description' },
    { name: bloomreach.getText('period'), field: 'servicePeriod' },
    { name: bloomreach.getText('amount'), field: 'amount', currency: true },
  ]

  debitMemoItems.map((data) => {
    let auxData: any = {}

    auxData.amount = formatCurrency(data.amount, currency)
    auxData.createdDate = formatDate(data.createdDate, bloomreach)
    auxData.servicePeriod =
      formatDate(data.serviceStartDate, bloomreach) +
      ' - ' +
      formatDate(data.serviceEndDate, bloomreach)
    auxData.description = data.skuName
    formattedData.push(auxData)
  })

  return {
    formattedHeaders,
    formattedData,
  }
}

export function formatTakeAsIncomeItems(
  debitMemoItems: Array<DebitMemoItem>,
  bloomreach: any,
  currency: string
) {
  const formattedData: any[] = []

  const formattedHeaders = [
    { name: bloomreach.getText('brand_name'), field: 'brandName' },
    { name: bloomreach.getText('glass_type'), field: 'glassType' },
    { name: bloomreach.getText('product_type'), field: 'type' },
    { name: bloomreach.getText('product_code'), field: 'posItemNumber' },
    { name: bloomreach.getText('period'), field: 'servicePeriod' },
    { name: bloomreach.getText('amount'), field: 'amount', currency: true },
  ]

  debitMemoItems.map((data) => {
    const auxData = {
      amount: formatCurrency(data.amount, currency),
      brandName: data.brandName,
      glassType: data.glassType,
      type: data.type,
      posItemNumber: data.posItemNumber || data.description || data.skuName,
      servicePeriod: `${formatDate(
        data.serviceStartDate,
        bloomreach
      )} - ${formatDate(data.serviceEndDate, bloomreach)}`,
    }

    formattedData.push(auxData)
  })

  return {
    formattedHeaders,
    formattedData,
  }
}

export function formatAdHocDebitMemoItems(
  debitMemoItems: Array<DebitMemoItem>,
  bloomreach: any,
  currency: string
) {
  const formattedData: DebitMemoItem[] = []

  const formattedHeaders = [
    { name: bloomreach.getText('brand_name'), field: 'brandName' },
    { name: bloomreach.getText('glass_type'), field: 'glassType' },
    { name: bloomreach.getText('product_type'), field: 'type' },
    { name: bloomreach.getText('product_code'), field: 'posItemNumber' },
    { name: bloomreach.getText('period'), field: 'servicePeriod' },
    {
      name: bloomreach.getText('amount'),
      field: 'amount',
      currency: true,
    },
  ]

  debitMemoItems.map((data) => {
    let auxData: any = {}

    auxData.brandName = data.brandName
    auxData.glassType = data.glassType
    auxData.type = data.type
    auxData.posItemNumber =
      data.posItemNumber || data.description || data.skuName
    auxData.servicePeriod =
      formatDate(data.serviceStartDate, bloomreach) +
      ' to ' +
      formatDate(data.serviceEndDate, bloomreach)

    auxData.amount = formatCurrency(data.amount, currency)
    auxData.unappliedAmount = formatCurrency(data.unappliedAmount, currency)
    auxData.createdDate = formatDate(data.createdDate, bloomreach)
    auxData.description = data.skuName

    formattedData.push(auxData)
  })
  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatCreditMemoItems(
  creditMemoItems: Array<CreditMemoItems>,
  bloomreach: any,
  currency: string
) {
  const formattedData: CreditMemoItems[] = []
  const formattedHeaders = [
    { name: bloomreach.getText('brand_name'), field: 'brandName' },
    { name: bloomreach.getText('glass_type'), field: 'glassType' },
    { name: bloomreach.getText('product_type'), field: 'type' },
    { name: bloomreach.getText('product_code'), field: 'posItemNumber' },
    { name: bloomreach.getText('period'), field: 'servicePeriod' },
    {
      name: bloomreach.getText('amount'),
      field: 'amount',
      currency: true,
    },
    {
      name: bloomreach.getText('unapplied_amount'),
      field: 'unappliedAmount',
      currency: true,
    },
  ]

  creditMemoItems.map((data) => {
    let auxData: any = {}

    auxData.brandName = data.brandName
    auxData.glassType = data.glassType
    auxData.type = data.type
    auxData.posItemNumber = data.posItemNumber
    auxData.servicePeriod =
      formatDate(data.serviceStartDate, bloomreach) +
      ' to ' +
      formatDate(data.serviceEndDate, bloomreach)

    const sumCreditTaxUnappliedAmounts = data.creditTaxItems.reduce(
      (sum: number, { unappliedAmount }: { unappliedAmount: number }) =>
        sum + unappliedAmount,
      0
    )
    const creditUnappliedAmount =
      data.unappliedAmount + sumCreditTaxUnappliedAmounts
    auxData.amount = formatCurrency(data.amount, currency)
    auxData.unappliedAmount = formatCurrency(creditUnappliedAmount, currency)
    auxData.id = data.id
    auxData.creditTaxItems = data.creditTaxItems

    // auxData.amountWithoutTax = formatCurrency(data.amountWithoutTax, currency)
    // auxData.appliedAmount = formatCurrency(data.appliedAmount, currency)
    // auxData.comment = data.comment
    // auxData.creditFromItemSource = data.creditFromItemSource
    // auxData.processingType = data.processingType
    // auxData.refundAmount = formatCurrency(data.refundAmount, currency)
    // auxData.serviceEndDate = formatDate(data.serviceEndDate, bloomreach)
    // auxData.serviceStartDate = formatDate(data.serviceStartDate, bloomreach)
    // auxData.sku = data.sku
    // auxData.sourceItemType = data.sourceItemType
    // auxData.remainingAmount = data.unappliedAmount
    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatPPActiveSubscriptionData(
  subscriptionData: SubscriptionData,
  bloomreach: any,
  currency: string,
  invoice: boolean
) {
  const formattedHeaders = [
    { name: bloomreach.getText('glass_type'), field: 'glassType' },
    { name: bloomreach.getText('brand_name'), field: 'brandName' },
    { name: bloomreach.getText('order_number'), field: 'number' },
    {
      name: bloomreach.getText('handout_date'),
      field: 'activationDate',
      moment: true,
    },
    {
      name: bloomreach.getText('total_monthly_price'),
      field: 'decimalPricePerMonth',
    },
  ]

  const response = subscriptionData.map((data: any) => {
    const auxData = data.items.map((item) => {
      return {
        glassType: item.glassType,
        brandName: item.brandName,
        number: item.opusNumber,
        activationDate: formatToReadableDate(
          item.activationDate,
          bloomreach.getLocale()
        ),
        decimalPricePerMonth: formatCurrency(
          item.decimalPricePerMonth,
          currency,
          true,
          true
        ),
      }
    })

    const owner = invoice
      ? data.subscription.accounts.find((sub) => sub.type === 'InvoiceOwner')
      : data.subscription.accounts.find(
          (sub) => sub.type === 'SubscriptionOwner'
        )

    return {
      data: auxData,
      headers: formattedHeaders,
      subscription: {
        subscriptionNumber: data.subscription.number,
        subscriptionActivationDate: formatToReadableDate(
          data.subscription.activationDate,
          bloomreach.getLocale()
        ),
        subscriptionOwner: owner,
        nextSwapDate: formatToReadableDate(
          data.subscription.nextSwapDate,
          bloomreach.getLocale()
        ),
        subscriptionStatus: data.subscription.status,
        subscriptionOwnerName: `${owner.contactDetail.firstName} ${owner.contactDetail.lastName}`,
        totalMonthlyPrice: formatCurrency(
          data.subscription.totalMonthlyPrice,
          currency,
          true,
          true
        ),
      },
    }
  })

  return response
}

export function formatPPCanceledSubscriptionData(
  subscriptionData: SubscriptionData,
  bloomreach: any,
  currency: string,
  invoice: boolean
) {
  const formattedHeaders = [
    { name: bloomreach.getText('glass_type'), field: 'glassType' },
    { name: bloomreach.getText('brand_name'), field: 'brandName' },
    { name: bloomreach.getText('order_number'), field: 'number' },
    {
      name: bloomreach.getText('delivery_date'),
      field: 'originalActivationDate',
      moment: true,
    },
    {
      name: bloomreach.getText('total_monthly_price'),
      field: 'decimalPricePerMonth',
    },
  ]

  const response = subscriptionData.map((data: any) => {
    const auxData = data.items.map((item) => {
      return {
        glassType: item.glassType,
        brandName: item.brandName,
        number: item.opusNumber,
        originalActivationDate: formatToReadableDate(
          item.originalActivationDate,
          bloomreach.getLocale()
        ),
        decimalPricePerMonth: formatCurrency(
          item.decimalPricePerMonth,
          currency,
          true,
          true
        ),
      }
    })

    const owner = invoice
      ? data.subscription.accounts.find((sub) => sub.type === 'InvoiceOwner')
      : data.subscription.accounts.find(
          (sub) => sub.type === 'SubscriptionOwner'
        )
    return {
      data: auxData,
      headers: formattedHeaders,
      subscription: {
        subscriptionNumber: data.subscription.number,
        subscriptionActivationDate: formatToReadableDate(
          data.subscription.activationDate,
          bloomreach.getLocale()
        ),
        subscriptionStartDate: formatToReadableDate(
          data.subscription.subscriptionStartDate,
          bloomreach.getLocale()
        ),
        earliestSubscriptionEndDate: formatToReadableDate(
          data.subscription.earliestSubscriptionEndDate,
          bloomreach.getLocale()
        ),
        subscriptionOwner: owner,
        subscriptionEndDate: formatToReadableDate(
          data.subscription.subscriptionEndDate,
          bloomreach.getLocale()
        ),
        cancellationNoticeDate: formatToReadableDate(
          data.subscription.cancellationNoticeDate,
          bloomreach.getLocale()
        ),
        subscriptionStatus: data.subscription.status,
        subscriptionOwnerName: `${owner.contactDetail.firstName} ${owner.contactDetail.lastName}`,
        totalMonthlyPrice: formatCurrency(
          data.subscription.totalMonthlyPrice,
          currency,
          true,
          true
        ),
      },
    }
  })
  return response
}

export function formatARCancelSubscriptionPreviewData(
  data: CancelSubscriptionPreviewData,
  bloomreach: any,
  currency: string
) {
  const formattedHeaders = [
    { name: bloomreach.getText('product_type'), field: 'type' },
    { name: bloomreach.getText('glass_type'), field: 'glassType' },
    { name: bloomreach.getText('brand_name'), field: 'brandName' },
    { name: bloomreach.getText('product_code'), field: 'posItemNumber' },
    { name: bloomreach.getText('period'), field: 'servicePeriod' },
    {
      name: bloomreach.getText('amount'),
      field: 'total',
      currency: true,
    },
  ]

  const invoices = data.invoices.map((document) => ({
    ...document,
    items: document.items.map((item) => ({
      ...item,
      documentType: PreviewDocumentType.Invoice,
    })),
    documentType: PreviewDocumentType.Invoice,
  }))
  const creditMemos = data.creditMemos.map((document) => ({
    ...document,
    items: document.items.map((item) => ({
      ...item,
      documentType: PreviewDocumentType.CreditMemo,
    })),
    documentType: PreviewDocumentType.CreditMemo,
  }))
  const debitMemos = data.debitMemos.map((document) => ({
    ...document,
    items: document.items.map((item) => ({
      ...item,
      documentType: PreviewDocumentType.DebitMemo,
    })),
    documentType: PreviewDocumentType.DebitMemo,
  }))
  const formattedData: SubscriptionPreviewDataWithType[] = [
    ...invoices,
    ...creditMemos,
    ...debitMemos,
  ]
  formattedData.sort((a, b) => {
    const endDateA = a.items
      .map((x) => new Date(x.serviceEndDate).getTime())
      .sort()[0]
    const endDateB = b.items
      .map((x) => new Date(x.serviceEndDate).getTime())
      .sort()[0]

    const diff = endDateA - endDateB

    if (diff === 0) {
      const startDateA = a.items
        .map((x) => new Date(x.serviceStartDate).getTime())
        .sort()[0]

      const startDateB = b.items
        .map((x) => new Date(x.serviceStartDate).getTime())
        .sort()[0]

      return startDateA - startDateB
    }

    return diff
  })

  // This is a hack to introdce total amount row into UI
  for (const document of formattedData) {
    document.items.push({
      servicePeriod: '',
      serviceStartDate: '',
      serviceEndDate: '',
      amountWithoutTax: 0,
      taxAmount: 0,
      total: document.items.reduce((sum, item) => sum + item.total, 0),
      type: '',
      glassType: '',
      brandName: '',
      posItemNumber: '',
      quantity: 0,
      chargeDescription: '',
      chargeName: '',
      chargeNumber: '',
      processingType: '',
      productName: '',
      productRatePlanChargeId: '',
      subscriptionNumber: '',
      displayName: '',
      documentType: document.documentType,
    })
  }

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

export function formatNewOpusData(
  data = [],
  bloomreach: any,
  currency: string
) {
  let formattedData = [],
    formattedHeaders = [
      { name: bloomreach.getText('opus_number'), field: 'opusNumber' },
      { name: bloomreach.getText('display_name'), field: 'displayName' },
      {
        name: bloomreach.getText('price_per_month'),
        field: 'pricePerMonth',
        currency: true,
      },
    ]
  data.map((item) => {
    const auxData = {
      ...item,
      pricePerMonth: formatCurrency(item.pricePerMonth, currency),
    }
    formattedData.push(auxData)
  })

  return { headers: formattedHeaders, data: formattedData }
}

export function formatRefundsRelatedTransactions(
  data: RefundDetails,
  bloomreach: Bloomreach,
  currency: string
) {
  const formattedData: Partial<RefundDetails[]> = []
  const refundsData = [data]

  const formattedHeaders = [
    { name: bloomreach.getText('transaction_type'), field: 'transactionType' },
    {
      name: bloomreach.getText('transaction_number'),
      field: 'transactionNumber',
    },
    { name: bloomreach.getText('amount'), field: 'amount', currency: true },
    {
      name: bloomreach.getText('applied_amount'),
      field: 'appliedAmount',
      currency: true,
    },
    { name: bloomreach.getText('balance'), field: 'balance', currency: true },
    { name: bloomreach.getText('status'), field: 'status' },
    {
      name: bloomreach.getText('transaction_date'),
      field: 'transactionDate',
    },
  ]

  refundsData.map((item) => {
    let auxData: any = {}
    auxData.amount = formatCurrency(item.amount, currency)
    auxData.appliedAmount = formatCurrency(item.appliedAmount, currency)
    auxData.balance = formatCurrency(item.balance, currency)
    auxData.status = item.status
    auxData.transactionDate = formatDate(item.transactionDate, bloomreach)
    auxData.transactionNumber = item.transactionNumber
    auxData.transactionType = item.transactionType

    formattedData.push(auxData)
  })

  return {
    headers: formattedHeaders,
    data: formattedData,
  }
}

<template>
  <div>
    <div
      v-if="
        !subscriptionDataStore.loadingSubscriptions &&
        subscriptionDataStore.subscriptionRecords.length > 0
      "
      class="mb-8"
    >
      {{ bloomreach.getText('subscriptions_intro') }}
      <router-link class="text-primaryColor-medium" :to="{ name: 'Contact' }">
        {{
          bloomreach.getLocale() === 'da-DK'
            ? bloomreach.getText('help')
            : bloomreach.getText('contact')
        }}
      </router-link>
    </div>

    <div v-if="subscriptionDataStore.loadingSubscriptions">
      <SLoading class="inset-1/2 -ml-8 mt-8 relative h-24 w-24" />
    </div>

    <div
      class="overflow-x-scroll mb-10"
      v-if="
        !subscriptionDataStore.loadingSubscriptions &&
        subscriptionDataStore.subscriptionRecords.length > 0
      "
    >
      <div
        v-for="(record, index) in subscriptionDataStore.subscriptionRecords"
        :key="index"
      >
        <!-- Active subscription -->
        <div
          class="mb-10"
          v-if="record.subscription.subscriptionStatus === 'Active'"
        >
          <h2 class="font-bold text-xl">
            {{ bloomreach.getText('subscriptions') }}
            {{ bloomreach.getText('for') }}
            {{ record.subscription.subscriptionOwner.contactDetail?.firstName }}
            {{ record.subscription.subscriptionOwner.contactDetail?.lastName }}
          </h2>
          <h3 class="font-bold text-xl mb-3">
            {{ bloomreach.getText('subscription_number') }}:
            {{ record.subscription.subscriptionNumber }}
          </h3>
          <h3 class="font-bold text-lg">
            {{ bloomreach.getText('subscription_dates') }}
          </h3>
          <div class="mb-5 grid md:grid-cols-1 xl:grid-cols-2">
            <div class="md:grid md:grid-cols-2">
              <p class="font-bold">
                {{ bloomreach.getText('activation_date') }}:
              </p>
              <p class="font-bold mb-4 md:mb-1">
                {{ record.subscription.subscriptionActivationDate }}
              </p>

              <p class="font-bold md:mb-1">
                {{ bloomreach.getText('next_swap_date') }}:
              </p>
              <p class="font-bold">
                {{ record.subscription.nextSwapDate }}
              </p>

              <p class="font-bold md:mb-1">
                {{ bloomreach.getText('monthly_subscription_fee') }}:
              </p>
              <p class="font-bold">
                {{ record.subscription.totalMonthlyPrice }}
              </p>
            </div>
          </div>
          <p class="font-bold mb-1">{{ bloomreach.getText('glasses') }}</p>
          <STable
            :headers="record.headers"
            :data="record.data"
            :sortable="false"
            :paginated="false"
            :limitPerPage="10"
            :rowClickable="false"
          />
        </div>
        <!-- End active subscription -->

        <!-- Cancelled subscription -->
        <div
          class="mb-10"
          v-if="record.subscription.subscriptionStatus === 'Cancelled'"
        >
          <div>
            <h2 class="font-bold text-xl">
              {{ bloomreach.getText('subscriptions') }}
              {{ bloomreach.getText('for') }}
              {{
                record.subscription.subscriptionOwner.contactDetail?.firstName
              }}
              {{
                record.subscription.subscriptionOwner.contactDetail?.lastName
              }}
            </h2>
            <h3 class="font-bold text-xl mb-3">
              {{ bloomreach.getText('subscription_number') }}:
              {{ record.subscription.subscriptionNumber }}
            </h3>
            <h3 class="font-bold text-lg">
              {{ bloomreach.getText('subscription_dates') }}
            </h3>
            <div class="grid md:grid-cols-1 xl:grid-cols-2 mb-5">
              <div class="md:grid md:grid-cols-2">
                <p class="font-bold">
                  {{ bloomreach.getText('activation_date') }}:
                </p>
                <p class="font-bold mb-4 md:mb-1">
                  {{ record.subscription.subscriptionActivationDate }}
                </p>
                <p class="font-bold">
                  {{ bloomreach.getText('cancellation_notice_date') }}:
                </p>
                <p class="font-bold mb-4 md:mb-1">
                  {{ record.subscription.cancellationNoticeDate }}
                </p>

                <p class="font-bold">
                  {{ bloomreach.getText('earliest_stop_date') }}:
                </p>
                <p class="font-bold mb-4 md:mb-1">
                  {{ record.subscription.earliestSubscriptionEndDate }}
                </p>

                <p class="font-bold">
                  {{ bloomreach.getText('subscription_end_date') }}:
                </p>
                <p class="font-bold">
                  {{ record.subscription.subscriptionEndDate }}
                </p>

                <p class="font-bold md:mb-1">
                  {{ bloomreach.getText('monthly_subscription_fee') }}:
                </p>
                <p class="font-bold">
                  {{ record.subscription.totalMonthlyPrice }}
                </p>
              </div>
            </div>
            <p class="font-bold mb-1">{{ bloomreach.getText('glasses') }}</p>
            <STable
              :headers="record.headers"
              :data="record.data"
              :sortable="false"
              :paginated="false"
              :limitPerPage="10"
              :rowClickable="false"
            />
          </div>

          <p class="mt-2">
            {{ bloomreach.getText('cancelled_subscription_text') }}
            <router-link
              class="text-primaryColor-medium"
              :to="{ name: 'Contact' }"
            >
              {{ bloomreach.getText('contact').toLowerCase() }}
            </router-link>
          </p>
        </div>
        <!-- End cancelled subscription -->
      </div>

      <!-- <div
        class="overflow-auto mb-10"
        v-if="
          subscriptionDataStore.relatedAccountsTable?.data?.children &&
          subscriptionDataStore.relatedAccountsTable?.data?.children?.length > 0
        "
      >
        <h2 class="mt-8 mb-2 text-lg font-medium">
          {{ bloomreach.getText('children_accounts') }}
        </h2>
        <div
          v-for="(child, j) in subscriptionDataStore.relatedAccountsTable?.data
            ?.children"
          :key="j"
          class="mb-10"
        >
          <h2 class="my-2 font-medium cursor-pointer">
            {{ `${child.name} - ${child.customerNumber}` }}
          </h2>
          <STable
            v-if="child.subscriptions.length > 0"
            :headers="subscriptionDataStore.relatedAccountsTable?.headers"
            :data="child.subscriptions"
            :sortable="false"
            :paginated="false"
            :limitPerPage="10"
            :rowClickable="false"
          />
        </div>
      </div> -->
    </div>

    <div
      v-if="
        !subscriptionDataStore.loadingSubscriptions &&
        subscriptionDataStore.subscriptionRecords.length === 0
      "
    >
      <NoData />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, inject } from 'vue'
import {
  STable,
  SLoading,
  STableCollapse,
  bloomreachKey,
  formatToReadableDate,
} from '@gv/core'

import NoData from './NoData.vue'
import { useSubscriptionDataStore } from '../stores/subscriptionData'

export default defineComponent({
  name: 'Subscriptions',
  components: { STable, SLoading, NoData, STableCollapse },
  setup() {
    const bloomreach = inject(bloomreachKey)!
    const subscriptionDataStore = useSubscriptionDataStore()

    onMounted(() => {
      if (subscriptionDataStore.storeNotStarted)
        subscriptionDataStore.startStore()
    })

    return {
      bloomreach,
      subscriptionDataStore,
      formatToReadableDate,
    }
  },
})
</script>

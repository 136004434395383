<template>
  <footer class="bg-white w-full border-t shadow-xl z-10">
    <slot name="content"> </slot>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SFooter',
})
</script>
